$font-headers: 'QuincyCF';
$font-text: 'Avenir Next';
$quincy: 'QuincyCF';
$avenir: 'Avenir Next';

// $primary-brand-color:  #224C31;
$primary-brand-color: #004D2E;
$secondary-brand-color: #F4F2EA;
$register-button-color: #01697C;

$icon-color: #D1AD82;
$bg-card-color: #4E725E;
$text-base-color: #333333;
$disabled-color: #787878;
$alert-color: #3C8CC8;
$alert-info-text: #20579D;
$bg-base-color: #FFFFFF;
$grape-red-color: #792A2A;
$grape-rose-color: #CEA0A0;
$error-color: #EB5757;
$button-green-color: #75B100;
$textShadow: 0px 1px 2px rgba(0, 0, 0, 0.8), 0px 0px 50px #393F58;


// NEW BRAND COLORS
$vineify-green: #004D2E; //Official brand colors and the primary color for the app.
$white: #FFFFFF; //Page backgrounds.
$alabaster-50: #FAF9F5; //Lighter backgrounds.
$alabaster: #F4F2EA; //Footer and other backgrounds.
$warm-gray-3: #C7C2BA; //Divider lines.
$warm-gray-6: #A59D95; //
$warm-gray-9: #82786F; //
$warm-gray-12: #5C5751; //
$overlay-20: rgba(0, 0, 0, 0.2); //Faint shade overlay.
$overlay-40: rgba(0, 0, 0, 0.4); //Stronger shade overlay for dialogs.
$primary: #403D3B; //Headlines and titles.
$secondary: #333231; //Body text and paragraphs.
$tertiary: #63615E; //Subtle description text.
$quarternary: #ABA9A7; //Disabled or placeholder text.
$text-reversed: #FFFFFF; //Used when text is on an image.
$white-wine: #D1AD82; //Used when referencing white wines.
$white-wine-text: #BD9C75; //Slightly darker white wine to ensure contrast for text.
$rose-wine: #CFA1A1; //Used when referencing rose wines.
$rose-wine-text: #BA9191; //Slightly darker rose wine to ensure contrast for text.
$red-wine: #782A2A; //Used when referencing red wines.
$success: #7AB800; //Indicates something positive.
$success-light: #F1FDD8; //Used for banner backgrounds. 
$info: #0090DB; // Indicates something neutral.
$info-light: #D8F1FD; // Used for banner backgrounds. 
$warning: #EB5757; // Indicates something negative.
$warning-light: #FEE2E1; // Used for banner backgrounds. 
$dark-gold: #A2905A;
$accent: #A1006B;

$sparkling-wine: #004D2E;

//text sizes
$h1: 84px;
$h1-mobile: 48px;
$h2: 60px;
$h2-mobile: 40px;
$h3: 44px;
$h3-mobile: 32px;
$h4: 32px;
$h4-mobile: 28px;
$h5: 24px;
$h5-mobile: 22px;
$subtitle: 20px;
$subtitle-mobile: 20px;
$body: 16px;
$caption: 14px;
$caption-mobile: 14px;

$border-radius: 8px;
$border-color: #C7C2BA;

$avatar-filter-shadow: drop-shadow(4px 0 4px rgba(0, 0, 0, 0.25));

@mixin flexLayout($align, $justify, $direction: row, $display: flex) {
  display: $display;
  align-items: $align;
    justify-content: $justify;
    @if $direction {
        flex-direction: $direction;
    }
    
}

@mixin boxShadow($color: rgba(0, 0, 0, 0.15), $spread: 15px) {
  box-shadow: 0px 0px $spread $color;
}


$media-lg: 1440px;
$media-md: 1024px;
$media-sm: 768px;
$media-xs: 520px;
