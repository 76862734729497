@import '../../theme/variables';

.reg-footer {
  padding: 32px 0px;
  background-color: $alabaster;

  .footer-logo-section {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .footer-social-links {
      padding: 36px 0;
      display: flex;
      gap: 12px;
  
      .social-link {
        width: fit-content;
        @include flexLayout(center, center);
        border: 2px solid $vineify-green;
        padding: 6px;
        border-radius: 50%;
      }
    }

    @media (max-width: $media-xs) {
      justify-content: center;
    }
  }

  @media (max-width: $media-sm) {
    height: auto;
    padding: 32px;
  }

  @media (max-width: $media-xs) {
    height: auto;
    padding: 32px 12px;
  }
}

footer:not(.reg-footer) {
  padding: 20px;
  background-color: $alabaster;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .footer-links {

    .simple-link {
      display: inline-block;
      width: 200px;
      text-align: center;
    }

    @media (max-width: $media-xs) {
      width: 100%;
      @include flexLayout(center, center, column);
      gap: 8px;
    }
  }
}

.footer-content {
  display: flex;
  align-items: stretch;
  position: relative;

  .footer-logo { 
    margin-right: 80px;

    @media (max-width: $media-md) {
      width: 100%;
      height: 40px;
      margin-right: 0;
      margin-bottom: 32px;
    }

    @media (max-width: $media-sm) {
      margin-bottom: 40px;
    }

    @media (max-width: $media-xs) {
      margin-bottom: 12px;
    }
  }

  @media (max-width: $media-md) {
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  @media (max-width: $media-xs) {
    gap: 20px;
    justify-content: center;
  }
}

.footer-links {
  padding: 16px 8px;;

  .links {
    width: 160px;

    &__title {
      color: $primary;
      font-weight: bold;
      margin-bottom: 16px;
      font-family: $quincy;
      font-size: $h5;
      line-height: 1;
    }

    &__item {
      margin-bottom: 8px;

      .button-link {
        color: $vineify-green;
        padding: 0;
        text-decoration: none;
      }
    }

    @media (max-width: $media-sm) {
      max-width: 160px;
      width: auto;
    }
  }

  a {
    text-decoration: none;

    &.simple-link { 
      padding: 0px 10px; text-decoration: underline; 
    }
  }

  @media (max-width: $media-xs) {
    width: 40%;
    flex-grow: 1;
  }
}

.copyright {
  margin-top: 32px;

  p { 
    color: $disabled-color;
    margin: 0;
    text-align: center; 
  }
}
