@import '../../theme/variables';


.wines-types-list {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 8px;
  width: 100%;
  color: $white;
  text-transform: uppercase;

  li + li {
    margin-left: 6px !important;

    &::before {
      content: "\2022";
      margin-right: 6px;
    }
  }
}

.case-sale-row {
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 24px;

  .sale-link {
    width: 50px;
    padding: 6px 12px;

    &.fluid {
      flex-grow: 1;
      width: 220px;
    }
  }
}

.price-details { 
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  font-size: 14px;

  li {
    display: flex;
    padding: 4px 8px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__total {
    font-size: 16px;
    font-weight: 600;
    margin-top: 12px;
    border-top: 1px solid;
  }
}

.order-contact-summary {
  width: 100%;
  position: relative;

  .contact-title {
    padding: 0;
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 16px;
    margin-top: 0;
    color: $text-base-color;
  }

  h3 {
    margin-top: 0;
  }

  p {
      margin-bottom: 8px;
      margin-top: 0;
  }

  .button-link {
      padding: 0;
      margin-top: 8px;
  }

  @media (max-width: $media-sm) {
    padding: 8px 0;
    width: 100%;
    max-width: 100%;

    img { margin-bottom: 32px; }
  }
}

.case__countries {
  @include flexLayout(center, flex-start);
  gap: 24px;
  margin-top: 32px;

  li {
    @include flexLayout(center, flex-start);
    gap: 8px;
    text-transform: uppercase;
    color: $warm-gray-9;
    font-weight: 500;
  }
}

.case-stock-badge {
  position: absolute;
  left: 40px;
  top: 47px;
  color: white;
  font-weight: 700;
  z-index: 10;

  &.with-case-details-btn {
    color: $white-wine-text;
    top: 50%;
    left: -24px;
    transform: translate(-100%, -50%);
    white-space: nowrap;

    @media (max-width: $media-xs) {
      top: 0;
      left: 50%;
      transform: translate(-50%, -32px);
    }
  }

  &.highlighted {
    left: 120px;

    @media (max-width: $media-sm) {
      left: 48px;
    }

    @media (max-width: $media-xs) {
      left: 16px;
      top: 16px;
    }
  }

  &.small-case-card {
    top: 20px;
    left: 20px;
  }

  &.checkout-card {
    left: 16px;
    top: 32px;
  }

  @media (max-width: $media-xs) {
    left: 16px;
    top: 12px;
  }
}

.tags-list {
  padding: 0;
  flex-shrink: 0;
  list-style: none;
  gap: 8px;
  display: inline-flex;

  &__item {
    background-color: $white-wine;
    border-radius: 4px;
    font-size: 14px;
    text-transform: uppercase;
    padding: 4px 12px;
    display: inline-block;
    font-weight: 700;
  }
}

.wine-sort-dropdown {
  flex-shrink: 0;
  width: 260px;
  max-width: 30%;
  z-index: 10;
}

.wines-categories-buttons {
  width: 100%;
  @include flexLayout(center, center);
  gap: 16px;


  .category-button {
    flex-grow: 1;

    &.button {
      background-color: $white;
      color: $vineify-green;
      border: 1px solid $vineify-green;
  
      &.active {
        background-color: $vineify-green;
        color: $white;
      }
    }
  }
}

.sign-to-buy {

  &__text {
    font-size: 18px;
    text-align: center;
  }

  &__button.button-link {
    font-weight: 600;
    padding: 0;
  }

  &__icon {
    margin: auto;
  }

  &__title {
    margin: 0;
    padding: 16px 0;
    text-align: center;
    font-size: $h4;
  }
}

.case-bottles {
  position: absolute;
  display: flex;
  transition: opacity .3s;
  justify-content: center;
  align-items: center;
  bottom: 6px;
  left: 0;
  width: 100%;
  height: 180px;
  padding: 0;
  gap: 0;

  img {
    height: 100% !important;
    width: auto !important;
    z-index: 5;
  }

  &.chess-pattern {
    gap: 0;
    height: 155px;
    margin-left: -8px;

    img:nth-child(even) {
      margin-left: -20px;
      margin-right: -20px;
      z-index: 2;
      margin-bottom: 24px;
    }

    @media (max-width: $media-xs) {
      height: 145px;
    }
  }
}

.regions-tags-list {
  @include flexLayout(center, flex-start);
  width: 100%;
  gap: 8px;
  padding: 0 16px;
  margin: 0;

  &__region {
    font-weight: 400;
    font-size: 14px;
    padding: 4px 12px;
    border-radius: 8px;
    background-color: $alabaster;
  }
}

.case-image-wrapper {
  width: 100vw;
  max-width: 370px;
  height: 205px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;

  img {
    width: 100%;
  }

  .price-label {
    position: absolute;
    color: white;
    width: 80px;
    height: 36px;
    border-radius: 0px 4px 4px 0px;
    background-color: #BCAB75;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -2px;
    top: 16px;
    z-index: 10;
  }
}

.labels-control {

  &__header {
    display: flex;
    align-items: center;
    gap: 36px
  }

  &__title {
    font-size: 32px;
    font-family: $avenir;
    font-weight: 600;
  }

  &__items {
    display: flex;
    gap: 12px;
    padding: 16px;
    border: 2px solid grey;
    border-radius: 8px;

    .label-pill {
      padding: 6px 16px;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;

      &.public {
        background-color: rgba($info-light, .8);
        border-color: $info-light
      }

      &.private {
        background-color: rgba($warning-light, .8);
        border-color: $warning-light
      }

      &:hover {
        opacity: .8;
      }
    }
  }

  &__history {
    display: flex;
    gap: 32px;
    align-items: center;
    padding: 8px 16px;

    &-item {
      font-style: italic;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 12px;

      &::before {
        content: "";
        display: block;
        height: 14px;
        width: 14px;
        border-radius: 50%;
      }

      &.public::before { background-color: rgba($info-light, .8); }
        
      &.private::before { background-color: rgba($warning-light, .8); }
    }
  }
}

#image-content {
  position: relative;
  overflow: hidden;
  background-color: white;

  &.visible {
    height: 100%;
  }

  &.not-visible {
    height: 0;
    width: 0;
    visibility: visible;
    margin-top: -14px;
  }
}

.labels-dialog {
  overflow-y: visible !important;
  overflow-x: visible !important;
}

.case-details__curator-preview {
  display: flex;
  align-items: center;
  gap: 12px;

  img { 
    height: 94px;
    border-radius: 50%;
  }

  .curator-preview__follow-button {
    margin: 8px 0;
  }

  .curator-preview__content {
    display: flex;
    flex-direction: column;

    &-text {
      color: #646260;
      margin: 0;
    }

    &-link {
      color: $vineify-green;
      font-weight: 600;
    }
  }
}

.stack-images {
  display: flex;
  align-items: center;

  &__item {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    object-fit: cover;
    outline: 3px solid white;

    &:not(:first-child) {
      margin-left: -5%;
    }
  }
}
