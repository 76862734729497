@import '../../theme/variables';


.pending-spinner {
  position: absolute;
  right: -25px;
  bottom: 8px;
  width: 25px;
  height: 25px;

  &.small {
    bottom: 14px;
    width: 20px;
    height: 20px;
  }

  &.inner {
    right: 6px;
  }
}

.radio-button-group {
  display: flex;
  align-items: center;
  gap: 16px;

  &__buttons {
    display: flex;
    align-items: center;

    .button {
      gap: 8px;
      border-radius: 0;
      border-width: 1px;
      margin-left: -1px;

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &:first-child {
        margin-left: 0px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &.active {
        background-color: $vineify-green !important;
        color: white;
      }
    }
  }
}
