@import '../../theme/variables';

.admin-nav__logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 35px;
  padding: 24px 0;
  background-color: $secondary-brand-color;

  a {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    font-size: 14px;

    img {
        margin-right: 16px;
    }
  }
}

.order-sidebar {
    background-color: rgba($secondary-brand-color, 0.5);
    padding: 96px 56px;
    height: 100%;

    .case-stock-badge {
      top: 40px;
      left: 16px;
    }

    .card_case {
      // height: 195px;

      .card__body {
        .card_case-content {
          .case__title {
            font-size: $h5;
          }
        }
      }
    }

    .subtitle { margin-top: 32px; }

    &-wines {

        li {
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-bottom: 16px;

            span {
                margin-right: 16px;
                display: flex;
                align-items: center;

                img { margin-right: 8px; }
            }
        }
    }

    @media (max-width: $media-sm) { 
        padding: 32px 16px;
    
        img {
            max-width: 100%;
        }
    }

    @media (max-width: $media-xs) {
        padding: 16px 0;
        background-color: transparent;
    }
}

.price {
    position: relative;
    margin: 32px 0;
    font-size: 18px;
    border-top: 1px solid $disabled-color;

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 12px;
        padding-bottom: 24px;
    }

    small {
        font-style: italic;
        margin-top: -16px;
        display: block;
        text-align: right;
    }
}

.shipping__item {
    @include flexLayout(flex-start, space-between);
    padding-top: 24px;
    font-size: .95rem;
}

.admin-sidebar {
  position: relative;
    width: 300px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    background: $white;
    flex-shrink: 0;

    &.collapsed {
      display: none;
    }

    @media print {
      display: none;
    }


    .admin-nav {
        padding: 0 0 36px;
        height: 100%;


        .admin-nav__item {
            padding: 16px 80px;
            opacity: .5;


            .admin-nav__link {
                display: flex;
                align-items: center;
                color: $text-base-color;
                font-size: 18px;
                font-weight: 600;


                .admin-nav__icon {
                    height: 26px;
                    width: 26px;
                    filter: brightness(0);
                    margin-right: 15px;
                }

                &.button-link {
                    color: $text-base-color;
                    text-decoration: none;
                }

            }
            
            &.active {
                opacity: 1;
                background-color: $secondary-brand-color;
            }
        }
    }
}

.collapse-sidebar-button {
  position: absolute !important;
  top: 4px;
  left: 32px;
  z-index: 100;
}
