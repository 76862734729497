@import '../../theme/variables';

.grapes-row {
    display: flex;

    .grapes-column {
        display: flex;
        flex-direction: column;
        width: 50%;

        h3 {
            padding: 8px 42px;
            margin: 0;
            display: flex;
            align-items: center;
            color: $white;
            font-size: 18px;
            border-radius: 10px;
            margin-bottom: 16px;

            &.red { background-color: #792A2A; }

            &.white { background-color: #D1AD82; }

            &:before {
                content: "";
                display: block;
                background-image: url('../../assets//icons/white-grape.svg');
                margin-right: 8px;
                width: 20px;
                height: 27px;
                background-size: cover;
            }
        }
    }

    .grapes-column + .grapes-column { margin-left: 16px; }

    @media (max-width: $media-sm) {
        flex-direction: column;

        .grapes-column { width: 100%; }

        .grapes-column + .grapes-column { margin-left: 0; margin-top: 60px }
    }

    @media (max-width: $media-xs) {
        padding-bottom: 32px;
    }
}

.card {
    position: relative;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba($disabled-color, .2);
    border-radius: 8px;
    transition-duration: .3s;
    transition-property: box-shadow;
    transition-timing-function: ease-in-out;
    position: relative;
    overflow: hidden;

    &.card_tasting {
        width: 339px;

        .button {
            margin-top: 8px;
        }
    }

    &_tasting + &_tasting {
        margin-left: 32px;
    }

    &_with-details {

        &:hover {
            box-shadow: 0px 3px 10px rgba(51, 51, 51, 0.5);
        }

        &.active, &:focus {
            border: 2px solid $primary-brand-color;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

            &:after {
                content: "";
                display: block;
                width: 40px;
                height: 40px;
                border: solid darkgreen;
                border-width: 0 2px 2px 0;
                border-bottom-right-radius: 10px;
                transform: rotate(45deg) translate(0, 100%);
                background: $white;
                position: absolute;
                z-index: 500;
                bottom: 7px;
                left: 50%;

                @media (max-width: $media-sm) { display: none; }
            }
        }

        button: { z-index: 10; }
    }

    &_subregion {
        height: 265px;
    }

    &__header {
        padding: 16px 32px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow: hidden;
        background-color: $bg-card-color;
        color: $white;
        font-size: 24px;
        line-height: 1;

        &_tasting {
            padding: 0;
            overflow: hidden;
            background-color: transparent;
        }
    }

    &.card_case {
        border: none;

        .card-bg {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            z-index: 5;

            img {
                min-height: 100%;
                min-width: 100%;
                width: 100%;
            }
        }

        .card-body-wrapper {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 7;

            &.case_white {
                background-color: $dark-gold;
                opacity: .6;

                // & + .card-bg { opacity: .5; }
            }
    
            &.case_red {
                background-color: $red-wine;
                opacity: .6;

                // & + .card-bg { opacity: .5; }
            }

            &.case_brown {
                background: linear-gradient(180deg,#2f211a,#0e090761 165%);
                opacity: 0.6;

                // & + .card-bg { opacity: .5; }
            }
    
            &.case_mix {
                background: black;
                opacity: .4;
            }
        }

        
        .card__body {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 24px 16px;
            height: 100%;
            position: relative;

            .card-bottom-section {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-grow: 1;
            }
        }

        .short-description {
            // color: $white;
            font-size: 18px;
            min-height: 75px; 
        }

        &.case_full-size {

            .card__body {
                padding: 28px 120px;
            }
        }

        .card_case-content {
            display: flex;
            flex-direction: column;
            max-width: 100%;
            justify-content: space-between;
            height: 100%;
            margin-top: 16px;

            .short-description { max-width: 100%; }
        }

        .button {
            margin: 0;
            // width: 270px;
            max-width: 100%;
            width: 100%;

            @media (max-width: $media-xs) { width: auto; }
        }
    }

    .case__title {
        font-size: 28px;
        line-height: 1;
        font-family: $quincy;
        color: $white;
        margin-top: 16px;
        margin-bottom: 0;
        font-weight: 500;
    }

    .case__subtitle {
        margin-top: 0;
        color: $white;
        width: 100%;
        margin-bottom: 16px;
    }

    .case__image {
        height: 100%;
        display: flex;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        z-index: 0;

        img { height: 100%; width: auto; }
    }

    .case__price {
        font-size: 14px;
        line-height: 1;
        margin: 0px;
        position: absolute;
        padding: 8px 16px;
        // background-color: #333333;
        background-color: $warm-gray-12;
        color: $white;
        font-weight: normal;
        right: 0;
        top: 8px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    &__body {
        padding: 32px;
        width: 100%;
        z-index: 10;
        

        &_tasting {
            padding: 24px;

            h4 {
                font-size: 18px;
                font-weight: 800;
                color: $disabled-color;
                margin: 0;
                line-height: 1.3;
            }

            h3 {
                display: inline-block;
                height: 80px;
                font-family: $quincy;
                font-size: 24px;
                line-height: 1;
                color: $text-base-color;
            }
        }

        p { color: $disabled-color; }
    }

    .button {
        width: 100%;
        margin-top: 32px;
    }

    a {
        text-decoration: none;
        margin: 15px auto;
        text-align: center;
        display: block;
    }

    &.grape_card {
        border-radius: 10px;
        padding: 24px 32px;
        min-width: 300px;
        background-color: $white;
        overflow: hidden;
        border-left: 8px solid;
        margin-bottom: 16px;
        
        .card__header {
            font-size: 18px;
            background-color: transparent;
            display: flex;
            padding: 0;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            .header-main-info  {
              @include flexLayout(flex-start, space-between, column);
              gap: 16px;

              .grape-name {
                display: flex;
                align-items: center;

                img { margin-right: 8px; }
              }

              .grape-tastes-list {
                display: flex;
                // height: 36px;
                align-items: baseline;
    
                .grape-taste {
                    display: list-item;
                    font-size: 14px;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: $tertiary;
                }
    
                .grape-taste + .grape-taste {
                    list-style: disc;
                    margin-left: 27px;
                }
    
                @media (max-width: $media-sm) {
                    height: 60px;
                    align-items: center;
                    flex-wrap: wrap;
                }
            }
          }

          .grape-image {
            height: 64px;
            width: 64px;
            border-radius: 50%;

            @media (max-width: $media-sm) {
              height: 40px;
              width: 40px;
            }
          }
        }

        &.grape_white { 
            border-left-color: $white-wine-text;

            .card__header { color: $white-wine-text !important; }
        }

        &.grape_red { 
            border-left-color: $red-wine; 

            .card__header { color: $red-wine !important; }
        }

        &.grape_rose { 
          border-left-color: $rose-wine; 

          .card__header { color: $rose-wine !important; }
      }

        .grape-description p {
            white-space: pre-line;
        }

        &.border-card {

            &_red {
                border-color: $grape-red-color;;
            }

            &_white {
                border-color: $icon-color;
            }

            &_sparkling {
                border-color: $disabled-color;
            }
        }
    }
}

.full-size_case-wrapper {
    margin-bottom: 16px;

    .card_case {
      height: 366px !important;

      .card-body-wrapper {
        background: rgba(0, 0, 0, 0.4);
      }

        .card__body {
            padding: 26px 120px !important;
            position: relative;

            .card_case-content {
              margin-top: 62px !important;

              @media (max-width: $media-sm)  {
                margin-top: 32px !important;
              }
            }

            .card-bottom-section {
                flex-direction: row;
                align-items: flex-end;
                gap: 16px;

                .short-description { width: auto; }
                
                .button {
                    margin-bottom: 18px; 
                    width: auto !important;
                    flex-shrink: 0;
                }

                @media (max-width: $media-sm) {
                  .flex-space-between:not(.w-100) {
                    flex-direction: column;
                    align-items: stretch;

                    .button {
                      margin: 12px 0 0 0 !important;
                      // margin-top: 12px;
                      // margin-bottom: 0;
                  }

                  // button + button {
                  //     margin-right: 0px !important;
                  // }
                  }
                }

                @media (max-width: $media-xs) {
                    flex-direction: column;
                    align-items: initial;

                    .flex-space-between {
                        flex-direction: column;

                        .button {
                            margin-top: 12px;
                            margin-bottom: 0;
                        }

                        button + button {
                            margin-right: 16px;
                        }
                    }

                    .flex-space-between:not(.w-100) {
                      flex-direction: row;
                      justify-content: stretch;
                      width: 100%;
                      gap: 16px;

                      .button { 
                        padding: 0 16px;
                        flex-grow: 1;
                      }
                    }
                }
            }


            @media (max-width: $media-sm) { padding: 24px 48px !important; }
            @media (max-width: $media-xs) { padding: 0 16px !important; }
        }

        .case__title {
            // font-size: 75px;
            font-size: $h2;
            line-height: 1;
            margin-bottom: 12px;
            // margin-top: 44px;
            margin-top: 0;
    
            @media (max-width: $media-xs) { 
              font-size: 36px;
              margin-top: 16px;
              margin-bottom: 0;
            }
        }

        .case__price {
            font-size: 18px;
            top: 16px;

            @media (max-width: $media-xs) {
                font-size: 16px;
                top: 8px;
            }
        }

        @media (max-width: $media-md) { width: 100% !important; }
    }

    &.homepage-case .card_case {
        height: 350px !important;

        .card__body .card-bottom-section .flex-space-between {

          .short-desc-wrapper + .flex-space-between {
            flex-shrink: 0;

            button + button { margin-right: 0; }
          }
        }
    }
}

.tasting-card-wrapper {
    padding: 0 16px;
}


.wines-list {
    display: flex;
    flex-wrap: wrap;
    margin: 25px -16px 0;

    .wine-card-wrapper {
        padding: 8px;
        flex: 33.3333% 0 0;

        @media (max-width: $media-sm) {
            padding: 0;
        }
    }
    

    .card {
        min-width: 275px;
        width: 100%;

        &-header {
          padding: 0px;
          position: relative;
          overflow: hidden;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          height: 220px;


          img { 
            width: 100%;
            height: 100%;
          }

          .badge {
            width: 40px;
            height: 40px;
            font-size: $subtitle;
            font-weight: 600;
            border-radius: 50%;
            position: absolute;
            top: 16px;
            right: 16px;
            background-color: $bg-card-color;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .wine__name {
            position: absolute;
            font-weight: 700;
            text-align: left;
            bottom: 0;
            left: 0;
            padding: 16px;
            width: 100%;
            color: #fff;
            margin: 0;
            text-shadow: 1px -1px 5px #000;
            height: 100%;
            display: flex;
            align-items: flex-end;
            background-image: linear-gradient(#f5f6fc00, #00000059 85%, #000000b3 100%);
          }

          &.wine_card {

            hr.wine__grape {
              margin-top: 20px;
            }
          }
        }

        &__body {
          padding: 16px;
          position: relative;

          .wine__description {
            margin-top: 0;
            margin-bottom: 20px;
          }

          .wine__grape {
            display: inline-flex;
            align-items: center;
          }

          .wine__region {
            @include flexLayout(center, flex-start);
            gap: 6px;
          }

          .wine-tastes {
            // color: $text-base-color;
            list-style: disc;
            padding: 5px 15px;
            display: flex;
            flex-direction: column;
            gap: 6px;
            margin-top: 16px;

            @media (max-width: $media-xs) {
              flex-direction: column;
              gap: 4px;
            }
          }
        }


        &.wine_recommendation {
            background-color: $white;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

            .card-header {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0px;
                position: relative;
                height: 220px;
            }

            .card__body {
                padding-top: 16px;
                padding-bottom: 16px;

                p { margin: 0; }

                .wine__region {
                    display: inline-flex;
                    align-items: center;
                    color: $tertiary;

                    img, svg { margin-right: 4px; }
                }

                .wine__name {
                    font-size: 20px;
                    margin-top: 0;
                    margin-bottom: 16px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        @media (max-width: $media-md) { 
            flex: 47.5% 0.33;
        }

        @media (max-width: $media-sm) { 
            flex: 325px 0 0;
            width: 300px;
        }

    }

    @media (max-width: $media-sm) { 
        margin: 0; 
        display: block;
    }
}

.nav-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    border: 1px solid $disabled-color;
    flex: 33.3333% 1 1;
    border-radius: 8px;
    padding: 16px;
    text-decoration: none !important;

    & + & { 
        margin-left: 24px;
        
        @media (max-width: $media-xs) {
            margin-left: 6px;
        }
    }

    &__icon { 
        height: 24px;

        @media (max-width: $media-xs) {
            height: 16px;
        }
    }

    &__title {
        font-size: $subtitle;
        line-height: 1;
        letter-spacing: 0.01em;
        margin: 0 0 0 8px;

        @media (max-width: $media-xs) {
            font-size: 14px;
        }
    }

    &.active {
        background-color: $primary-brand-color;

        .nav-card__icon { filter: brightness(0) invert(1); }

        .nav-card__title { color: $white; }
    }

    @media (max-width: $media-xs) {
        height: 40px;
        padding: 12px;
    }
}

.order-card {
    margin-top: 28px;
    padding: 32px 56px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .divider {
      height: 1px;
      display: inline-block;
      width: 100%;
      margin: 8px 0;
      border-top: 1px solid rgba($icon-color, 0.4);
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .order-card__title {
            display: flex;
            align-items: baseline;

            h3 {
                font-size: $h5;
                padding: 0px;
                line-height: 1;
                font-weight: 700;
                margin-right: 16px;
            }

            @media (max-width: $media-sm) {
                flex-direction: column;

                h3 { padding: 0; }

                p { margin: 0; }
            }

            @media (max-width: $media-xs) {
                width: 100%;
                margin-bottom: 12px;
            }
        }

        @media (max-width: $media-xs) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__body {
        display: flex;
        padding: 24px 0px;

        .card_case {
          width: 295px;
          height: 240px;
          flex-shrink: 0;

          .card__body {
            align-items: flex-start;

            .card_case-content {

              .case__title {
                  margin-bottom: 16px;
              }

              .wines-types-list {
                font-size: 12px;
              }
            }
          }
        }

        .body-image {
            width: 230px;
            height: 140px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            border-radius: 10px;

            img { width: 100%; }

            @media (max-width: $media-md) {
                flex-basis: 50%;
                height: 230px
            }

            @media (max-width: $media-xs) {
                width: 100%;
            }
        }

        .body-info {
            flex-grow: 1;
            position: relative;
            display: flex;
            padding-left: 32px;
            gap: 32px;
            color: $secondary;
            justify-content: space-between;

            .body-section {
                display: flex;
                flex-direction: column;
                // padding: 0 24px;
                padding: 0;
                flex-grow: 1;
                // max-width: 220px;
                min-width: 175px;

                &__row:not(:last-child) {
                  margin-bottom: 16px;
                }

                &:nth-child(2) {
                  flex-grow: 1;
                  flex-shrink: 0;
                  max-width: initial;
                }

                &__title { color: $tertiary; font-weight: 700; }
                
                &__content {
                    margin-top: 14px;
                    margin-bottom: 0;
                    line-height: 1.5;
                    font-size: 16px;

                    p{
                        margin: 0;
                        line-height: 1.5;
                    }

                    small {
                      color: $tertiary;
                    }

                    // p + p { margin-top: 12px; }
                }

                @media (max-width: $media-sm) {
                    padding: 0;
                    max-width: none;
                    min-width: auto;

                    &:first-child {
                        flex-grow: 0;
                    }

                    &__content {
                        margin: 0;
                    }
                } 

                @media (max-width: $media-sm) {
                    padding: 8px;
                } 
            }

            @media (max-width: $media-sm) {
                flex-direction: column;
                flex-basis: 50%;
                padding-left: 24px;
            }

            @media (max-width: $media-xs) {
                margin: 0;
                padding: 0;
                flex-basis: 100%;
            }
        }

        @media (max-width: $media-md) {
            padding: 16px 0;
        }

        @media (max-width: $media-xs) {
            flex-direction: column;
        }
    }

    &__footer {
        border-top: 1px solid rgba($icon-color, 0.4);
        padding: 24px 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .ctrl-btn {
            cursor: pointer;
            padding: 8px 16px;
            color: $vineify-green;
            background-color: transparent;
            border: none !important;
            outline: none !important;
        }
    }


    .order-details {
        display: none;
        transition: transform .7s ease-in-out;
        transform: translateY(-100%);
        width: 100%;

        .positions-list {
            
            .case-position {
                padding: 24px 32px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &.header {
                    font-weight: bold;
                    color: $primary;
                    font-size: $subtitle;
                    padding-top: 0;
                    padding-bottom: 0;

                    @media (max-width: $media-sm) {
                        display: none;
                    }
                }

                .pos-image {
                    position: relative; 
                    height: 80px;
                    width: 80px;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;

                    img {
                        height: 100%;
                    }
                }

                .pos-info { 
                    flex: 75% 1 1;
                    display: flex;
                    align-items: center;

                    p { margin: 0; }
                    

                    .pos-name {
                        width: 65%;
                        padding: 0px 32px;
                        flex-grow: 1;
                        color: $secondary;
                    }

                    
                    .producer { width: 30% }
    
                    .pos-quantity { width: 10%; color: $tertiary; }

                    @media (max-width: $media-xs) {
                        flex-direction: column;
                        width: 50%;
                        padding-left: 16px;

                        .pos-name {
                            width: 100%;
                            padding: 0;
                        }
                        
                        .producer { width: 100% }
        
                        .pos-quantity { width: 100%; }
                    }
                }


                @media (max-width: $media-xs) {
                    padding: 16px;
                }
            }

            .case-position + .case-position { border-top: 1px solid rgba($icon-color, 0.4); }
        }
    }

    &.expanded {

        .order-details {
            display: block;
            transform: translateY(0%);
        }
    }

    @media (max-width: $media-sm) {
        width: 100%;
        padding: 24px;
    }
}

.admin-item-row {
    display: flex;
    height: 75px;
    align-items: center;
    padding: 16px;

    .checkbox-wrapper { display: initial; }

    .wine-select { margin-right: 16px; }

    .wine-image {
        height: 100%;

        img {
            max-height: 100%;
            border-radius: 10px;
        }
    }

    .wine-id {
      padding: 0 8px;
      font-weight: 600;
      width: 36px;
      background-color: transparent;
    }

    .wine-producer {
      padding: 0 8px;
      flex-basis: 150px;
    }

    .wine-name {
      padding: 0 24px;
      font-size: 18px;
      font-weight: bold;
      flex-basis: 250px;
      flex-grow: 1;
    }

    .wine-vintage {
      flex-basis: 80px;
      min-width: 50px;
      padding: 0 8px;
      text-align: center;
    }

    .wine-stock, .wine-cost, .wine-price {
      flex-basis: 80px;
      padding: 0 8px;
      text-align: center;
    }

    .wine-stock {
      position: relative;
      flex-basis: 120px;
    }

    .wine-region {
      flex-basis: 120px;
      padding: 0 8px;
    }

    .wine-control {
        flex-basis: 80px;
        min-width: 75px;
        text-align: center;

        button {
          padding-bottom: 0;
          padding-top: 0;
        }
    }

    & + & {
      border-top: 1px solid rgba($primary-brand-color, 0.15);
    }
}

.admin-order-row, .admin-order-headers {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  color: $disabled-color;

  .order {
    &-number {
      padding: 0 8px;
      flex: 65px 0 0;
      min-width: 30px;
      font-size: 14px;
      font-weight: 600;
    }

    &-price {
      padding: 0 8px;
      flex: 15% 0 0;
      text-align: center;
      min-width: 60px;
    }

    &-owner {
      padding: 0 8px;
      flex: 15% 0 0;
      min-width: 200px;
    }

    &-date {
      padding: 0 8px;
      flex: 120px 0 0;
      font-size: 85%;
      min-width: 120px;
    }

    &-case {
      position: relative;
      display: inline-flex;
      align-items: center;
      padding: 0 8px;
      flex: 35% 1 1;
      min-width: 125px;
    }

    &-status {
      padding: 0 16px;
      width: 120px;
      flex-shrink: 0;
    }
  }

  & + & + & {
    border-top: 1px solid $disabled-color;
  }

  &:hover {
    background-color: rgba($primary-brand-color, .2);
    text-decoration: none;
  }
}

.admin-order-headers {
  background-color: $primary-brand-color;
  font-weight: normal;
  margin-bottom: 8px;

  .order-number, .order-price, .order-owner, .order-date, .order-case, .order-status {
    font-size: 120%;
    padding-top: 8px;
    padding-bottom: 8px;
    text-transform: capitalize;
    color: $white;
  }
}

.admin-order-mobile-row {
  padding: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px 16px;
  color: $disabled-color;

  .order {
    &-number {
      min-width: 30px;
    }

    &-price {
      text-align: center;
      min-width: 60px;
    }

    &-owner {
      flex: 15% 0 0;
      min-width: 200px;
    }

    &-date {
      flex: 120px 0 0;
      min-width: 150px;
    }

    &-case {
      position: relative;
      display: inline-flex;
      align-items: center;
      gap: 4px;
      flex-wrap: wrap;
      flex-grow: 1;
    }

    &-status {
      // width: 120px;
      flex-shrink: 0;
    }
  }

  & + & {
    border-top: 1px solid $disabled-color;
  }
}

.order-contact-details {
    min-height: 275px;
    padding: 16px 32px;
    border-radius: 10px;
    background-color: rgba($secondary-brand-color, .35);
}


.account-wine {
    padding: 16px 32px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: background-color 0.6s ease-out;
    position: relative;
    margin-bottom: 16px;
    color: $secondary;

    &.adjusted {
        background-color: $secondary-brand-color;
    }

    &__row {
        width: 100%;
        position: relative;
        display: flex;
        gap: 32px;
        align-items: center;
        
        &.out-of-stock {
          background-color: $white;
          opacity: .4;
        }
        
    
        .wine-like {
          position: relative;
          cursor: pointer;
          border: none !important;
          background-color: transparent;
          outline: none !important;
          width: 50px;
          cursor: pointer;
        }
    
        .wine-image {
          position: relative;
          height: 80px;
          width: 60px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
  
          img { max-height: 100%; }
        }

        .wine-info {
          flex-grow: 1;
          line-height: 1.2;
          display: flex;
          gap: 32px;
          align-items: center;

          .wine-vintage {
            min-width: 60px;
            text-align: center;
          }
      
          .wine-name {
            width: 150px;
            // font-size: 18px;
            font-size: $subtitle;
            font-weight: 600;
            flex-grow: 1;
          }
      
          .wine-producer {
            width: 125px;
          }
      
          .wine-grape {
            display: flex;
            align-items: center;
            font-weight: 500;
            width: 125px;
            padding: 0;

            &.grape_red { color: $red-wine; }

            &.grape_white { color: $white-wine-text; }

            &.grape_sparkling { color: $vineify-green; }
          }

          @media (max-width: $media-md) {

            .wine-producer { width: 100px; }
          }

          @media (max-width: $media-sm) {
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 0px;

            .wine-vintage {
              order: 1;
              padding: 0 24px;
              display: none;
            }

            .wine-name { 
              width: 300px;
              text-align: left;
            }

            .wine-producer {
              order: 2;
              flex-grow: 1;
              padding: 0 16px;
              display: none;
            }

            .wine-grape { 
              order: 3;
              width: auto;
              text-align: left;
            }
          }

          @media (max-width: $media-xs) {
            flex-direction: column;
            align-items: stretch;
            padding: 8px 16px;
            width: calc(100% - 120px);
            flex-grow: 1;
            margin-top: 8px;

            .wine-vintage {
              padding: 0;
              order: initial;
            }

            .wine-name {
              padding: 0;
              font-size: 16px;
              width: 100%;
              margin: 8px 0 16px;
            }

            .wine-producer {
              padding: 0;
              width: 100%;
            }

            .wine-grape {
              padding: 0;
              width: 100%;
            }
          }
        }
    
        .wine-inventory {
            width: 170px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
    
            &__badge {
              @include flexLayout(center, center);
                background-color: $vineify-green;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                color: $white;
                text-align: center;
                // font-size: 16px;
                font-size: $subtitle;
                font-weight: 600;
                line-height: 1;
            }
    
            .button-link { padding: 8px 16px; }

            @media (max-width: $media-sm) {
                // flex-direction: column;
                width: 150px;

                input {
                  order: 1;
                  height: 32px;
                  font-size: 14px;
                  margin-top: 6px;
                }
            }
        }
    
        .wine-control-button {
          width: 24px;
          height: 24px;
          padding: 8px;
          // margin-left: 24px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background-color: transparent;
          outline: none !important;
        }

        @media (max-width: $media-xs) {
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 0px;

            .wine-like {
              position: absolute;
              top: 0;
              right: 0;
              padding: 0;


              img { width: 26px; }
            }

            .wine-image {
              width: 100px;
              height: 132px;
              margin: 0;
            }

            .wine-inventory {
              flex-direction: row;
              width: auto;
              padding: 12px 8px;

              input {
                width: 100px;
                height: 40px;
                font-size: 16px;
              }

              button { font-size: 16px; }
            }
        }
    }


    .wine-details {
        padding: 16px;
        display: flex;

        &__video {
            margin-left: 60px;
            height: 240px;
            // width: 240px;
            border-radius: 10px;
            overflow: hidden;

            .video-placeholder {
                position: relative;
                // background: $secondary-brand-color url('../../assets/images/coming-soon.jpg');
                background-position: center;
                background-size: cover;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                overflow: hidden;

                // &::before {
                //     content: "";
                //     background: linear-gradient(180deg, #424242 0%, rgba(66, 66, 66, 0) 100%);
                //     transform: rotate(-180deg);
                //     display: block;
                //     position: absolute;
                //     z-index: 10;
                //     height: 100%;
                //     width: 100%;
                //     top: 0;
                //     left: 0;
                // }

                &.placeholder_bottle-image {
                  width: auto;
                  img {
                    width: auto;
                  }
                }

                &__alert {
                    position: absolute;
                    height: 32px;
                    margin: 0;
                    background-color: rgba($primary-brand-color, 0.6);
                    top: 50%;
                    left: 0;
                    width: 100%;
                    transform: translateY(-50%);
                    font-weight: 800;
                    color: $white;
                    text-align: center;
                    line-height: 32px;
                    z-index: 50;
                }

                &__wine-name {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    color: $white;
                    margin: 0;
                    padding: 16px 24px;
                    font-weight: 800;
                    font-size: 14px;
                    line-height: 16px;
                    z-index: 15;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            @media (max-width: $media-sm) {
                margin: 0;
            }
        }

        &__recomend {
            padding: 0 64px;
            width: 360px;

            .recomendations-list {
                padding: 16px 0;

                &__item {
                    font-weight: 800;
                    padding: 12px 0;
                    display: flex;
                    align-items: center;

                    img { 
                        width: 20px;
                        height: 22px;
                        margin-right: 18px;
                    }
                }
            }

            @media (max-width: $media-sm) {
                padding: 0 32px;
                width: 300px;
            }
        }

        &__info {
            padding: 12px 0;
            flex: 50% 1 1;
            display: flex;
            flex-direction: column;

            .tastes-list {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 16px;

                .taste-item {
                    text-transform: uppercase;
                    color: $tertiary;
                    font-size: $caption;
                    font-weight: 700;
                }

                .taste-item + .taste-item::before {
                    content: "\2022";
                    padding: 10px;
                }
            }

            .wine-details__description {
                margin: 0;
            }

            //TODO: remove after "your wines" details will be changed
            &.short_info {
                padding: 0 0 0 64px;

                .recomendations-list__item {
                    margin-bottom: 24px;
                    display: flex;
                    align-items: center;

                    &.grape_red { color: $grape-red-color; }

                    &.grape_white { color: $icon-color; }

                    &.grape_sparkling { color: $disabled-color; }

                    img { margin-right: 16px; }
                }

                @media (max-width: $media-xs) {
                    padding-left: 0;
                }
            }
        }
        

        @media (max-width: $media-sm) {
            padding-left: 0;
            padding-right: 0;
        }

        @media (max-width: $media-xs) {
            flex-direction: column;

            &__video {
                width: 100%;
                height: 240p;
            }

            &__recomend {
                padding: 0;
            }
        }
    }

    &.custom-case {

      .account-wine__row {

        .wine-like {
          cursor: none;
        }

        @media (max-width: $media-md) {

          .wine-info {

            .wine-producer { width: 100px; }
          }
        }
      }
    }

    & + & { margin-top: 24px; }

    @media (max-width: $media-xs) {
        padding: 16px;
    }
}



.admin-wines-sales-headers {
    // background-color: $primary-brand-color;
    @include flexLayout(center, space-between);
    padding: 6px 186px 6px 16px;
    background-color: rgba($disabled-color, .1);
    gap: 16px;
    font-weight: 700;
    margin-bottom: 24px;

    .id, .date, .vintage, .name, .sold, .cost, .total, .producer {
        font-size: 120%;
        width: 80px;
        padding-top: 8px;
        padding-bottom: 8px;
        text-transform: capitalize;
    }

    .id {
        width: 40px;
    }

    .name {
        flex-grow: 1;
    }
}

.sales-report-card.report-card .card__body .report-data {
    padding: 3px 16px;
    gap: 16px;

    .id {
        width: 40px;
        padding: 0;
    }

    .date, .vintage, .sold, .cost, .total, .producer {
        width: 80px;
        padding: 0;
    }

    .name {
        flex-grow: 1;
        padding: 0;
    }

    .button-link {
        padding: 0;
        width: 120px;
    }
}

.wine-feedback {
    height: 72px;
    background-color: rgba($disabled-color, .1);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    transition: height 0.4s ease-out;
    margin: 0 32px;
    padding: 0 16px;
    transform: translateY(-16px);

    p { padding: 0 16px; }

    button {
        width: 80px;
        height: 36px;

        img { margin-right: 6px; }

        &.active {
            background-color: $primary-brand-color !important;
            color: $white;

            img { filter: brightness(10) }
        }
    }

    button + button { margin-left: 8px; }

    &.hidden {
        height: 0;
    }

    @media (max-width: $media-sm) {

        p {
            display: inline-flex;
            flex-direction: column;            
        }
    }

    @media (max-width: $media-xs) {
        margin-left: 8px;
        margin-right: 8px;
        height: auto;
        padding: 0 16px 16px;

        button {
            width: 80px;            
        }

        p {
            display: inline-block;
            text-align: center;
        }

        &.hidden {
            padding: 0;
        }
    }
}

.admin-table-header {
    background-color: $primary-brand-color;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 8px;
    padding: 8px 16px;
    height: 75px;
    display: flex;
    align-items: center;
    color: $white;

    &.producers-header {
      padding: 8px 24px;
    }

    button {
        background-color: transparent;
        color: $white;
        text-align: left;
        font-weight: 600;
    }

    .wine-select {
        margin-right: 16px;

        .checkbox-wrapper {
            margin: 0;
            display: initial;
            
            .checkmark {
                border-color: $white;

                &::after {
                    border-color: $white;
                }
            }
        }
    }

    .wine-image {
        width: 75px;
    }

    .wine-id {
        padding: 0 8px;
        width: 36px;
    }

    .wine-vintage {
        padding: 0 8px;
        width: 80px;
    }

    .wine-producer {
        width: 150px;
        padding: 0 8px;
    }

    .wine-name {
        padding: 0 24px;
        flex-basis: 250px;
        flex-grow: 1;
    }

    .wine-price, .wine-cost {
        flex-basis: 80px;
        padding: 0 8px;
        text-align: center;
    }

    .wine-stock {
      flex-basis: 120px;
      justify-content: flex-end;
    }

    .wine-region {
        flex-basis: 120px;
        padding: 0 8px;
    }

    .wine-tools {
        flex-basis: 80px;
        text-align: center;
    }
}

.region-preview {
    width: 445px;
    border-radius: 10px;
    position: relative;
    overflow: visible;

    .card__body {
        padding: 0;
    }

    &__image {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 180px;

        img {
            height: 100%;
            // width: auto;
        }
    }

    &__content {
        position: relative;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 12px 24px;
        background-color: $white;

        p { 
            font-size: 18px;
            margin: 0;
            line-height: 1;
            color: $icon-color;
        }

        h3 { 
            font-size: 32px;
            margin: 0;
            line-height: 1.5;
            padding: 0;
        }

        button {
            width: 135px !important;
            position: absolute;
            bottom: 50%;
            right: 32px;
            transform: translateY(50%);
        }
    }

    .new-item-badge {
        position: absolute;
        bottom: 12px;
        right: 16px;
        transform: translateY(100%);
    }

    @media (max-width: $media-xs) {
        width: 90%;
    }
}



.report-card {
    margin-bottom: 16px;

    .card__body {
        padding: 0;
        transition: height .6s ease-in-out;

        .report-data {
            display: flex;
            align-items: center;
            padding: 6px 32px;
            font-weight: 600;
            font-size: 20px;
            background-color: rgba($bg-card-color, .15);

            .id, .name, .info { padding: 6px 12px; }

            .warning { color: $error-color; font-weight: 600; }

            button { 
                margin: 0 16px;
                font-size: 14px;

                img { 
                    height: 7px;
                    margin-left: 6px;
                }
            }

            .id { width: 46px; font-size: 18px; }
            .name { flex-grow: 1; }
            .info { min-width: 176px; font-weight: 400; }
        }

        .report-content {
            padding: 16px 32px;
        }
    }
}

.preference-wine, .preference-wines-header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 64px 0 0;

    .date, .name, .producer, .stats, .recomend {
        padding: 4px 16px;
    }

    .name {
        flex-grow: 1;
    }

    .recomend, .stats, .date {
        width: 90px;
        text-align: center;
    }

    .producer {
        width: 160px;
    }
}

.preference-wine {

    img {
        position: absolute;
        right: 16px;
        height: 16px;
    }

    &.red {

        .recomend { 
            color: $error-color;
        }
    }
    &.green {

        .recomend { 
            color: rgba($primary-brand-color, .7);
        }
    }
    &.gray {

        .recomend { 
            color: rgba($disabled-color, .7);
        }
    }
}

.preference-wines-header {
    background-color: rgba($disabled-color, .15);
    font-weight: bold;
    padding: 8px 64px 8px 0;

    .title {
        width: 100%;
        text-align: center;
        padding: 0 16px;
        color: $text-base-color;
        font-size: 20px;
        font-weight: 600;
    }
}


.region-card {
    @include flexLayout(flex-start, flex-start, column);
    text-align: left;
    cursor: pointer;
    background-color: white;
    padding: 0;
    line-height: 1.5;
    height: 478px;
    width: 100%;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &__body { padding: 0; }

    &__image {
        position: relative;
        overflow: hidden;
        height: 140px;

        img {
            width: 100%;
        }

        @media (max-width: $media-md) {

            img {
                height: 100%;
            }
        }
    }

    &__title {
        position: relative;
        margin-bottom: 8px;

        i.country {
            font-size: $subtitle;
            color: $tertiary;
            margin-top: 4px;
            width: 100%;
            display: inline-block;
        }

        h3 {
            line-height: 1;
            margin: 0;
            padding: 0;
            display: inline-flex;
            align-items: center;
        }
    }

    &__description {

        @media (max-width: $media-xs) {

            .float-img {
                height: 220px;
                padding: 26px 0px;
            }
        }
    }

    &__label {
        margin-left: 16px;

        .new-item-badge {
            width: auto;
            min-width: auto;
            padding: 4px 8px;
            font-size: 14px;
            line-height: 1.2;
            border-radius: 20px;
        }

        @media (max-width: $media-xs) {
            margin-left: 8px;

            .new-item-badge { padding: 2px 8px; }
        }
    }

    &__content {
        padding: 24px 32px;

        @media (max-width: $media-xs) {
            padding: 24px 16px;
        }
    }

    &__footer {
      margin-top: 12px;
        padding: 0 32px;
        width: 100%;

        .region-card__cases-stat {
            width: 60%;
            color: $secondary;
            display: flex;
            align-items: center;

            img { 
                margin-right: 8px;
                height: 24px;
            }
        }

        button { 
            width: 60% !important;
            margin-left: 16px;
            margin-top: 0 !important;
        }

        @media (max-width: $media-xs) {
            padding: 0 16px 24px;
            flex-direction: column;

            .region-card__cases-stat {
                margin-bottom: 12px;
                justify-content: center;
            }

            button {
                margin-left: 0;
                width: 100% !important;
            }
        }
    }

    @media (max-width: $media-xs) {
        width: 100%;
        height: auto;
    }
}

.custom-case__count-bar {
  // height: 90px;
  height: 60px;
  position: relative;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $alabaster;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  margin: 0 16px;
  overflow: hidden;

  .out-of-stock-alert { 
    background-color: rgba($disabled-color, .4);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .count-input.custom-case-count {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;

    label {
      padding: 0;
    }

    .input-group {
      padding: 0;

      input {
        color: $primary;
        width: 64px;
  
        &::placeholder {
          font-weight: 800;
          font-size: 24px;
          color: $text-base-color;
        }
      }
    }
  }
}

.del-button {
    position: absolute;
    height: 32px;
    width: 32px;
    background-color: $white;
    padding: 10px;
    top: 16px;
    left: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
        opacity: .7;
    }

    img { 
        transform: rotate(45deg);
        width: 100%;
        
        &.spinner {
            height: 20px;
            width: 20px;
        }
    }
}

.custom-case-wine__control-btn {
    width: 150px !important;
    flex-shrink: 0;

    img {
        width: 20px !important;
        height: 20px !important;
        margin-right: 12px;
    }
}

.fav-label {
    position: absolute;
    width: 150px;
    height: 36px;
    right: 16px;
    top: 0;
    transform: translateY(-60%);

    img, svg {
        filter: brightness(10);
        margin-right: 4px;
        height: 16px;
    }
}

.modal-dialog-lg {

    .stat-caption {
        font-family: $quincy;
        padding: 0 32px;
        font-size: 32px;
        line-height: 1;
        font-weight: 600;
        margin-bottom: 16px;
        border-bottom: 1px solid;
        color: $text-base-color;
    }

    .orders-statistics {
        height: auto;
        max-height: 500px;
        overflow-y: auto;
    }
}

.wine-row-label-wrapper {
    width: 150px;
    flex-shrink: 0;
    // text-align: right;

    .label {
      @include flexLayout(center, center);
      gap: 10px;
      background-color: $warm-gray-6 !important;
      color: $white !important;
      height: 40px;

      @media (max-width: $media-sm) {
        right: 8px;
        left: auto !important;
      }
    }
}

.low-stock {
    // background-color: rgba(red, .1);
    .wine-stock, .wine-name, .wine-name a {
      font-weight: bold;
      color: #FF9700;
    }
}

.out-of-stock {
    // background-color: rgba(red, .3);
    .wine-stock, .wine-name, .wine-name a {
      font-weight: bold;
      color: #C92C53;
    }
}

.recommendation-preview {
    position: relative;
    width: 100%;
    max-width: 475px;
    height: 440px;
    background-color: $white;
    border: none;
    font-size: 16px;
    text-align: left;
    
    .card__body {
        padding: 0;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
    }


    &__image {
        position: relative;
        overflow: visible;
        height: 125px;
        margin-bottom: 24px;

        img {
            height: 100%;
        }

        .fav-badge {
            position: absolute;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            bottom: 0;
            right: 32px;
            transform: translateY(50%);
            z-index: 20;
            background-color: $white;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
            @include flexLayout(center, center);

            img {
                height: 28px;
                width: 40px;
                object-fit: none;
                object-position: unset;
            }
        }

        @media (max-width: $media-xs) {
            height: 75px;
        }
    }

    &__content {
        flex-grow: 1;
        padding: 0 32px;

        &-header {
            display: flex;
            flex-direction: column;
            font-size: 16px;

            .subtitle {
                text-transform: uppercase;
                color: $icon-color;
                letter-spacing: 0.1em;
                margin: 0;
            }

            .title {
                font-size: 26px;
                line-height: 1.2;
                font-weight: bold;
                color: $text-base-color;
                font-family: QuincyCF;
                margin-bottom: 0;
                margin-top: 4px;
                padding: 0;
            }

            .wines-types-list {
                margin-top: 8px;
                margin-bottom: 16px;
            }
        }
    }

    &__footer {
        padding: 32px;

        .button { margin-top: 0; }
    }

    @media (max-width: $media-md) {
        position: absolute;
        bottom: 0;
        right: 50%;
        transform: translate(50%, 110%);
    }

    @media (max-width: $media-xs) {
        height: auto;
        width: 95%;
        margin: auto;
        transform: translate(50%, 100%);
    }
}

.admin-grape {

    &-row { 
        padding: 16px;
        border-bottom: 1px solid rgba(grey, .1);
        @include flexLayout(center, flex-start);
        gap: 16px;

        @media (max-width: $media-xs) {
          flex-direction: column;
        }
    }

    &__img-container {
      position: relative;
      width: 120px;
      height: 80px;
      @include flexLayout(center, center);

      img { 
          max-width: 100%;
          border-radius: 10px; 
      }

      @media (max-width: $media-xs) {
        width: auto;
        height: 120px;

        img {
          max-height: 100%;
          max-width: none;
        }
      }
    }

    &__info {
      @include flexLayout(flex-start, flex-start, column);
      align-self: stretch;
      width: 50%;
      flex-grow: 1;

      @media (max-width: $media-xs) {
        width: 100%;

        .flex-space-between { 
          width: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &__name {
        padding: 6px 0;
        font-size: 18px;
        font-weight: 800;
        display: flex;
        align-items: center;
    }

    &__tastes {
        padding: 0;
        @include flexLayout(flex-start, flex-start);
        gap: 24px;
        font-size: 12px;
        margin-left: 16px;

        li {
            border: 1px solid;
            padding: 2px 4px;
        }

        li + li {
            list-style: disc;
        }
    }

    &__description {
        margin-top: 8px;
        font-size: 14px;
    }

    &__region {
        margin-top: -8px;
        font-size: 14px;
        color: $disabled-color;
        font-weight: 600;

        @media (max-width: $media-xs) {
          margin-top: 8px;
          font-size: 16px;
          width: 100%;
          text-align: center;
        }
    }

    &__tools {
        @include flexLayout(center, center, column);
        width: 120px;
    }
}

.admin-region {

    &-row {
        @include flexLayout(center, flex-start);
        gap: 16px;
        padding: 8px 16px;
    
        &.parent-region {
            background-color: rgba($primary-brand-color, .1);
            font-weight: 600;
        }
    }

    &-headers {
        @include flexLayout(center, flex-start);
        gap: 16px;
        padding: 16px;
        background-color: rgba($disabled-color, .2);
        margin-bottom: 16px;
        font-weight: bold;
    }

    &-id {
        text-align: center;
        width: 24px;
    }

    &-name {
        width: 200px;
        flex-grow: 1;
    }

    &-country, &-parent-region, &-curator {
        width: 120px;
    }
}


.player-wrapper {
  position: relative;
  height: 100%;

  .player-spinner {
    position: absolute;
    z-index: -1;
    bottom: 50%;
    right: 50%;
    transform: translate(50%, 0);
  }
}

.waitlist-info-card {
  @include flexLayout(center, center, column);
  @include boxShadow(rgba(0, 0, 0, 0.15), 25px);
  padding: 27px 20px;
  text-align: center;
  gap: 18px;
  color: $text-base-color;
  border-radius: 10px;

  &__icon {
    @include flexLayout(center, center);
    width: 128px;
    height: 128px;
    border-radius: 50%;
    background-color: $primary-brand-color;
    align-self: center;
  }

  &__title {
    font-size: 24px;
    color: $text-base-color;
    font-weight: 800;
    line-height: 1;
    padding: 0;
    margin: 0;
  }

  &__text {
    font-size: 18px;
    line-height: 1.5;
    padding: 0;
    margin: 0;
  }
}

.wine-popover {
  width: 320px;
  position: relative;
  padding: 0 !important;

  .wine_admin-data {

    .card__header {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      position: relative;
      height: 220px;
  
      .badge {
        width: 40px;
        height: 40px;
        font-weight: 700;
        border-radius: 50%;
        position: absolute;
        top: 16px;
        right: 16px;
        background-color: #4e725e;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  
      img { height: 100%; }
  
      .wine__name {
        position: absolute;
        font-size: 14px;
        font-weight: 700;
        bottom: 0;
        left: 0;
        padding: 16px;
        width: 100%;
        color: #fff;
        margin: 0;
        text-shadow: 1px -1px 5px #000;
        height: 100%;
        display: flex;
        align-items: flex-end;
        background-image: linear-gradient(rgba(245,246,252,0),rgba(0,0,0,.34901960784313724) 85%,rgba(0,0,0,.7019607843137254));
      }
    }
  }

  .popover-arrow {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.wine-stock-with-img {
  @include flexLayout(center, space-between, column);
  gap: 8px;
}

.stock-badge {
  @include flexLayout(center, space-between);
  gap: 4px;
  font-weight: 600;
  font-size: 16px;

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    border-radius: 50%;

    &.red { background-color: #782A2A; }
    &.orange { background-color: #FF9700; }
    &.blue { background-color: #0090DB; }
    &.green { background-color: #7AB800; }
    &.white { background-color: $white-wine; }
    &.rose { background-color: $rose-wine; }
    &.sparkling { background-color: $sparkling-wine; }
  }
}

.order-price-details {
  display: table;
  width: 100%;
  border-collapse: collapse;

  .price-details {
    &__row {
      // @include flexLayout(center, space-between);
      // gap: 16px;
      display: table-row;
      width: 100%;
      color: $warm-gray-9;
      text-align: right;

      &.divider {
        height: 1px;
        width: 100%;
        margin: 8px 0;
        border-top: 1px solid rgba($icon-color, 0.4);

        &::before {
          content: "";
          display: table-row;
          height: 10px;
        }
      }
  
      &.total-price { color: $primary; }

      &:nth-child(2) {
        height: 32px;
      }
    }

    &__value {
      display: table-cell;
    }

    &__label {
      display: table-cell;
      width: 100%;
    }
  }
}

.wine-case-stat-list {
  padding: 16px;

  .title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 8px;
  }

  .wine-case-stat-item {
    padding: 4px 0;
    display: flex;
    align-items: center;
    opacity: .6;
    width: 100%;
  
    .dots {
      flex-grow: 1;
      padding: 0 4px;
    }

    &.active {
      font-weight: 600;
      opacity: 1;
    }
  }
}

.post-card {
  position: relative;
  height: 460px;
  border: 2px solid $alabaster;
  border-radius: 8px;
  overflow: hidden;
  
  &__header {
    position: relative;
    width: 100%;
    height: 220px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
      max-width: 100%;
    }

  }

  &__body {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: calc(100% - 220px);

    .short-desc-wrapper { 
      margin: 0;
      color: $secondary;
      flex-grow: 1;
    }

    h5 {
      margin: 0;
      padding: 0;
      line-height: 1;
      font-size: 22px;
      flex-shrink: 0;
    }

    .button { 
      width: fit-content;
      flex-shrink: 0;
    }
  }
}

.facebook-wine {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img { width: 100% }

  .region {
    width: auto;
    align-self: flex-end;
    margin-top: 12px;
    margin-right: 18px;
    padding: 6px 12px;
    background-color: white;
    gap: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }

  .tastes {
    justify-self: flex-end;
    padding: 4px 8px;
    background-color: white;
    display: flex;
    gap: 75px;

    li {
      display: list-item;
      list-style: disc;
    }
  }

}

.admin-mobile-wine-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px;
  gap: 6px 16px;
  margin-top: 8px;

  .checkbox-wrapper { display: initial; }

  .wine-select { margin-right: 16px; }

  .wine-image {
    height: 100%;

    img {
      max-height: 100%;
      border-radius: 10px;
    }
  }

  .wine-id {
    font-weight: 600;
    min-width: 36px;
    padding: 0 !important;
    background-color: transparent;
  }

  .wine-producer {
    // flex-basis: 150px;
    min-width: 150px;
  }

  .wine-name {
    font-weight: bold;
    flex-basis: 250px;
    flex-grow: 1;
  }

  .wine-vintage {
    // flex-basis: 80px;
    min-width: 50px;
    text-align: center;
  }

  .wine-stock, .wine-cost, .wine-price {
    // flex-basis: 80px;
    min-width: 80px;
    text-align: center;
  }

  .wine-stock {
    position: relative;
  }

  .wine-region {
    // flex-basis: 120px;
    min-width: 120px;
  }

  .wine-control {
    // flex-basis: 80px;
    min-width: 75px;
    text-align: center;

    button {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  & + & {
    border-top: 1px solid rgba($primary-brand-color, 0.15);
  }
}

.case-slider-card {
  height: 485px;
  position: relative;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  &.compact {
    height: auto;

    .case-slider-card__body {
      height: auto;
    }
  }

  &__price {
    position: absolute;
    width: 80px;
    height: 36px;
    @include flexLayout(center, center);
    border-radius: 0px 4px 4px 0px;
    background-color: #BCAB75;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: white;
    top: 0;
    left: 0;
    transform: translate(-2px, 16px);
    z-index: 10;
  }

  &__body {
    padding: 16px;
    display: flex;
    flex-direction: column;
    height: 277px;
    gap: 16px;

    .wines-types-list {
      color: $tertiary;
      font-weight: 400;
      justify-content: center;
      margin: 0;

      @media (max-width: $media-xs) {
        font-size: 12px;
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60px;
  }

  &__image {
    height: 205px;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      object-fit: cover;
      height: auto;
    }
  }

  &__name {
    font-size: $h5;
    margin: 0;
    padding: 0;
    text-align: center;

    @media (max-width: $media-xs) {
      font-size: $h5-mobile;
    }
  }

  &__description {
    font-size: 14px;
    flex-grow: 1;
    margin: 0;
    // max-height: 80px;
    max-height: 85px;
    overflow: hidden;

    &-text {
      font-size: 14px;
    }
  }

  &__control {
    @include flexLayout(center, center);
    gap: 16px;

    .button { 
      font-size: 14px;
      width: 50% !important;
      border-radius: 4px !important;
      margin-top: 0 !important;
      padding-left: 16px;
      padding-right: 16px;
      height: 48px;
    }
  }

  &__souldout-label {
    color: $tertiary;

    @media (max-width: $media-xs) {
      font-size: 14px;
    }
  }
}

.order-dynamic-case-image {
  min-width: none;
  min-height: none;
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  // align-items: flex-end;

  img {
    min-width: auto !important;
    min-height: auto !important;
    // width: auto !important;
    // height: 100%;
  }

  .case-bottles {
    height: 160px;
    
    &.chess-pattern {
      height: 140px;
    }
  }
}

.admin-case-row .category {
  display: flex;
  flex-shrink: 0;
  align-items: center;
}

.admin-case-row__customers {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: white;
  width: 500px;
  max-width: 90%;
  border-radius: 8px;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  h5 {
    margin-top: 0;
    margin-bottom: 12px;
    width: 100%;
    text-align: center;
    font-size: 20px;
  }
}

.case-data {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  padding: 8px 16px;
  @include flexLayout(center, space-between, column);

  &:not(:last-child) {
    border-bottom: 1px solid rgba($primary-brand-color, .4);
  }

  &__row {
    width: 100%;
    @include flexLayout(center, space-between);
  }

  .case-main-info {
    flex-shrink: 0;
    font-size: 16px;
    padding-right: 40px;
    width: 265px;
    text-align: right;
    font-weight: 400;
  }

  &__wines-summary {
    color: $secondary;
    font-size: 16px;
    @include flexLayout(center, flex-end);
    flex-grow: 1;
    gap: 8px;
    padding-right: 16px;
    font-weight: 400;

    .wines-types-list {
      color: $tertiary;
      margin: 0;
      width: auto;
      font-weight: 400;
    }
  }

  img {
    height: 24px;
    filter: brightness(0);
  }

  &__regions-list {
    @include flexLayout(center, flex-start);
    width: 100%;
    gap: 8px;
    padding: 0 16px;
    margin: 0;

    .case-data__region {
      font-weight: 400;
      font-size: 14px;
      padding: 4px 12px;
      border-radius: 8px;
      background-color: $alabaster;
    }

    @media (max-width: $media-sm) {
      flex-wrap: wrap;
    }
  }
}

.case-tile-dnd-wrapper {
  border: 1px solid $vineify-green;
  border-radius: 6px;
  background-color: whitesmoke;
  cursor: move;

  &.out-of-stock {
    background-color: rgba($warning, .3);
  }
}

.order-inc-buttons {
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  .button-link {
    padding: 0;
    height: auto;

    &:hover {
      opacity: .6;
    }
  }
}

.motivation-card {
  width: 100%;
  border-radius: 20px;
  height: 260px;
  cursor: pointer;

  &__content {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .75) 85%, rgba(0, 0, 0, 100) 100%);
    padding: 24px 48px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: white;
    position: absolute;
    bottom: 0;
    z-index: 5;

    @media (max-width: $media-sm) {
      padding: 24px;
    }

    @media (max-width: $media-xs) {
      padding: 16px 24px;
    }
  }

  &__title {
    font-size: 36px;
    line-height: 1.2;
    font-family: $avenir;
    color: white;
    margin: 0;
    padding: 0;
    font-weight: 700;

    @media (max-width: $media-sm) {
      font-size: 24px;
    }

    @media (max-width: $media-xs) {
      font-size: 20px;
    }
  }

  &__text {
    margin: 0;

    @media (max-width: $media-sm) {
      font-size: 12px;
    }
  }

  &__link {
    display: flex !important;
    margin: 12px 0 0 !important;
    align-items: center;
    gap: 12px;
    font-weight: 600;
    color: white;

    @media (max-width: $media-sm) {
      font-size: 14px;
    }
  }

  &__background {
    position: absolute;
    height: 100%;
  }

  @media (max-width: $media-sm) {
    height: 200px;
  }
}

.status-badge.stock-badge {
  position: absolute;
  right: 0px;
  background-color: transparent;
  font-size: 8px;
  padding: 4px;
  width: 50px;
  text-transform: uppercase;
  border-radius: 20px;
  bottom: 50%;
  transform: translateY(50%);

  &.low {
    color: #FF9700;
    border: 1px solid;
    
  }

  &.out {
    color: white;
    background-color: #C92C53;
  }

  @media (max-width: $media-xs) {
    right: -52px;
  }
}
