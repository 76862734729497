@import '../../theme/variables';


.popover-notification {
    padding: 16px 32px;
    background-color: $white;
    border-radius: 10px;
    width: 250px;
}

.form-control__tabs {
    margin: 0;
    padding: 0 8px;

    .tag {
        padding-right: 0;

        &-button {
            padding: 3px 6px !important;
            width: 40px;
        }
    }
}

.search-bar-wrapper {
  @include flexLayout(flex-start, space-between);
  gap: 32px;

  .search-bar {
    position: relative;
    width: 100%;
    flex-grow: 1;
    gap: 16px;

    .input-group {
      flex-grow: 1;
      display: flex;

      .select-wrapper {
        width: 185px;

        [class$="-control"] {
          box-shadow: none !important;
        }

        [class$="-menu"] {
          min-width: 200px !important;
        }
      }

      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        z-index: 2;
        margin-left: -12px;
      }
    }

    label { flex-shrink: 0; }

    input { 
        width: 100%;
        border-color: rgba($bg-card-color, .4) !important;
    }

    .search-button {
        position: absolute;
        right: 16px;
        z-index: 5;
        background-color: transparent;

        img { height: 18px; }
    }
  }

  .button {
    width: 120px;
    flex-shrink: 0;
    margin: 0
  }
}

.input-records-group {
  @include flexLayout(flex-start, flex-start, column);
  gap: 12px;
  padding: 32px 0;

  &__records-input {
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;

    input { flex-grow: 1; }

    @media (max-width: $media-xs) {
      .button { 
        width: auto !important;
        min-width: auto;
      }
    }
  }

  &__error {
    position: absolute;
    margin: 0;
    left: 0;
    top: 40px;
    font-size: 14px;
    color: $warning;
  }

  .records-list {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
    padding: 32px;
    background-color: rgba($disabled-color, .05);
    width: 100%;
    border-radius: 10px;

    .record {
      padding: 4px 8px;
      font-size: 12px;
      border: 1px solid $disabled-color;
      border-radius: 4px;
    }

    .message {
      margin: 0;
      padding: 0;
      text-align: center;
      width: 100%;
    }

    @media (max-width: $media-xs) {
      padding: 16px;
    }
  }

  .form-control {
    width: 100%;

    &.invalid {
      & + .records-list {
        border: 1px solid $warning;

        .message {
          color: $warning;
        }
      }
    }
  }
}

// flexDirection: 'column', justifyContent: 'space-around', flexGrow: 1, width: '100%'

.form-control.radio-horizontal {
  justify-content: space-between;
  flex-grow: 1;
  padding: 16px 0;
  width: 100%;

  .flex-space-between {
    gap: 32px;
    align-items: flex-end;

    .radio-button {
      width: 225px;
    }
  }
}

.crop-container {
  @include flexLayout(center, flex-start, column);
  position: relative;
  padding: 8px 0;
  margin-top: 32px;
  width: calc(50% - 16px);
  height: 450px;
}

.crop-image__buttons {
  width: 100%;
  @include flexLayout(center, space-around);
  gap: 32px;
  margin-top: 32px;
  flex-grow: 1;
}

.crop-image__title {
  font-weight: 600;
  font-size: 32px;
  text-decoration: underline;
}

.crop-tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
}

.crop-image {

  &__layout {
    padding: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__input {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    img {
      height: 16px;
      margin-left: 6px;
    }
  }
}

.textarea-limit {
  padding: 0 24px;
  margin-bottom: 6px;
  flex-grow: 1;
  text-align: right;

  &.max-length {
    color: $warning;
  }
}

.over-limit-message {
  color: $warning;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  text-align: right;
}

.share-buttons__list {
  padding: 16px 0;
  display: flex;
  align-items: center;
  gap: 12px;

  .button.share-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 125px;
    padding: 0;
    gap: 8px;
    height: 35px;
    font-size: 14px;
    text-decoration: none;
    font-weight: 400;
    

    &.facebook {
      // background-color: #41416e;
      background-color: #385898;
    }

    &.twitter {
      background-color: #55acee;
    }
  }
}
