@import '../../theme/variables';


.email-preview {
  font-family: 'Avenir';
  font-size: 16px;
  line-height: 1.5;

  header { 
    background-color: transparent;
    position: relative;
  }

  hr { 
    border: 1px solid white;
    border-bottom: 1px solid #F4F2EA;
    color: white;
    display: block;
    margin: 0;
    height: 15px;
}

.content {
    padding: 0;
}

p, a {
    margin: 0;
    color: #484848;
}

.vertical-space {
    content: "";
    display: block;
    margin-bottom: 24px;
}

.case-link-button {
  margin: auto;
  text-decoration: none;
  background-color: #004d2e;
  border-radius: 8px;
  box-sizing: border-box;
  color: white !important;
  font-weight: 500;
  height: 40px;
  line-height: 1.5;
  outline: none;
  padding: 8px 32px;
  position: relative;
  text-align: center;
  transition: box-shadow .4s ease-in-out;
}

.header-content {
  display: table;
  width: 100%;
  margin-bottom: 32px;
  background-color: #FAF9F5;

  &__case {
    padding: 16px;

    .header-content__image {
      padding: 0;
    }
  }

  &__case-categories {
    padding: 6px 0;

    .case-category {
      display: inline-block;
      width: auto;
    }

    .case-category + .case-category::before {
      content: "\2022";
      padding: 0 4px;
    }
  }

  &__curator {
    display: table;
    min-width: 320px;
    width: 320px;
  }
  
  &__curator-photo {
      display: table-cell;
      width: 100px;

      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
  }
  
  &__curator-text {
      padding: 8px 16px;
      display: table-cell;
      width: 100%;
      color: grey;
      vertical-align: middle;

      .name {
        font-weight: 600;
        color: #004d2e;
    }
  }
}

.header-content__text {
    display: table-cell;
    width: 320px;
    padding: 0 64px;
    background-color: #FAF9F5;
    vertical-align: middle;
}

.header-content__image {
    display: table-cell;
    width: 100%;
    padding-left: 24px;
    vertical-align: middle;
}

.header-content__text hr {
    color: black;
    border-color: #FAF9F5;
    border-top-color: #A2905A;
    width: 40%;
}

.header-content__text h2 {
    font-size: 36px;
    line-height: 1.2;
    color: #004D2E;
}

.header-content__text p {
    font-size: 20px;
    font-weight: bold;
}

footer {
    color: #787878;
    font-size: 80%;
    padding: 16px 24px;
    margin-top: 64px;
    border-top: 1px solid;
}

footer p {
    margin-bottom: 8px;  
    text-align: center;
}

.recommendation-image {
        background-image: url('http://cdn.mcauto-images-production.sendgrid.net/501be10801dfadfb/2358a868-b852-43eb-bcbf-d6be2e0ec1d2/1659x755.jpg');
        background-position: 0 0;
        background-size: cover;
        width: 100%;
        height: 375px;
        margin-bottom: 32px;
}

.visible-xs {
    display: none !important;
}

@media (max-width: 1024px) {
    .content { padding: 24px 10px }
    .order-info {
        margin: 0;
    }
}

@media (max-width: 520px) {
    .recommendation-image {
        background-image: url('http://cdn.mcauto-images-production.sendgrid.net/501be10801dfadfb/c530c098-1d40-4650-98b2-47804e97350f/622x608.jpg');
        height: 210px;
    }
    
    .hidden-xs {
        display: none !important;
    }
    
    .visible-xs {
        display: initial !important;
    }
    
    .header-content {
        display: block;
        position: relative;
    }
    
    .header-content__text {
        display: block;
        width: 100%;
        padding: 24px;
        float: right;
        box-sizing: border-box;
        margin-bottom: 16px;
    }
    
    .header-content__image {
        float: left;
        display: block;
        padding: 0;
        margin-bottom: 16px;
    }
    
    .header-content__image img {
        max-width: 100%;
        height: auto;
    }
    
    .header-content__text hr {
        width: 100%;
    }
    
    .header-content__text h2 {
        font-size: 24px;
    }
    
    .header-content__text p {
        font-size: 16px;    
    }
}
}

.recommendation-preview-dialog {
  max-width: 1080px;
  width: 90vw !important;
}
