@import '../../theme/variables';

.accordion {
  width: 100%;

  .accordion__item {
    padding: 32px 0px;
    width: 100%;
    position: relative;
    overflow: hidden;

    .accordion__header {            
      font-family: $quincy;
      font-size: 24px;
      line-height: 1;
      display: flex;
      z-index: 50;
      align-items: center;
      background-color: $white;
    }

    .accordion__body {
      color: $disabled-color;
      padding-top: 24px;
      padding-left: 50px;
      display: none;
      transform: translateY(-100%);
      transition-property: transform;
      transition-duration: .5s;
      transition-timing-function: ease-out;


      &.collapsed {
        display: block;
        transform: translateY(0%);
      }
    }
  }

  &__toggle-button {
      
    button {
      @include flexLayout(center, flex-start);
      gap: 24px;
      background-color: transparent;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none !important;
      outline: none;
    }
  }

  .accordion__item + .accordion__item { border-top: 1px solid #E0E0E0 }

  @media (max-width: $media-xs) {
    &__title { text-align: left; }
  }
}

.waitlist-faq {
  @include boxShadow($spread: 25px);
  padding: 30px 90px;
  border-radius: 10px;

  &__title {
    text-align: center;
    font-size: 32px;
    font-family: $quincy;
    font-weight: 700;
    color: $text-base-color;
  }

  &__list {
    padding: 48px 0;
  }

  &__row {
    padding: 32px 54px;
    color: $text-base-color;
    border-bottom: 1px solid $secondary-brand-color;
    border-top: 1px solid $secondary-brand-color;

    &-title {
      @include flexLayout(center, space-between);
      font-size: 18px;
      font-weight: 800;
      gap: 24px;

      @media (max-width: $media-xs) {
        gap: 16px;
      }
    }

    &-text {
      margin-top: 24px;
      white-space: pre-line;
    }

    &-button {
      @include flexLayout(center, center);
      width: 32px;
      height: 32px;
      background-color: transparent;
      border: 2px solid $primary-brand-color;
      border-radius: 50%;
      font-size: 24px;
      font-weight: 800;
      padding: 0;
      line-height: 1;
      flex-shrink: 0;
    }

    &.expanded {
      background-color: rgba($secondary-brand-color, .25);
    }

    @media (max-width: $media-md) {
      padding: 24px 32px;
    }

    @media (max-width: $media-xs) {
      padding: 16px;
    }
  }

  @media (max-width: $media-md) {
    padding: 30px 60px;
  }

  @media (max-width: $media-xs) {
    padding: 16px 32px;
  }
}
