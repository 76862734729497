@import '../../theme/variables';


.hero {
    background-color: $white;

    &__content {
        position: relative;
        padding: 30px;
        width: 100%;
        height: 100%;
        @include flexLayout(center, space-between);
        gap: 30px;

        @media (max-width: $media-xs) { flex-direction: column; }
    }

    &__image {
        width: 208px;
        position: relative;

        img {
            width: 100%;
        }

        @media (max-width: $media-xs) {
          display: block;
          width: auto;
          margin-top: -50px;
          align-self: flex-start;
          
          img { 
              height: 80px;
              width: auto;
          }
      }
    }

    &_recommendation, &_region-recommendatrion {
        z-index: 12;
        border-radius: 20px;
        position: relative;
        width: 100%;
        // height: 320px;
        max-width: 1168px;
        transform: translate(-50%, -50%);
        left: 50%;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

        .hero__content {
            height: 332px;

            @media (max-width: $media-xs) { 
              height: auto;
              gap: 8px;
            }
        }

        &__title {
            height: 80px;
            margin-top: 12px;
            margin-bottom: 16px;

            h3 {
                font-size: $h3;
                font-weight: 600;
                color: $primary;
                font-family: $quincy;
                line-height: 1;
                padding: 0;
            }

            @media (max-width: $media-sm) {
              margin-bottom: 0;
              
              h3 { font-size: $h3-mobile; }
            }

            @media (max-width: $media-xs) {
              height: auto;
              margin: 8px 0;
              
              h3 { font-size: 18px; }
          }
        }

        &__prefix {
            padding: 1px 14px;
            color: $white-wine-text;
            font-size: 14px;
            font-weight: 700;
            border: 2px solid $white-wine;
            border-radius: 1000px;
            text-transform: uppercase;
            width: auto;
            display: inline-block;

            @media (max-width: $media-xs) {
              position: absolute;
              right: 16px;
              top: 16px;
          }
        }

        &__wines-stats {
            display: inline-flex;
            font-weight: bold;

            &::before {
                content: url('../../assets/icons/black-box.svg');
                margin-right: 6px;
                margin-top: 1px;
            }

            .button-link { font-weight: bold; }
        }

        &__purchase {
            @include flexLayout(center, flex-start);
            flex-wrap: wrap;
            gap: 16px;
            margin-top: 24px;
            // font-size: 18px;

            .button { 
              // width: 185px;
              font-weight: bold;
            }

            .button-link {
              padding: 0;
              font-weight: bold;
            }
        }

        @media (max-width: $media-sm) {
            height: auto;
            transform: translate(-50%, -70%);
        }

        @media (max-width: $media-xs) {
            height: auto;
            transform: translate(-50%, -35%);
        }
    }

    &_recommendation {
        position: absolute;
        width: 94%;

        @media (max-width: $media-xs) {
          margin-top: -50px;
        }
    }

    &_region-recommendation {
        position: relative;
        box-shadow: 0px 0px 25px rgba(black, .1);
        border-radius: 20px;

        .hero__content { 
            height: 300px;
            gap: 35px;

            @media (max-width: $media-xs) { 
                height: auto;
                min-height: 208px;
                width: auto;
                flex-direction: column;
                padding: 16px;
                gap: 8px;
            }
        }

        .hero_recommendation__title {
            @media (max-width: $media-xs) {
                height: auto;
                margin: 8px 0;
                
                h3 { font-size: 18px; }
            }
        }

        .hero_recommendation__prefix {
            @media (max-width: $media-xs) {
                position: absolute;
                right: 16px;
                top: 16px;
            }
        }

        .hero__wines-gallery {

            .wines-gallery__item {
              @include flexLayout(center, center);
              overflow: hidden;
                padding: 4px 6px;
                height: 72px;
                text-align: center;

                img { 
                  object-fit: cover;
                  object-position: bottom;
                  width: 100%;
                  height: 100%;
                 }
            }
        }

        .hero_recommendation__purchase {
            font-size: 16px;

            // .button {
            //     width: 185px;
            // }

            @media (max-width: $media-xs) {
                margin: 16px 0;
            }
        }

        .hero__image {
          flex-shrink: 0;

            @media (max-width: $media-xs) {
                display: block;
                width: auto;
                margin-top: -50px;
                align-self: flex-start;
                
                img { 
                    height: 80px;
                    width: auto;
                }
            }
        }

        .hero_recommendation__wines-stats {
          & + .button-link { font-weight: bold; }

            @media (max-width: $media-xs) {
                width: 100%;
                font-size: 14px;
            }
        }

        .hero__case-data {
            @media (max-width: $media-xs) {
                width: 100%;
            }
        }
        .hero__case-wines {
            @media (max-width: $media-xs) {
                width: auto;
                margin-top: 32px;
            }
        }

        .button-link {
            @media (max-width: $media-xs) { 
                padding: 0;
                font-size: 14px;
            }
        }
    }

    &__case-data {
        min-width: 260px;
        flex-grow: 1;

        @media (max-width: $media-xs) {
          width: 100%;
      }
    }

    &__case-wines {
        width: 320px;

        @media (max-width: $media-xs) {
          & + .button { width: 100%; }
        }
    }

    &__controls {
      width: 100%;
      @include flexLayout(center, space-between);
      transform: translateY(50%);
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 0 32px;

      button {
        color: $primary-brand-color;
        width: 275px;

        img {
            object-fit: none;
            object-position: center;
            width: auto;
            gap: 4px;
        }
      }

      @media (max-width: $media-xs) {
          padding: 0;
          gap: 32px;
          transform: translateY(calc(100% + 8px));

          // button.button {
          //   background-color: rgba(white, .7) !important;
          // }
          .button { width: calc(50% - 8px); }
      }
    }
}

.spin-center {
    margin: auto;
    height: 32px !important;
    width: auto !important;
}

.hero-wrapper {
  position: relative;
    margin-top: 64px;
    min-height: 300px;
    height: auto;
    width: 100%;
    
    .hero__content {
      @media (max-width: $media-sm) {
        gap: 16px;
      }

      @media (max-width: $media-xs) {
        gap: 8px;
      }
    }

    .hero__controls {
      @media (max-width: $media-xs) {
        transform: none;
        position: relative;
        bottom: auto;
        right: auto;
        padding: 0;
        margin-top: 8px;
        gap: 26px;

        button {
          // background-color: rgba(white, .7) !important;
          font-size: 14px;
          flex-grow: 1;

          img { width: 16px; height: 16px; }
        }
      }
    }

    .hero_recommendation__purchase {

      @media (max-width: $media-sm) {

        .button-link { padding: 10px 0; white-space: nowrap; }
      }
    }

    .hero-title {
        font-family: $avenir;
        color: $text-base-color;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 1;
        margin: 0;
        padding: 0 0 16px;
    }

    @media (max-width: $media-sm) {
        margin-bottom: 64px;
    }

    @media (max-width: $media-xs) {
        margin-bottom: 0;
        height: auto;
    }
}


.recommendation-stock-badge {
  color: black;
  left: 270px;
  text-transform: uppercase;
  font-style: italic;
  
  @media (max-width: $media-xs) {
    left: 235px;
    font-size: 14px;
  }
}
