@import '../../theme/variables';


.hero__wines-gallery {
    @include flexLayout(center, space-between);
    flex-wrap: wrap;
    // gap: 14px 8px;

    &.six-pack-gallery {
      .wines-gallery__item {
        width: calc(100% / 3);

        &:not(:nth-child(4n + 1)) {
          border-left: none;
        }
  
        &:nth-child(n + 5) {
            border-top: none;
        }

        &:not(:nth-child(3n + 1)) {
          border-left: 3px dashed $icon-color;
        }
  
        &:nth-child(n + 4) {
          border-top: 3px dashed $icon-color;
        }
      }
    }
}

.wines-gallery__item {
    position: relative;
    width: 25%;
    height: 80px;
    padding: 5px 7px;
    
    &:not(:nth-child(4n + 1)) {
        border-left: 3px dashed $icon-color;
    }

    &:nth-child(n + 5) {
        border-top: 3px dashed $icon-color;
    }

    img {
        width: 100%;
        border-radius: 4px;
    }

    @media (max-width: $media-sm) { height: 60px; }
}

.wines-gallery__badge {
    border-radius: 50%;
    position: absolute;
    width: 24px;
    height: 24px;
    border: 3px solid white;
    bottom: 0;
    right: 0;

    &.red {
        background-color: $grape-red-color;
    }

    &.white {
        background-color: $icon-color;
    }

    &.rose {
        background-color: $grape-rose-color;
    }

    &.sparkling {
        background-color: $bg-card-color;
    }
}
