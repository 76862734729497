@import "../../theme/variables";

header {
  top: 0;
  height: 90px;
  padding: 16px 0px;
  background: $primary-brand-color;
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 100;
  width: 100%;
  transition: all .1s ease-in-out;

  &.transparent {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.2) 53.12%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    top: 0;

    &.is-sticky {
      // background: $quarternary;
      background: rgba($secondary, .5);
      position: fixed;
    }

    @media (max-width: $media-xs) {
      .container { padding: 0 32px; }
    }
  }

  &.is-hidden {
    overflow: hidden;
    transform: translateY(-100%);
  }

  .container {
    width: 100%;
  }

  &.admin-header {
    height: 40px;
  }
}

.logo {
  filter: brightness(0) invert(1);
  height: 38px;

  @media (max-width: $media-sm) {
    height: 26px;
  }
}

.header-nav {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .header-links {
      display: flex;
      justify-content: flex-end;
      padding: 8px 0px;

      .link {
        background-color: transparent;
        position: relative;

        &.with-padding {
          padding-right: 16px;
        }

        a {
          color: $white;
          font-weight: bold;
        }

        select {
          background: transparent;
          padding: 0;
          border: none;
          outline: none;
          cursor: pointer;
          color: $white;
          font-weight: bold;

          option {
            color: $primary-brand-color;
          }
        }

        .region-card__label {
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(-16px, -100%);
        }

        @media (max-width: $media-xs) {
          padding: 0 8px;

          .new-item-badge {
            display: none;
          }
        }
      }

      &.with-padding {
        padding: 8px 0;
        margin-left: 32px;

        .link + .link {
          margin-left: 16px;
        }
      }


      .login-link {
        border: 1px solid white;
        padding: 8px 56px !important;
        border-radius: 50px;
        text-align: center;
        margin-right: 16px;

        @media (max-width: $media-xs) {
          border: initial;
          padding: 0 16px !important;
          border-radius: initial;
          text-align: initial;
          margin-right: initial;
        }
      }

      .link + .link {
        margin-left: 32px;

        @media (max-width: $media-xs) {
          margin-left: 0;
        }
      }

      @media (max-width: $media-xs) {
        // width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: row-reverse;
      }
    }

    .header-links + .main-menu {
      margin-left: 32px;
    }

    @media (max-width: $media-xs) {
      width: 100%;
      // margin-top: -30px;
      // align-items: flex-end;
      // flex-direction: column-reverse;
      justify-content: flex-end;
    }
  }

  @media (max-width: $media-xs) {
    flex-wrap: nowrap;
    align-items: flex-end;
  }
}

.home-header {
  position: relative;
  height: 275px;

  img {
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
    filter: brightness(0.7);
    clip-path: ellipse(65% 80% at 50% 20%);
  }

  .welcome-title {
    top: 100px;
    position: absolute;
    width: 100%;
    color: #fff;
    text-align: center;

    h1 {
      font-size: 64px;
      margin-top: 0;
      margin-bottom: 16px;
    }
  }

  &.region-header {
    height: 700px;
    // margin-bottom: 100px;

    img {
      filter: none;
      clip-path: none !important;
    }

    .home-header__background {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-bottom-right-radius: 25% 5%;
      border-bottom-left-radius: 25% 5%;

      img {
        // clip-path: ellipse(100% 95% at 50% 5%);
        clip-path: none !important;
      }
    }

    .home-header__alert .button-link  {
      color: white;
    }

    .welcome-title {
      h1 {
        font-weight: 400;

        @media (max-width: $media-sm) {
          font-size: 24px;
          margin-bottom: 8px;
        }
      }

      .welcome-subtitle {
        font-size: 24px;
        font-weight: 600;
        padding: 0;
        color: $white;
        margin: 0;

        @media (max-width: $media-sm) {
          font-size: 14px;
        }
      }

      @media (max-width: $media-sm) {
        top: 125px;
      }
    }

    .hero-wrapper {
      position: absolute;
      bottom: 90px;
      width: auto;
      left: 24px;
      right: 24px;
      z-index: 10;
      transition: all .3s ease-out;
      transition-delay: 0s;

      &.expanded {
        bottom: -45px;
      }

      .hero__case-wines {
        margin-top: 8px;
      }
    }

    @media (max-width: $media-xs) {
      // height: 500px;
      // height: calc(100vh - 90px);
      height: calc(100vh - 200px);
      min-height: 550px;

      .home-header__background {
        border-radius: 0%;

        // img {
        //   clip-path: ellipse(100% 50% at 50% 50%);
        // }
      }
    }
  }

  &__alert {
    background-color: $info;
    // background-color: #81a398;
    // background-color: $vineify-green;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    color: $white;
    font-weight: 500;
    top: 0;
    text-align: center;
    width: 100%;
    // position: absolute;
    z-index: 10;

    button.button-link {
      color: $white;
      font-weight: 500;
    }

    p {
      font-size: 14px;
      margin: 0;

      @media (max-width: $media-sm) {
        font-size: 12px;
        display: inline-flex;
        flex-wrap: wrap;
        gap: 4px;
        padding: 0 24px;
      }

      @media (max-width: $media-xs) {
        // padding: 0 6px;
        // display: none;
        padding: 0 8px;
        justify-content: center;
      }
    }

    .dismiss-button {
      text-decoration: none;
      position: absolute;
      right: 0;
      transform: translate(calc(50% + 32px), -25%);
    }
  }

  .region-header__content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: stretch;
    bottom: 0;
    width: 100%;
    z-index: 5;

    .container {
      display: flex;
      justify-content: flex-end;
    }

    .region {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-end;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -25%);
      color: $white;
      z-index: 10;

      &-cases__badge {
        background: rgba(white, 0.9);
        border-radius: 4px;
        padding: 8px 24px;
        text-transform: uppercase;
        color: $disabled-color;
        font-weight: 800;
        // font-size: 24px;
        font-size: $subtitle;

        @media (max-width: $media-xs) {
          font-size: 14px;
          padding: 4px 12px;
        }
      }

      h1 {
        // font-size: 120px;
        line-height: 1.25;
        border-bottom: 1px solid rgba(white, .7);
        margin: 0;
        text-shadow: 0px 0px 30px black;
        color: $white;
      }

      .subtitle {
        // font-size: 60px;
        font-size: $h4;
        font-weight: 700;
        color: $white;
        text-align: right;
        line-height: 1.25;
        margin: 0;
        text-shadow: 0px 0px 30px black
      }

      &__bottom-links {
        @include flexLayout(center, center, column);
        gap: 12px;
        padding: 32px;
        position: absolute;
        bottom: 0;
        width: 100%;

        h4 {
          color: $white;
          margin: 0;
        }

        button {
          @include flexLayout(center, center);
          gap: 12px;
          padding: 0;
          font-weight: 800;
          color: $white;
          font-size: 20px;
          text-decoration: none;

          img {
            filter: brightness(12);
            height: 8px;
            width: auto;
          }

          @media (max-width: $media-xs) {
            width: auto;
          }
        }

        @media (max-width: $media-xs) {
          padding: 24px 16px;
        }
      }

      @media (max-width: $media-sm) {
        transform: translateY(-40px);
      }

      @media (max-width: $media-xs) {
        // left: 50%;
        // text-align: center;
        transform: none;
        position: absolute;
        top: 45px;

        h1 {
          font-size: 56px;
        }
        .subtitle {
          font-size: 18px;
        }
      }
    }
  }

  // .region {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -25%);
  //   color: $white;
  //   z-index: 10;

  //   h1 {
  //     font-size: 120px;
  //     font-weight: 500;
  //     line-height: 1.25;
  //     border-bottom: 1px solid;
  //     margin: 0;
  //   }

  //   .subtitle {
  //     font-size: 60px;
  //     text-align: right;
  //     line-height: 1.25;
  //     margin: 0;
  //   }

  //   @media (max-width: $media-xs) {
  //     left: 50%;
  //     transform: translate(-50%, -60%);
  //     text-align: center;

  //     h1 { font-size: 68px; }
  //     p { font-size: 38px; }
  //   }
  // }

  .region-map {
    position: absolute;
    // bottom: -130px;
    bottom: 0;
    // left: 80px;
    left: 150px;

    img {
      // height: 600px;
      // width: auto;
      width: 375px;
      filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.35));
    }

    @media (max-width: $media-sm) {
      left: 24px;
      // bottom: -120px;

      img {
        height: 400px;
        width: auto;
      }
    }

    @media (max-width: $media-xs) {
      top: 125px;

      img {
        height: 220px;
      }
    }
  }

  .region-preview {
    position: absolute;
    right: 80px;
    bottom: 0;
    transform: translateY(50%);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    .card__body {
      background-color: $white;
    }

    img {
      clip-path: none;
    }

    @media (max-width: $media-sm) {
      right: 16px;
      bottom: 40px;

      h1 {
        font-size: 32px;
      }
    }

    @media (max-width: $media-xs) {
      right: 50%;
      transform: translate(50%, 50%);
      bottom: 20px;
    }
  }

  @media (max-width: $media-sm) {
    .welcome-title {
      top: 140px;

      h1 {
        font-size: 32px;
      }
    }
  }
}

.regions-dropdown {
  background-color: transparent;
  width: 80px;

  &__control {
    color: $white;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0 20px 0 0;
    font-size: 16px;
    font-weight: 600;
  }

  &__menu {
    width: auto;
    min-width: 200px;
    height: auto;
    max-height: 450px;
    max-width: 300px;
    right: 50%;
    transform: translateX(50%);
    // padding: 4px 16px;
    border-radius: 4px;
    margin-top: 8px;

    // .Dropdown-option {
    //   padding: 12px 0;
    //   color: $primary-brand-color;

    //   &:hover {
    //     background-color: transparent;
    //     text-decoration: underline;
    //   }
    // }

    // .Dropdown-option + .Dropdown-option {
    //   border-top: 1px solid $icon-color;
    // }
  }

  &__arrow {
    position: absolute;
    color: $white;
    border-color: transparent;
    filter: brightness(12);
    right: 0;
    top: 50%;
    width: 12px !important;
    height: auto !important;
    transform: translateY(-50%);
  }

  &.appellations {
    width: 175px;

    .regions-dropdown__control, .regions-dropdown__arrow {
      color: black;
    }

    .regions-dropdown__menu {
      width: 250px;
      right: 120px;
    }
  }
}

.page-header {
  position: relative;
  height: 200px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;

  img {
    object-fit: cover;
    object-position: bottom;
    width: 100%;
    height: 100%;
  }

  .overlay {
    background-color: rgba($text-base-color, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .container {
    z-index: 10;
  }

  .page-title {
    bottom: 0;
    margin: 0;
    // padding: 32px 0;
    margin-top: 32px;
    width: 100%;
    max-width: 800px;
    color: $white;

    h1 {
      margin-top: 34px;
      margin-bottom: 0;
      color: $white;
      font-size: $h2;

      @media (max-width: $media-xs) {
        font-size: 20px;
      }
    }

    @media (max-width: $media-xs) {
      top: inherit;

      h1 {
        margin-top: 0;
      }
    }
  }

  @media (max-width: $media-xs) {
    height: 100px;
  }
}

.sa-label {
  position: fixed;
  bottom: 16px;
  right: 16px;
  background-color: $white;
  z-index: 1000;
  border-radius: 4px;
  overflow: hidden;

  .close-modal-btn {
    z-index: 100;

    img {height: 12px;}
    
  }

  .alert {
    flex-direction: column;
    padding: 12px 36px;

    p {
      margin: 0 0 6px;
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.new-wines-notification {
  position: absolute;
  bottom: 6px;
  right: -16px;

  &::after {
    content: "";
    position: absolute;
    width: 9px;
    height: 9px;
    right: 0;
    top: 0;
    transform: translate(4px, -3px);
    background-color: $error-color;
    border-radius: 50%;
    border: 1.5px solid $primary-brand-color;
  }
}

.mobile-lng-menu {
  right: 64px;
}

.welcome-header-container {
  display: flex;
  justify-content: space-between;
  gap: 90px;

  @media (max-width: $media-sm) {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
}
