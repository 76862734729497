@import '../../theme/variables';

.status-badge {
    background-color: $alabaster;
    color: $vineify-green;
    border-radius: 50px;
    padding: 14px 20px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;


    .status__name {
        line-height: 1.25;
        margin: 0;
    }

    .status__icon { 
      margin-left: 14px;
      height: 28px;
    }

}

.new-item-badge {
    padding: 4px 8px;
    min-width: 130px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: $quarternary;
    border: 2px solid $quarternary;
    border-radius: 1000px;
    text-transform: uppercase;

    &.gold { border-color: $white-wine; color: $white-wine-text; }
    &.green { background-color: $primary-brand-color; }
    &.red { background-color: $error-color; }
    &.white { background-color: $white; }

    .badge-icon {
        margin-right: 4px;
        height: 12px;
        width: auto;
        filter: brightness(12);
    }

    .badge-text {
        text-transform: uppercase;
        font-weight: 800;
        line-height: 1;
        margin: 0;
    }
}

.rec-badge {
    position: relative;
    height: 20px;
    padding: 2px 4px;
    font-size: 12px;
    text-transform: lowercase;
    font-weight: bold;
    border: 1px solid;
    border-radius: 3px;
    @include flexLayout(center, center);
    gap: 2px;

    img {
        max-width: 100%;
    }
}

.wines-summary {
    display: flex;
    align-items: center;

    .wine-badge {
      background-color: $alabaster;
      color: $white-wine-text;
      width: 142px;
      height: 45px;
      @include flexLayout(center, center, row);
      border-radius: 6px;
      text-transform: uppercase;
      font-weight: 500;
    }

    .wine-badge + .wine-badge { margin-left: 12px; }
  }

  .custom-case-badge {
    position: relative;
    margin-left: 6px;
    align-self: flex-end;
    color: #D1AD82;
    border: 2px solid;
    background-color: transparent;
    padding: 4px 8px;

    &.float {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    .status__name { 
      font-size: 10px;
      color: $icon-color;
    }
  }
  