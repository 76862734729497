@import '../../theme/variables';


.warehouses-nav {
  @include flexLayout(flex-start, center);
  gap: 32px;
  margin-top: 32px;

  .nav-card + .nav-card {
    margin: 0;
  }
}
