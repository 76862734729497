@import '../../theme/variables';

.admin-content {
    padding: 16px 24px;
    min-height: calc(100vh - 32px);
    flex: 1 0 calc(100% - 350px);
    width: calc(100% - 350px);

    & > .container { max-width: 95%; }

    .container { padding: 0; }
}

.admin-title {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 32px 0;

    h1 {
      margin: 0;
      font-size: $h3;
    }

    @media (max-width: $media-xs) {
      flex-direction: column;
      gap: 12px;
    }
}

.admin-layout {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  border-radius: 10px;
  padding: 16px 24px;
  margin-bottom: 32px;
  background-color: $white;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

  .admin-subtitle {
    font-weight: 600;
    font-family: 'Avenir Next';
    font-size: $subtitle;
  }

  .flex-row {
    .flex-column {
      @media (max-width: $media-xs) {
        padding: 0;
      }
    }
  }

  &__filters {
    padding: 16px;
    margin-bottom: 16px;
    @include flexLayout(center, flex-start);

    .form-control {
      margin: 0;
    }

    .form-control + .form-control {
      margin-left: 24px;
    }
  }

  @media (max-width: $media-xs) {
    padding: 16px;

    .order-contact-details {
      height: auto;
      padding: 16px;
      margin-top: 16px;
    }
  }

  .producer-id {
    width: 24px;
    text-align: right;
  }
  
  .producer-name {
    flex-grow: 1;
    width: 200px;
    text-align: left;
  }
  
  .producer-wines {
    flex-shrink: 0;
    width: 60px;
    text-align: center;
  
    &.title {
      width: 410px;
    }
  }

  .ai-logs__layout {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .accordion {
    border-radius: 8px;
    overflow: hidden;

    &__header {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      gap: 32px;
      cursor: pointer;
      background-color: $warm-gray-12;
      color: white;
    }

    &__body {
      padding: 0;

      .ai-log-item {
        padding: 24px;
        display: flex;
        flex-direction: column;

        &:nth-child(even) {
          background-color: rgba($warm-gray-3, .2);
        }

        // &:not(:last-child) {
        //   border-bottom: 1px solid $alabaster;
        // }

        &__header {
          display: flex;
          justify-content: space-between;
          gap: 32px;

          .button-link { padding: 0; }
        }

        &__date {
          margin-bottom: 12px;
          font-size: 14px;
          color: $warm-gray-12;
          width: auto;
        }

        &__prompt {
          font-weight: 600;
          margin: 0;
        }

        &__message {
          font-style: italic;
          margin: 12px 0 0;
          color: $disabled-color;
          white-space: pre-line;
        }
      }
    }
  }
}

.admin-cards-layout {
  display: flex;
  padding: 16px 0;
  gap: 24px;
  justify-content: space-between;

  .report-layout {
    width: calc(50% - 24px / 2);
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    &__title {
      font-size: 28px;
    }
  }
}

.filters--cases {
  flex-wrap: wrap;
  gap: 16px;

  .form-control {
    @include flexLayout(center, center);
    gap: 6px;
    width: 30%;
    flex-shrink: 1;

    .select-wrapper { width: 100%; }
  }

  .form-control + .form-control { margin-left: 0; }
}

.verifications {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);


    .verification__item, .verifications__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px;
        font-size: 14px;

        .verification__col {
          flex: 125px 0 0;
          position: relative;
          margin-left: 16px;
          overflow: hidden;
          text-overflow: ellipsis;

          &.col--email {
            flex-grow: 1.5;
            flex-shrink: .33 !important;
            display: flex;
            align-items: center;
            font-size: 14px !important;
            gap: 8px;
            overflow: visible;
            height: 40px;
          }

          &.col--name {
            display: flex;
            align-items: center;
            flex-direction: column;
            flex-grow: 1.5;
            gap: 8px;
            height: 40px;
            align-items: flex-start !important;
            flex-shrink: .33 !important;
          }
            
          .verification-badge {
            bottom: 50%;
            transform: translateY(50%);
          }

          &:not(:last-child) {
            box-sizing: border-box;
            font-size: 16px;
            line-height: 1;
            outline: none;
            border-radius: 100px;
            text-align: left;
            padding: 0 16px;
          }

          &:first-child { 
            flex: 125px 0 0;
            text-align: center;
            margin-left: 0;
            flex-shrink: 0 !important;
          }

          &:last-child { 
            flex-basis: 235px !important;
            flex-shrink: 0 !important;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 35px;
            font-size: 14px;
            overflow: visible;
          }

            .verification__comment { 
                position: absolute;
                width: auto;
                display: inline-flex;
                margin: 0;
            }

            .button + .verification__comment { 
                font-size: 14px;
                width: auto;
                transform: translateX(-150%);
            }
        }

        .header__col {
            flex: 125px 0 0;
            padding: 0px 16px;
            font-size: 20px;
            line-height: 1;
            font-weight: bold;
            margin-left: 16px;
            color: $white;

            &.col--email {
              flex-grow: 1.5;
              flex-shrink: .3 !important;
            }

            &.col--name {
              flex-grow: 1.5;
              flex-shrink: .5 !important;
            }

            &:first-child { 
                // flex: 215px 0 0; 
                flex: 125px 0 0; 
                text-align: center;
                margin-left: 0;
                flex-shrink: 0 !important;
            }

            &:last-child { 
                flex: 235px 0 0; 
                text-align: center;
            }
        }
    }

    .verification__item + .verification__item {
        border-top: 1px solid $disabled-color;
    }

    .verifications__header { 
        background-color: $primary-brand-color;
        margin-bottom: 16px;
    }
}

.tools-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .filters {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;

        &--manager {
          flex-direction: row;
          gap: 12px;
          flex-wrap: wrap;

          @media (max-width: $media-xs) {
            flex-direction: column;
            gap: 6px;
          }
        }

        .form-control {
            width: 400;
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
        }

        @media print {
          display: none;
        }
    }
}

.admin-container {
    background-color: $secondary-brand-color;
}

.admin-content-wrapper {
    display: flex;
}

.orders-statistics {
    position: relative;
    // margin-top: -85px;
    height: 275px;
    width: 720px;
    padding: 16px 32px;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: $white;
    border-radius: 10px;
    flex-grow: 1;

    .stat-caption {
        font-family: $quincy;
        font-size: 32px;
        line-height: 1;
        font-weight: 600;
        margin-bottom: 16px;
        border-bottom: 1px solid;
    }

    .stat-item {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .stat-case {
            font-weight: 600;
            flex-grow: 1;
            width: 235px;
        }

        .stat-ordered {
            padding: 0 8px;
            min-width: 100px;
        }

        .stat-total {
            width: 100px;
            text-align: right;
        }

        &:last-child:not(.flex-center) {
            align-items: flex-end;
        }
    }
}


.admin-users {

    &__header {
        padding: 16px;
        display: flex;
        background-color: $primary-brand-color;
        color: $white;
        font-weight: 600;

        .id, .name, .email, .orders, .date {
            padding: 0 8px;
        }

        .id {
            width: 60px;
            text-align: center;
        }

        .name {
            width: 250px;
            justify-content: flex-start;
        }

        .email {
            width: 150px;
            flex-grow: 1;
            justify-content: flex-start
        }

        .orders {
            width: 60px;
            text-align: center;
        }

        .date {
            width: 120px;
            text-align: center;
        }

        .tools {
            width: 100px;
            margin: 0 16px;
        }
    }

    &__body {
        padding: 16px;
        display: flex;
        flex-direction: column;

        .admin-user-row-wrapper {
            @include flexLayout(space-between, center);
            gap: 16px;

            .admin-user-row {
            padding: 16px 0;
            display: flex;
            flex-grow: 1;
            align-items: center;
            transition: height 0.6s ease-in-out;

            .id, .name, .email, .orders, .date {
                padding: 0 8px;
            }
    
            .id {
                width: 60px;
                text-align: center;
            }
    
            .name {
                width: 250px;
            }
    
            .email {
                width: 150px;
                flex-grow: 1;
            }

            .orders {
                width: 60px;
                text-align: center;
            }

            .date {
                width: 120px;
                text-align: center;
            }
        }

        .tools {
            width: 120px;
            padding: 8px;
            z-index: 15;
            flex-shrink: 0;
        }
    }

        .admin-user-row + .admin-user-row {
            border-top: 1px solid $disabled-color;
        }
    }
}


.admin-cases, .curator-panel > .admin-cases {
    padding: 16px 0;

    &__header {
      padding: 16px;
      background-color: $primary-brand-color;
      color: $white;
      font-weight: bold;
      display: flex;
      align-items: center;

      .id, .date, .name, .cost, .stock, .status, .category, .orders, .curator {
          padding: 8px;
          text-align: center;
      }

      .id { width: 40px }
      .date { width: 120px;  }

      .orders { 
        width: 80px;
      }

      .stock { 
        width: 120px;
        justify-content: flex-end;
      }

      .cost { 
        width: 110px;
        justify-content: flex-start;
      }

      .status { width: 120px; }
      .category { 
        width: 120px;
        justify-content: flex-start;
      }

      .name {
          width: 170px;
          text-align: left;
          justify-content: flex-start;
          flex-grow: 1;
      }

      .curator {
        width: 220px;
        text-align: left;
        justify-content: flex-start;
        flex-shrink: 0;
      }
    }

    &__body {
      padding: 8px 0;

      .admin-case-row, .admin-case-mobile-row {
        padding: 0 12px;
        cursor: pointer;
        color: $text-base-color;
        display: flex;
        align-items: center;

        .id, .date, .name, .cost, .stock, .status, .category, .orders, .curator {
          padding: 12px 8px;
          text-align: center;
        }

        .id { 
          width: 40px;
          display: flex;
          justify-content: center;
        }
        .date { width: 120px;  }
        .orders { width: 80px; }
        .stock {
          position: relative;
          width: 120px;
        }

        .cost { width: 110px; }

        .status { width: 120px; }

        .name {
          width: 170px;
          text-align: left;
          flex-grow: 1;
        }

        .curator {
          width: 220px;
          text-align: left;
          padding: 0 8px;
          flex-shrink: 0;

          @media (max-width: $media-xs) {
            padding: 6px;
            width: 100%;
          }
        }

        .category {
          width: 120px;
          white-space: pre-wrap;
          justify-content: flex-start;
        }

        &.low-stock {
          // background-color: rgba(red, .1);
          .name, .stock {
            font-weight: 600;
            color: #FF9700;
          }
        }

        &.no-stock {
          // background-color: rgba(red, .3);
          .name, .stock {
            font-weight: 600;
            color: #C92C53;
          }
        }
      }

      .admin-case-row + .admin-case-row {
        border-top: 1px solid $disabled-color;
      }

      .admin-case-mobile-row {
        flex-wrap: wrap;
        margin-top: 8px;
        padding-top: 16px;
        padding-bottom: 16px;

        .id, .date, .name, .cost, .stock, .status, .category, .orders {
          width: auto;
          gap: 4px 12px;
          padding: 6px;
        }

        .name { 
          flex-grow: 1;
          width: 100%;
        }
      }
    }
}

.admin-case-data {
    padding: 32px 64px;

    .flex-space-between {
      @media (max-width: $media-xs) {
        flex-wrap: wrap;
      }
    }

    .case-image {
      display: flex;
      gap: 16px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 175px;
      width: 328px;
      overflow-x: hidden;

      .card.card_case {
        height: 220px;
      }

      img {
        width: 100%; 
      }
    }

    .case-details {
      // width: 60%;
      width: 500px;
      padding: 16px 32px;
      align-self: flex-start;
      flex-grow: 1;

      li {
        padding: 4px 16px;
        font-size: 18px;
        width: 420px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        b { flex-grow: 1; }

        button { font-size: 14px; }
        
        &.row {
          flex-wrap: nowrap;
          flex-direction: row;
          gap: 48px;
        }

        &.caption {
          font-size: 32px;
          margin-bottom: 16px;
          font-weight: 600;
          border-bottom: 1px solid $disabled-color;
        }

        .badge {
          background-color: $primary-brand-color;
          border-radius: 50%;
          height: 32px;
          width: 32px;
          color: $white;
          display: flex;
          justify-content: center;
          align-items: center;

          &.low-stock {
            background-color: rgba(red, .75);
            font-weight: 600;
          }
        }

        @media (max-width: $media-xs) {
          width: 100%;
          font-size: 16px;
        }
      }

      &.striped > li:nth-child(even) {
        background-color: rgba($disabled-color, .1);
      }

      @media (max-width: $media-xs) {
        width: 100%;
        padding: 0;
        margin-top: 32px;

        .caption {
          display: none;
        }
      }
    }


    .case-notes {
      @include boxShadow(rgba(0, 0, 0, 0.05), 12px);
    }
    
    .admin-wines-header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      font-weight: 600;
      background-color: rgba($disabled-color, .25);
      
      .id, .date, .name, .quantity, .stock, .producer, .region {
        padding: 8px;
        text-align: center;
      }
      
      .id { width: 30px; } 
      .quantity, .stock { width: 50px; }
      .cost { width: 120px; text-align: center; }
      .date { width: 80px }
      
      .name {
        width: 200px;
        flex-grow: 1;
        text-align: left;
      }
      
      .producer, .region {
        width: 130px;
        text-align: left;
        flex-shrink: 0;
      }
    }

    &__wines {
      padding: 8px 24px;

      .admin-wine-row {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 -8px;

        .id, .date, .name, .quantity, .stock, .producer, .region {
          padding: 8px;
          text-align: center;
        }

        .id { width: 30px; }
        .quantity, .stock { width: 50px; }
        .cost { width: 120px; text-align: center; }
        .date { width: 80px }

        .name {
          width: 200px;
          flex-grow: 1;
          text-align: left;
        }

        .producer, .region {
          width: 130px;
          text-align: left;
          flex-shrink: 0;
        }

        &.low-stock {
          background-color: rgba(red, .25);
          font-weight: 600;
        }
      }

      @media (max-width: $media-xs) {
        padding: 0;
      }
    }

  @media (max-width: $media-xs) {
    padding: 0;
  }
}

.confirmation-modal {
    width: auto;
    height: auto;
    min-height: 0% !important;
}

.report-layout {
    padding: 32px;

    &.striped:nth-child(even) {
      background-color: rgba($disabled-color, .05);

      .records-list {
        background-color: white;
        border: 1px solid $alabaster;
      }
    }

    &__title {
      font-size: 32px;
      padding: 0;
      margin-bottom: 16px;
    }

    h2 { 
        font-size: 36px;
        font-family: $avenir;
        margin-top: 0;
    }

    .wines-total {
        padding: 0 16px;
        font-size: 18px;
    }

    .customer-referrers {
        margin-bottom: 16px;

        .card__body {
            padding: 0;
            transition: height .6s ease-in-out;
    
            .customer-data {
                display: flex;
                align-items: center;
                padding: 16px 32px;
                font-weight: 600;
                font-size: 20px;
                background-color: rgba($bg-card-color, .15);

                .id, .name, .stats { padding: 8px 16px; }
                button { margin: 0 16px; }

                .name { flex-grow: 1; }
            }
        }
    }

    .report-content {
        padding: 16px 30px;

        .referrer {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .name, .email, .date {
                padding: 6px 12px;
            }

            .name {
                width: 250px;
                flex-grow: 3;
            }

            .email {
                width: 175px;
                flex-grow: 2;
            }

            .date {
                width: 120px;
                flex-grow: 1;
            }
        }

        .sales-list {

          li {
            display: flex;
            align-items: center;

            a:not(.sales_id) {
              flex-grow: 1;
              width: 100%;
              display: flex;
              align-items: center;
            }

            .id, .name, .quantity, .date, .customer {
                padding: 6px 12px;
            }

            .quantity, .id { width: 46px; }
            .name { flex-grow: 1; }
            .customer { width: 176px; }

            .negative { color: $warning }
            .positive { color: $vineify-green }
          }
        }

        @media (max-width: $media-xs) {
          padding-left: 0;
          padding-right: 0;
        }
    }
}

.reports-tools {
    padding: 32px 64px;
    background-color: $white;
    border-radius: 10px;
    margin-bottom: 32px;

    h2 {
        font-size: 24px;
        color: $disabled-color;
        margin: 0 0 16px;
    }

    .report-links-list {
        width: 100%;
        padding: 16px 0;
        display: flex;
        align-items: center;

        .report-link {
            position: relative;
            background-color: transparent;
            border: 1px solid;
            border-radius: 4px;
            padding: 8px 24px;
            text-decoration: none;
            display: flex;
            align-items: center;
            font-size: 18px;
            filter: contrast(0);

            &:not(:disabled):hover {
                background-color: rgba($bg-card-color, .25);
                text-decoration: none;
            }

            img {
                height: 24px;
                margin-right: 8px;
            }

            &.active {
                background-color: $bg-card-color;
                color: $white;
                font-weight: 600;
                filter: none;

                img {
                    filter: brightness(3);
                }
            }
        }

        li + li { margin-left: 32px; }
    }
}

.admin-report-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    font-weight: 600;
    background-color: rgba(120, 120, 120, 0.25);
}

.orphans-content {
    display: flex;

    .orphans-list {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-self: stretch;
        width: 33.33%;
        padding: 0 24px;
    
        .list-caption {
            margin-bottom: 16px;
            font-weight: 600;
            font-size: 20px;
            margin: 0 0 20px;
            padding: 0;
        }
    
        ul { flex-grow: 1; }
    
        .orphan-item {
            padding: 8px 16px;
        }
    
        button {
            margin-bottom: 32px;
        }
    }
}

.tracking-info-container {
    // padding: 8px 16px;
}

.admin-order-details {

    .order-card {
      box-shadow: none;

      @media (max-width: $media-xs) {
        padding: 16px;

        &__body {

          .card_case {
            width: 100%;
          }
        }

        &__footer {
          border: none;
        }
      }
    }
}

.test-order {
    opacity: .8;
    position: relative;

    &::after {
        content: 'test';
        color: $white;
        position: absolute;
        background-color: $icon-color;
        padding: 2px 8px;
        left: -8px;
        border-radius: 4px;
        font-size: 12px;
    }
}


.custom-cases-list {
    padding: 16px 0;

    &__header {
        margin: 12px 0;
        
        .title {
            font-weight: 600;
            font-size: 20px;
            padding: 0 16px;
        }
    }

    .custom-case-row {
        padding: 4px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        .main {
            padding: 0 8px;
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .name { 
                font-weight: 600;
                padding: 8px 0;
                display: flex;
                align-items: center;

                img {
                    height: 12px;
                    margin-left: 16px;
                    margin-right: 4px;
                }
            }
        
            .wine-lovers-list {
                display: flex;
                align-items: center;
    
                .wine-lover-badge {
                    padding: 2px 4px;
                    margin: 2px;
                    font-size: 10px;
                    border: 1px solid rgba($bg-card-color, .3);
                    background-color: rgba($bg-card-color, .15);
                }
            }
        }
    
        .stock {
            padding: 0 24px;
        }
    
        button.button {
            width: auto;
            margin: 0;
        }
    }

    .custom-case-row + .custom-case-row {
        border-top: 1px solid rgba($disabled-color, .3);
    }
}

[data-sort] {
    height: 14px;
    font-size: 16px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &::after {
        content: url('../../assets/icons/chevron-down.svg');
        filter: brightness(12);
        display: inline-block;
        transform: scaleX(0.7);
    }

    &[data-order="desc"]::after {
        content: url('../../assets/icons/chevron-up.svg');
    }
}

.incomplete-alert {
    position: absolute;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    right: 0;
    bottom: 0;
    transform: translateY(calc(100% + 12px));

    .alert {
        padding: 8px 0;
        box-shadow: none;
        font-size: 18px;
    }
}

.recommendation-modal {
    width: 95% !important;
    // max-width: 98%;
    opacity: 1;
    height: calc(100vh - 60px);
    min-height: auto !important;

    &.small {
      width: 600px !important;
      max-width: 90%;
    }

    .tox .tox-statusbar {
        display: none;
    }

    .form-section-title {
      margin-top: 0;
    }

    &.hidden {
      opacity: 0
    }
}

.grapes-list {
    min-height: calc(100vh - 90px);
}

.admin-tastes-list {
  position: relative;
  padding: 32px 24px;
  @include flexLayout(flex-start, flex-start);
  gap: 18px;
  flex-wrap: wrap;

  .admin-taste-row {
    width: calc(33.33% - 12px);
    background-color: $white;
    color: rgba($primary-brand-color, .65);
    transition: color, background-color .3s ease-out;
    padding: 8px;
    border: 1px solid;

    &:hover {
      background-color: $bg-card-color;
      color: $white;
    }

    @media (max-width: $media-xs) {
      width: 50%;
      max-width: calc(50% - 16px);
    }
  }

  @media (max-width: $media-xs) {
    padding: 0;
  }
}

.warehouses-dialog-layout {
  position: fixed;
  @include flexLayout(center, center);
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(black, .65);

  .confirmation-dialog {
    width: 350px;

    h4 { 
      font-size: 24px; 
      font-weight: 600;
      margin: 0;
    }

    .alert {
      padding: 16px;
      margin: 16px 0;

      .alert__text { font-size: 14px; }
    }
  }
}

.verifications-filter {
  padding: 32px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.admin-user__warehouses {
  padding: 32px 64px;
  display: flex;
  gap: 32px;

  .checkbox-wrapper { margin: 0; }
}

.admin-retailers {
  &__header {
    padding: 16px;
    display: flex;
    background-color: $primary-brand-color;
    color: $white;
    font-weight: 600;

    .id, .name, .date {
      padding: 0 8px;
    }

    .id {
      width: 60px;
      text-align: center;
    }

    .name {
      width: 250px;
      flex-grow: 1;
    }

    .date {
      width: 120px;
      text-align: center;
    }

    .tools {
      width: 280px;
      margin: 0 16px;
    }
  }

  &__body {
    padding: 16px;
    display: flex;
    flex-direction: column;

    .admin-retailer-row-wrapper {
      @include flexLayout(space-between, center);
      gap: 16px;

      .admin-retailer-row {
        padding: 16px 0;
        display: flex;
        flex-grow: 1;
        align-items: center;
        transition: height 0.6s ease-in-out;

      .id, .name, .date {
        padding: 0 8px;
      }

      .id {
        width: 60px;
        text-align: center;
      }

      .name {
        width: 250px;
        flex-grow: 1;
      }

      .date {
        width: 120px;
        text-align: center;
      }
    }

      .tools {
        width: 280px;
        display: flex;
        align-items: center;
        gap: 16px;
        margin: 0 16px;
        padding: 8px;
        z-index: 15;
      }
    }

    .admin-retailer-row + .admin-retailer-row {
      border-top: 1px solid $disabled-color;
    }
  }
}

.retailer-data__submit-payment-wrapper {
  width: 135px;
  flex-shrink: 0;
  align-self: center;
  padding-top: 12px;
}

.horizontal-datepicker {
  position: relative;
  width: 165px;

  &-input { width: 100%; }
}

.wine-integration-controls {
  padding: 60px 0;
  @include flexLayout(flex-end, flex-start);
  gap: 32px;
}

.region-wine-row {
  @include flexLayout(center, flex-start);
  gap: 8px;
}

.role-confirmation-dialog {
  box-shadow: none !important;
  margin: auto;
  width: 450px !important;
}

.wine-integration-layout {
  border-radius: 8px;
  border: 1px solid $white-wine;
  border-left-width: 8px;
  padding: 24px 24px 24px 32px;
  display: flex;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

  .region-map {
    width: 330px;
    color: $white-wine;
    font-size: 20px;
    flex-shrink: 0;
  }

  .region-wines-list {
    flex-grow: 1;

    &__add-wine {
      width: 100%;
      text-align: right;
    }
  }

  &.layout-white {
    border-color: $white-wine;

    h4 { color: $white-wine; }
  }

  &.layout-red {
    border-color: $red-wine;

    h4 { color: $red-wine; }
  }

  &.layout-rose {
    border-color: $rose-wine;

    h4 { color: $rose-wine; }
  }

  &.layout-sparkling {
    border-color: $vineify-green;

    h4 { color: $vineify-green; }
  }

  &.layout-column { flex-direction: column; }
}

.region-wine-row {
  @include flexLayout(flex-start, center);
  gap: 18px;

  &__appellation {
    width: 300px;
    flex-shrink: 0;
  }

   &__name {
    width: 200px;
    flex-grow: 1;
    display: inline-flex;
    gap: 8px;

    svg { flex-shrink: 0; }
   }

   &__vintage {
    width: 70px;
    text-align: center;
   }

   &__producer {
    width: 145px
   }

   &__cost {
    width: 80px;
    text-align: center;
   }

   &__control {
    width: 225px
   }

   &.headers {
    padding: 6px 24px 6px 32px;
    font-weight: 600;

    .region-wine-row__name { padding-left: 40px; }
   }
}

.appellations-dialog {
  width: 1300px;
  max-width: 90vw;
  padding: 24px;

  h3, h4 {
    margin: 16px 0;
    font-size: 16px;
  }

  h3 { 
    color: $white-wine;
    padding-top: 0;
    margin-bottom: 32px;
  }

  h4 {
    display: flex;
    align-items: center;
    margin: 0 0 32px;
  }
}

.appellation-wine-row {
  @include flexLayout(flex-start, center);
  gap: 18px;
  color: $vineify-green;

    &__name {
    width: 200px;
    flex-grow: 1;
    }

    &__producer {
    width: 160px
    }

    &__cost {
    width: 60px;
    text-align: center;
    }

    &__control {
    width: 120px;

    button.button-link { padding: 0; }
    }
}

.appellations-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0 16px;

  .subtitle {
    font-size: $h5;
    font-weight: 600;
  }
}

.wine-card-modal {
  position: relative;
  width: 500px !important;
  min-height: auto !important;
  height: 500px !important;
  background-color: #D9D9D9 !important;

  .rsm-flex-1.rsm-p-8 {
    padding: 0;
  }
}


.order-title-badge {
  margin-left: 12px !important;

  .status__name {
    font-size: 18px !important;
  }
}

.gift-message-alert {
  width: 100%;

  .alert__title {  font-size: $h5;}
}

.case-bottle-image {
  height: 250px;
  position: relative;

  img {
    height: 100%;
  }
}

.producers-header {
  padding: 8px 24px;
}

.admin-producer-wines {
  padding: 24px 0;

  &__row {
    display: flex;
    align-items: center;
    padding: 6px 16px;
    gap: 32px;
  }

  &__image {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      object-fit: contain;
      object-position: center;
      height: 100%;
    }
  }

  &__title {
    margin: 24px 0 12px;
    padding: 0;
  }

  &__name {
    flex-grow: 1;
    min-width: 200px;
  }

  &__vintage { 
    flex-shrink: 0;
    width: 60px;
    text-align: center;
  }

  &__region { 
    flex-shrink: 0; 
    width: 250px;
  }

  &__stock { 
    flex-shrink: 0; 
    width: 60px;
    text-align: center;
  }

  &__headers {
    font-weight: 600;
    background-color: rgba($disabled-color, .25);
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 32px;
  }
}

.dnd-layout {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.regions-tags-list.case-labels {
  padding: 6px 0;
  width: auto;

  &.public {
    
    li.regions-tags-list__region {
      background-color: rgba($info-light, .8);
      border-color: $info-light
    }
  }

  &.private {

    li.regions-tags-list__region {
      background-color: rgba($warning-light, .8);
      border-color: $warning-light
    }
  }

  li.regions-tags-list__region {
    width: auto;
    font-size: 14px;
  }
}
