@import '../../theme/variables';

.tasting-carousel {
    width: 100%;
    margin-bottom: 40px;
}

.tastings__media-list {
    width: 100%;
    padding: 32px 0px;
    position: relative;
    height: 600px;

    .media-list-inner {
        position: absolute;
        width: 1318px;
        overflow-x: hidden;
        height: 100%;
    }
}

.slider-control {
    display: flex;

    button {
        display: flex;
        align-items: center;
        transition: opacity .4s ease-out;
        border: 1px solid $disabled-color;
        opacity: .5;
        padding: 5px;
        border-radius: 4px;
        background: transparent !important;

        &:hover { opacity: 1; }

        &:disabled { 
            opacity: .2 !important;
            border: 1px solid $disabled-color !important;
        }

        img {
            height: 16px;
        }
    }

    button + button {
        margin-left: 8px;
    }
}

.carousel-content {
    display: flex;
    transition: transform .5s ease-out;
}

.card-carousel {
    position: relative;
    width: calc(100vw - 16px);
    padding: 0;


    .carousel-nav {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;


        &__item {
            padding: 8px 4px;
        }


        .carousel-nav-btn {
            border-radius: 50%;
            background-color: rgba($disabled-color, .2);
            transition: background-color .4s ease-out;
            width: 16px;
            height: 16px;


            &:hover {
                background-color: rgba($disabled-color, .6);
            }

            &.active {
                background-color: $disabled-color;
            }
        }
    }
}

.slick-track {
    display: flex !important;
}

.slick-slide {
    *:focus { outline: none; }

    & + & { margin-left: 16px; }
}

.with-pagination {
  padding-bottom: 60px !important;
}

.swiper-pagination-bullet.slider-dot {
  opacity: 0.25;
  background-color: #000 !important;
  width: 15px;
  height: 15px;
  margin: 0 10px !important;
  display: inline-block;
  border-radius: 50%;
  
  &.swiper-pagination-bullet-active { opacity: .75 !important; }
}

.swiper-with-navigation {

  .swiper-button-prev, .swiper-button-next {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 8px;
    border: 2px solid $vineify-green;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    top: 100% !important;
    transform: translateY(-45%) !important;
  
    &::after {
      transform: translateY(-8px) !important;
    }
  
    @media (max-width: $media-xs) {
      // display: none !important;
    }
  }

  .swiper-button-prev {
    left: calc(50% - 200px) !important;

    @media (max-width: $media-xs) {
      left: 0 !important;
    }
  
    &::after { content: url('../../assets/icons/arrow-left.svg') !important; }
  }
  .swiper-button-next {
    right: calc(50% - 200px) !important;

    @media (max-width: $media-xs) {
      right: 16px !important;
    }
  
    &::after { content: url('../../assets/icons/arrow-right.svg') !important; }
  }
}

.swiper-pagination-vineify {
  @media (max-width: $media-xs) {
    width: 250px !important;
    padding-right: 16px;
  }
}
