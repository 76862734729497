@import "../../theme/variables";


.quiz {
  @include flexLayout(center, space-between, column);

  .top-control {
    @include flexLayout(flex-start, flex-start);
    height: 62px;
    width: 100%;
    padding: 0 16px;

    @media (max-width: $media-xs) {
      height: 24px;
      width: auto;
      min-width: 145px;
    }
  }

  .quiz-steps-list {
    @include flexLayout(center, space-between);
    // gap: 40px;

    .quiz-step {
      @include flexLayout(center, center);
      // font-size: 28px;
      font-size: $subtitle;
      font-weight: 600;
      position: relative;
      width: 40px;
      height: 40px;
      padding: 6px;
      color: $vineify-green;
      border: 2px solid $vineify-green;
      border-radius: 50%;
      transition: all 0.6s ease-in-out;

      &.active {
        background-color: $vineify-green;
        color: $white;
      }

      img, svg { max-height: 100%; }

      @media (max-width: $media-xs) {
        width: 24px;
        height: 24px;
        font-size: 12px;
      }
    }

    .quiz-step + .quiz-step {
      margin-left: 40px;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-100%, -50%);
        height: 1px;
        width: 43px;
        border-top: 4px solid $vineify-green;
  
        @media (max-width: $media-xs) {
          width: 38px;
        }
      }

      @media (max-width: $media-xs) {
        margin-left: 34px;
      }
    }

    // @media (max-width: $media-xs) {
    //   gap: 30px;
    // }
  }

  .quiz-container {
    @include flexLayout(center, center, column);
    margin: auto;
    width: 100%;
    font-size: 24px;

    .quiz-header {
      // height: 164px;
      // height: 94px;
      margin: 40px 0;
      text-align: center;

      .quiz-title {
        // font-size: 48px;
        text-align: center;
        padding: 0;
        line-height: 1;

        @media (max-width: $media-xs) {
          font-size: $h3-mobile;
        }
      }

      .quiz-subtitle {
        text-align: center;
        color: $white-wine-text;
        margin-top: 16px;
        margin-bottom: 0;
        padding: 0;
        font-size: $subtitle;
      }

      @media (max-width: $media-xs) {
        height: auto;
        margin-bottom: 0;
      }
    }

    .quiz-items-list {
      width: 100%;
      max-width: 875px;
      @include flexLayout(center, center);
      gap: 16px;
      margin-top: 24px;
      flex-wrap: wrap;
      font-size: $subtitle;

      .quiz-item {
        @include flexLayout(center, center);
        position: relative;
        text-align: center;
        gap: 8px;
        width: 280px;
        // width: 30%;
        height: 88px;
        // margin-top: 24px;
        border: 2px solid $alabaster;
        border-radius: 8px;
        background-color: $white;
        cursor: pointer;
        transition: background-color 0.6s ease-in-out;

        &:hover {
          background-color: $alabaster;
        }

        &.active {
          border: 2px solid $white-wine;
          background-color: $alabaster;

          &::after {
            content: url("../../assets/icons/quiz-checked.svg");
            position: absolute;
            display: inline-block;
            top: 12px;
            left: 12px;
          }
        }

        .quiz-icon {
          // width: 64px;
          height: 44px;

          @media (max-width: $media-xs) {
            width: 32px;
            height: 32px;
          }
        }

        @media (max-width: $media-xs) {
          height: 64px;
          width: 100%;
        }
      }

      &.streched {
        max-width: 1120px;
        width: 100%;

        .quiz-item {
          // width: 100%;
          width: 335px;
          flex-direction: row;

          @media (max-width: $media-xs) {
            height: 70px;
          }
        }

        .quiz-icon {
          width: 40px;
          height: 40px;

          @media (max-width: $media-xs) {
            width: 32px;
            height: 32px;
          }
        }
      }

      &.vertical {
        gap: 32px;
        width: 100%;
        justify-content: center;
        text-align: center;

        .quiz-item {
          padding: 48px 20px;
          width: 45%;
          flex-grow: 1;
          height: 390px;
        }

        .quiz-icon {
          width: 100px;
          height: 100px;
          margin: 48px 0;
        }

        @media (max-width: $media-xs) {
          gap: 18px;
          
          .quiz-item {
            padding: 16px;
            height: 130px;
          }

          .quiz-icon {
            width: 40px;
            height: 40px;
            margin: 8px 0;
          }
        }
      }

      @media (max-width: $media-xs) {
        width: 100%;
        gap: 8px;
      }
    }

    @media (max-width: $media-xs) {
      font-size: 14px;
      width: 100%;
    }
  }

  .quiz-control {
    @include flexLayout(center, center);
    gap: 48px;
    margin-top: 44px;

    // .button {
    //   padding: 12px 40px;
    //   width: auto;
    //   font-size: 24px;

    //   @media (max-width: $media-xs) {
    //     font-size: 18px;
    //     padding: 8px 24px;
    //     // width: 115px;
    //     height: 38px;
    //   }
    // }

    .button-link {
      width: auto;
      padding: 0;
      font-size: 24px;

      @media (max-width: $media-xs) {
        font-size: 20px;
      }
    }

    @media (max-width: $media-xs) {
      gap: 20px;
      margin: 12px auto;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      padding: 12px 48px;
    }
  }

  @media (max-width: $media-xs) {
    padding: 8px 16px;
    gap: 0 16px;
  }
}

.quiz-loading {
  @include flexLayout(center, center, column);
  padding: 60px 20px;

  img {
    width: 120px;
    height: 120px;

    @media (prefers-reduced-motion: no-preference) {
      animation: spin infinite 2s linear;
    }
  }

  p {
    font-size: 40px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.quiz-init {
  position: relative;
  @include flexLayout(center, center, column);
  padding: 75px 0;
  width: 100%;
  max-width: 600px;
  margin: auto;

  img {
    max-width: 100%;
  }

  &__text {
    font-family: $quincy;
    font-size: $h3;
    font-weight: 500;
    text-align: center;
    line-height: 1;
    margin-bottom: 48px 0;
  }

  .button { 
    width: 100%;
  }

  .button-link {
    padding: 8px 16px;
    margin-top: 48px;
  }
}
