@import '../../theme/variables';


.case-price-details-layout {
  width: 90vw;
  max-width: 900px;

  h3 {
    font-size: 36px;
    background-color: rgba($alert-info-text, .15);
    margin: 0 32px;
    font-weight: 600;
    text-align: center;
    color: $text-base-color;
  }
}

.ai-description-form {

  button {
    border-radius: 4px;
    height: 48px;

    &.generate {
      height: 40px;

      &:not(:disabled) {
        background-color: $white-wine;
      }
    }

    .pending {
      position: absolute;
      left: -32px;
    }
  }
}

.curator-followers {
  &__title {
    font-size: 26px;
    font-weight: 600;
  }

  &__dialog {
    min-width: 300px;
    min-height: 200px;

    .followers-list {
      padding: 12px 0;

      &__item {
        &.title {
          font-size: 20px;
          font-weight: 600;
          border-bottom: 1px solid $disabled-color;
          margin-bottom: 4px;
        }
      }
    }

    .followers-list + .followers-list {
      margin-top: 16px;
    }
  }
}
