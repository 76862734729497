@font-face {
  font-family: "QuincyCF";
  font-style: normal;
  font-weight: 400;
  src: 
    url("./assets/fonts/quincycf-regular-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "QuincyCF";
  font-style: normal;
  font-weight: 500;
  src: 
    url("./assets/fonts/quincycf-medium-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "QuincyCF";
  font-style: normal;
  font-weight: 700;
  src: 
    url("./assets/fonts/quincycf-bold-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: normal;
  src: 
    url("./assets/fonts/Avenir-Light.woff") format("woff"), 
    url("./assets/fonts/Avenir-Medium.woff") format("woff"),
    url("./assets/fonts/Avenir-Heavy.woff") format("woff");
  font-display: swap;
}

@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=f0a6172a-2491-4ef4-8943-426a817d018d");

@font-face {
  font-family: "Avenir Next";
  font-display: swap;
  src: 
    url("./assets/fonts/9b2ae141-1cc8-4f32-9624-a6a19301931e.woff2") format("woff2"),
    url("./assets/fonts/2726c0be-0c24-4d85-853c-f1c176320d3a.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next";
  font-display: swap;
  src: 
    url("./assets/fonts/e9820a90-ba4f-4321-b57f-5099b5ed07f2.woff2") format("woff2"),
    url("./assets/fonts/60e44a4f-83cd-4772-bc55-cf8af1fefb50.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next";
  font-display: swap;
  src: 
    url("./assets/fonts/a564f40f-09a5-4634-a3fb-9187b0302873.woff2") format("woff2"),
    url("./assets/fonts/cb0d72c8-044e-4473-8044-50c2b72fab7b.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next";
  font-display: swap;
  src: 
    url("./assets/fonts/fbbdf0ff-06ca-4d57-8e6a-23604cf701c1.woff2") format("woff2"),
    url("./assets/fonts/2cbe954b-2c0b-4243-8363-81e3fba39528.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next";
  font-display: swap;
  src: 
    url("./assets/fonts/2b682515-3007-4a6a-ab2d-c71c60de5bb2.woff2") format("woff2"),
    url("./assets/fonts/f06485fd-2016-4741-ab1f-b251a3d9d595.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next";
  font-display: swap;
  src: 
    url("./assets/fonts/73f7fb39-dfbe-44dd-906d-f04e84254085.woff2") format("woff2"),
    url("./assets/fonts/3f14efd2-562a-493a-8891-3b64ab25cc67.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next";
  font-display: swap;
  src: 
    url("./assets/fonts/fa19b046-0e2e-4c4d-837b-93673a6cc05f.woff2") format("woff2"),
    url("./assets/fonts/5347721c-df5f-4fb1-bb82-195fb28640e1.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
