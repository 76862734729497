@import '../../theme/variables';


.tasting-wines-block {
    margin: 0 0 100px;
    padding: 0 16px;
    text-align: center;

    &__header {
        padding: 0 16px;
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
            font-size: 32px;
            margin: 0;
        }

        @media (max-width: $media-xs) {
            flex-direction: column;
            align-items: flex-start;

            p { margin: 0; }
        }
    }

    .tasting-wines-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -16px;

        .tasting-wine-card-container {
            padding: 16px;
            width: 33.333333%;
        
            .card.tasting-wine-card {
                text-align: left;
        
                .card__body {
                    padding: 0;

                    .tasting-wine__video-preview {
                        position: relative;
                        height: 220px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            height: 100%;
                        }

                        .tasting-wine__name {
                            position: absolute;
                            display: flex;
                            text-align: left;
                            flex-direction: column;
                            justify-content: flex-end;
                            bottom: 0;
                            width: 100%;
                            height: 100%;
                            color: $white;
                            margin: 0;
                            padding: 16px 24px;
                            font-size: 18px;
                            line-height: 1.5;
                            font-weight: 800;
                            background-image: linear-gradient(#f5f6fc00, #00000059 85%, #000000b3 100%);
                        }

                        .tasting-wine__alert {
                            position: absolute;
                            height: 32px;
                            background-color: rgba($primary-brand-color, 0.6);
                            top: 50%;
                            left: 0;
                            width: 100%;
                            transform: translateY(-50%);

                            p {
                                margin: 0;
                                font-weight: 800;
                                color: $white;
                                text-align: center;
                                line-height: 32px;
                            }
                        }
                    }

                    .tasting-wine__content {
                        padding: 24px;

                        .wine-grape {
                            padding: 24px 0;
                            display: flex;
                            align-items: center;
                            height: 86px;
                        }

                        .wine-tastes {
                            list-style: disc;
                            padding: 5px 15px;
                            display: flex;
                            gap: 26px;
                        }
                    }
                }
                
            }

            @media (max-width: $media-sm) {
                width: 50%;
            }

            @media (max-width: $media-xs) {
                width: 100%;
            }
        }
    }

    .button-link {
        text-decoration: none;
        font-size: 24px;
        margin-top: 36px;

        &::after {
            content: url('../../assets/icons/chevron-down.svg');
            margin-left: 22px;
        }
    }

    &.expanded {

        .button-link {
    
            &::after {
                content: url('../../assets/icons/chevron-up.svg');
            }
        }
    }

    @media (max-width: $media-xs) {
        padding: 0;
    }
}