@import './theme/variables';

body {
  color: $primary;  
  font-family: $avenir;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.mobile-menu-layout {
    height: 100vh;
    width: 100vw;
    overflow-y: hidden;
  }
}

// * { outline: 1px solid red; }

div, footer, header, section {
  box-sizing: border-box;
}

.w-100 {
  width: 100% !important;
  max-width: 100% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-auto {
  width: auto !important;
  max-width: auto !important;
}

.icon-xs-button {
  width: auto;
  padding: 0 !important;
}

.shadow {
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
}

.fill-green {
  filter: invert(24%) sepia(8%) saturate(3436%) hue-rotate(88deg) brightness(83%) contrast(83%);
}

.fill-blue {
  filter: invert(21%) sepia(89%) saturate(1404%) hue-rotate(194deg) brightness(99%) contrast(81%);
}

.show-xs {
  display: none;

  @media (max-width: $media-xs) {
    display: block;
  }
}

.hidden {
  display: none !important;
}

.subtitle {
  font-size: $subtitle;
}

.container {
  padding: 0px 16px;
  width: 100%;
  margin: auto;
  max-width: 1200px;

  &.wines_container {
    padding: 0;
    margin-bottom: 64px;

    @media (max-width: $media-md) {
      padding: 0 16px;
    }
  }

  &.flex-container {
    display: flex;
    justify-content: center;

    @media (max-width: $media-sm) {
      flex-wrap: wrap;

    }


    .info-container {
      width: 100%;
      min-width: 325px;

      h3 {
        padding: 0;
        margin-bottom: 16px;
      }

      @media (max-width: $media-sm) {
        padding: 0 16px;
      }
    }
  }

  &.with-vertical-offset {
    margin-top: 120px;

    @media (max-width: $media-md) { 
      margin: 0;
    }
  }

  &.container_with-sidebar {
    max-width: 100%;
    padding: 0;

    .order-subtitle {
      margin-top: 32px;
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 600;
      // color: $text-base-color;
      padding: 0;
      line-height: 1.5;
    }

    .section {
      display: flex;
      justify-content: space-between;
      padding: 0px;

      .section__part {
        flex-basis: 70%;
        max-width: 1024px;
        padding: 0 80px 0 20px;
        flex-grow: 1;
        

        @media (max-width: $media-md) { 
          flex-basis: 100%;
          max-width: 100%;
          padding-right: 80px;
        }

        @media (max-width: $media-sm) { 

          &:first-child {
            padding: 0px 24px;
          }
        }
      }


      .section__part + .section__part {
        flex-basis: 30%;
        padding: 0px;
        margin: 0;
        max-width: 520px;


        @media (max-width: $media-md) { 
          flex-basis: 100%;
          max-width: 360px;

          .order-sidebar {
            padding: 56px 32px;
          }
        }

        @media (max-width: $media-xs) {
          display: none;
        }
      }


      h2 {
        margin-top: 32px;
        margin-bottom: 16px;
        font-size: $h3;
      }


      h3 {
        margin-top: 32px;
        margin-bottom: 16px;
      }

      @media (max-width: $media-md) {
        align-items: stretch;
        margin: 0;
        flex-direction: row;
      }
    }
  }

  @media (max-width: $media-sm) { padding: 0 16px; }
}

.sticky { 
  z-index: 100;
  width: 100% !important;
  left: 0;
  right: 0;
}

.welcome-container {
  max-width: 776px;
  width: 100%;

  .infographics {
    max-width: 100%;

    &__text {
      font-size: 20px;
      white-space: pre-line;
      text-align: center;

      @media (max-width: $media-sm) {
        font-size: 12px;
      }
    }

    .icon-wrapper_circle {
      width: 80px;
      height: 80px;
      padding: 16px;

      @media (max-width: $media-sm) {
        width: 64px;
        height: 64px;        
      }
    }

    @media (max-width: $media-sm) {

      .infographics__container {
        padding: 4px;
      }
    }

    @media (max-width: $media-xs) {
      flex-direction: row;
      width: 100%;
    }
  }

  .slick-slide + .slick-slide { margin: 0; }

  @media (max-width: $media-md) {

    .section_divided {
      flex-direction: row;
      justify-content: space-between;

      .section__part {
        width: 50%;
      }
    }
  }

  @media (max-width: $media-sm) {

    .section_divided {
      flex-direction: column;
      justify-content: initial;
    }
  }

  @media (max-width: $media-sm) { 
    padding: 0 32px;
    margin-top: 0;
  }

  @media (max-width: $media-xs) {
    padding: 0 16px;

    .infographics {

      &__container {
        width: 100%;
      }
    }

    .section_divided {
      
      .section__part { width: 100%; }
    } 
  }
}

.small-container {
  max-width: 535px;
}

a { 
  text-decoration: none;
  color: $primary-brand-color;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
}

.go-back-link {
  display: inline-flex;
  align-items: center;
  padding: 16px;

  img {
    margin-right: 16px;
  }
}

.manager-nav-wrapper {
  @media (max-width: $media-xs) {
    background-color: whitesmoke;
    

      [class$="-singleValue"], [class$="-option"] {
        display: flex !important;
        align-items: center;
        gap: 8px;
      }

      [class$="-menu"] {
        z-index: 100;
      }
    }
}

.manager-nav-title {
  font-size: $subtitle;
  margin: 0;
  padding-top: 32px;

  & + .select-wrapper {
    padding-bottom: 32px;
  }
}

strong {
  font-family: $avenir;
  font-weight: 800; 
  font-size: '20px';
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.hr {
  height: 1px;
  display: inline-block;
  width: 100%;
  margin: 8px 0;
  border-top: 1px solid;
}

.flex-col {
  flex-direction: column;
}

.flex-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.flex-space-between {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-space-around {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex-self-start {
  align-self: flex-start;
}

.inline-flex-column {
  display: inline-flex;
  flex-direction: column;
}

.inline-block { display: inline-block; }

.gap {
  gap: 32px;

  &_lg { gap: 64px !important; }
  &_sm { gap: 16px !important; }
  &_xs { gap: 8px !important; }
}

.no-margin {
  margin: 0px !important;
}

.no-padding {
  padding: 0px !important;
}

.full-width {
  width: 100%;
}

.no-decoration {
  text-decoration: none !important;
}

.margin-auto {
  margin: auto;
}

h1 {
  // font-size: 50px;
  font-family: $quincy;
  color: $primary;
  font-size: 84px;
  font-weight: 500;
  line-height: 1;
  margin-top: 64px;
  margin-bottom: 32px;

  @media (max-width: $media-xs) {
    font-size: $h1-mobile;
  }
}

h2 { 
  font-family: $quincy;
  color: $primary;
  font-weight: 500;
  // font-size: 32px;
  font-size: 60px;
  letter-spacing: 0.01em; 
  line-height: 1; 

  @media (max-width: $media-xs) {
    font-size: $h2-mobile;
  }
}

h3 {
  // font-size: 20px;
  font-family: $quincy;
  color: $primary;
  font-size: 44px;
  font-weight: 500;
  padding: 16px 0px;
  margin: 0px;
  line-height: 1;

  @media (max-width: $media-xs) {
    font-size: $h3-mobile;
  }
}

h4 {
  font-size: 32px;
  font-family: $quincy;
  color: $primary;
  font-weight: 700;

  @media (max-width: $media-xs) {
    font-size: $h4-mobile;
  }
}

h5 {
  font-size: 24px;
  font-family: $quincy;
  color: $primary;
  font-weight: 500;

  @media (max-width: $media-xs) {
    font-size: $h5-mobile;
  }
}

ul {
  list-style: none;
  padding: 0px;
  margin: 0px;

  &.horizontal_list {
    display: flex;
    align-items: center;

    @media (max-width: $media-xs) { flex-wrap: wrap; }

    li:not(:first-child) {
      margin-left: 20px;
    }
  }

  .space { margin: 16px 0; }
}

.subtotal-info {

  li {
    display: flex;
    padding: 16px 0px;
    justify-content: space-between;

    .name { flex: 30% 0 0; }
    .value { flex: 50% 0 0; }
    .link { flex: 20% 0 0; }
  }

  li + li { border-top: 1px solid $disabled-color }
}

.text {

  &-bold {
    font-weight: bold !important;
  }

  &-underline {
    text-decoration: underline !important;
  }

  &-white {
    color: $white !important;
  }

  &-black {
    color: $secondary !important
  }

  &-gray {
    color: $disabled-color !important;
  }

  &-gold {
    color: $icon-color !important;
  }

  &-accent {
    color: $accent !important;
  }

  &-success {
    color: $bg-card-color !important;
  }

  &-green {
    color: $primary-brand-color !important;
  }

  &-error {
    color: $error-color !important;
  }

  &-blue {
    color: $alert-color;
  }

  &-primary {
    color: $text-base-color;
  }

  &-lg {
    font-size: 18px !important;
    line-height: 1.5 !important;
  }

  &-xl {
    font-size: 24px !important;
    line-height: 1.5 !important;
  }

  &-sm {
    font-size: 14px !important;
    line-height: 1.2 !important;
  }

  &-shadow {
    text-shadow: $textShadow;
  }

  &-center { text-align: center; }

  &-left { text-align: left !important; }

  &-right { text-align: right !important; }  
}

button { 
  cursor: pointer;

  &:disabled {
    pointer-events: none;
  }

  &.disable-hidden-button {
    pointer-events: none !important;
    opacity: 0 !important;
    cursor: none;
  }

  &.button-link {
    padding: 10px 16px;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    color: $primary-brand-color;
    text-decoration: underline;

    &.button_error {
      color: $error-color;
    }

    &:disabled { opacity: .4; }

    &.not-underlined {
      text-decoration: none;
    }
  }

  &.button-sort {
    background-color: transparent;
    @include flexLayout(flex-end, center);
    color: $white;

    &::after {
      content: url('./assets/icons/arrows.svg');
      filter: brightness(12);
      transform: scale(0.7);
      line-height: 0;
      margin-left: 4px;
    }

    &.sort-color-black {
      color: $text-base-color;

    &::after {
      filter: brightness(0);
    }
    }
  }

  @media (max-width: $media-xs) { 
    font-size: 14px;
   }
}

.alert-info {
  background-color: $info-light;
  // padding: 16px 36px;
  margin: 24px 0;
  
  .alert__icon { 
    @extend .fill-blue;
    // height: 24px;
  }

  .alert__text, .alert__title { 
    color: $info;

    a {
      color: $info;
      font-weight: 600;
    }
  }

  .alert__title { font-weight: 600; }
}

.alert-success {
  // background-color: rgba($button-green-color, .2);
  background-color: $success-light;
  margin: 24px 0;
  
  .alert__icon {
    height: 24px;
  }

  .alert__text, .alert__title { 
    color: $button-green-color;
    font-size: 16px;

    a {
      color: $button-green-color;
      font-weight: 600;
    }
  }

  .alert__title { 
    font-weight: 600;
    margin: 0;
    padding: 0; 
  }
}

.alert-error {
  // background-color: rgba($error-color, .2);
  background-color: $warning-light;
  padding: 16px 36px;
  margin: 24px 0;
  
  .alert__icon { 
    height: 24px;
  }

  .alert__text, .alert__title { 
    // color: $error-color;
    color: $warning;

    a {
      // color: $error-color;
      color: $warning;
      font-weight: 600;
    }
  }

  .alert__title { font-weight: 600; }
}

.button {
  position: relative;
  @extend .flex-center;
  color: $white;
  background-color: $vineify-green;
  font-weight: 500;
  height: 40px;
  box-sizing: border-box;
  outline: none;
  text-align: center;
  line-height: 1.5;;
  padding: 8px 32px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  transition: box-shadow .4s ease-in-out;

  &.pill { border-radius: 25px; }

  &.login {
    background-color: transparent !important;
    border: 2px solid $white;
    color: $white;
    width: auto;
  }

  &.account {
    background-color: $white !important;
    border: 2px solid $white;
    color: $tertiary !important;
    width: auto;
    padding: 8px 16px;
  }

  &.with_icon {
    padding: 8px 16px;
    gap: 6px;
  }

  &:not(:disabled):hover { box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1); }

  &:focus { 
    background-color: $warm-gray-12;
    box-shadow: none;;
  }

  .button-loading {
    position: absolute;
    right: 60px;
  }

  &:disabled, &.disabled {
    background-color: $warm-gray-6 !important;
    pointer-events: none;
  }

  &.reversed {
    background-color: $white;
    color: $primary-brand-color;
  }

  &.accent {
    background-color: $accent;
    color: $white;

    &:disabled {
      opacity: .5;
    }
  }

  &.primary {
    background-color: $primary;
    color: $white;
  }

  &_error {
    background-color: $warning;
    border-color: $warning;

    &:focus {
      background-color: rgba($warning, 1.2);
      border-color: rgba($warning, 1.2);
    }
  }
  

  &--register {
    background-color: $register-button-color !important;
  }

  &_green {
    background-color: $button-green-color;
    border-color: $button-green-color;
  }

  &_red {
    background-color: $warning;
    border-color: $warning;

    &:focus {
      background-color: rgba($warning, 1.2);
      border-color: rgba($warning, 1.2);
    }
  }

  &_gold {
    background-color: $secondary-brand-color;
    border-color: rgba($icon-color, .4);
    color: $icon-color;

    &:focus { 
      background-color: rgba($secondary-brand-color, 1.2);
      border-color: rgba($icon-color, 1.2);
    }
  }

  &_info {
    background-color: $alert-color;
    border-color: rgba($alert-color, 1.2);

    &:focus { 
      background-color: rgba($alert-color, 1.4);
      border-color: rgba($alert-color, 1.6);
    }
  }

  &_outline {
    color: $primary-brand-color;
    // background-color: transparent !important;
    background-color: $white !important;
    box-shadow: none;
    border: 2px solid $primary-brand-color;

    &.light {
      color: $white;
      border-color: $white;
    }

    &.red {
      color: $warning;
      border-color: $warning;
    }

    &.reversed {
      background-color: transparent;
      border-color: white;
      min-width: auto;
      padding: 8px;

      &:disabled {
        opacity: .6;
      }
    }

    &:hover:not(:disabled) { filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.1)); }

    &:focus {
      color: $warm-gray-12;
      border-color: $warm-gray-12;
    }

    &:disabled {
      color: $warm-gray-3;
      border-color: $warm-gray-3;
    }

    &.reversed {
      color: $white;
      border-color: $white;
      background-color: transparent !important;
    }
  }

  &_sm { width: 194px; }

  &_xs {
    height: 30px;
    font-size: 14px;
    padding: 6px 18px;
    width: auto;
    min-width: 115px;
  }

  &_lg { 
    width: 394px; 
    padding: 12px 24px;

    @media (max-width: $media-xs) {
      width: auto;
    }
  }

  &_secondary {
    background-color: $secondary-brand-color;
    color: $primary-brand-color;
    border-color: rgba($primary-brand-color, 1.2);

    &:focus { 
      background-color: rgba($secondary-brand-color, 1.2);
      border-color: transparent;
    }
  }

  &_light {
    background-color: $white;
    color: $primary-brand-color;
    border-color: transparent;

    &:focus { 
      background-color: rgba(white, 1.2);
      border-color: transparent;
    }
  }

  &_request-pending {
    filter: brightness(10);
    height: 32px;
  }

  .button-float-content {
    position: absolute;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    right: 0;
    transform: translateX(calc(100% + 12px));

    &.left {
      left: 0;
      right: auto;
      transform: translateX(calc(-100% - 12px));
    }

    &.bottom {
      bottom: 0;
      transform: translateY(calc(100% + 6px));
    }
  }

}

.button-container {
  padding: 50px 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  &--column {
    padding: 25px;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;

    .button {
      width: 100%;
    }

    .button + .button {
      margin: 0;
    }
  }
  

  &.small {
    padding: 0px;
  }

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  .checkbox-wrapper { 
    margin: 0px;
    
    .checkmark {
      transform: none;
    }
  }

  button + .checkbox-wrapper {
    margin-left: 25px;
  }

  &.button_aside {
    width: 100%;
    justify-content: space-between;

    button + button {

      img { margin-left: 16px; }
    }
  }

  &.button_full-size {
    padding-left: 0px;
    padding-right: 0px;

    .button { width: 100%; flex-grow: 1; }
  }

  .button + .button {
    margin-left: 10px;
  }

  &.check-age-buttons {
    gap: 16px;
  
    .button { flex-grow: 1; }
  
    @media (max-width: $media-sm) {
      flex-direction: row;
      width: 90vw;
    }
  
    @media (max-width: $media-xs) {
      flex-direction: column-reverse;
      width: 100vw;
  
      .button {
        width: 100%;
        margin: 0 !important;
      }
    }
  }

  @media (max-width: $media-sm) { flex-direction: column-reverse; }
}

.form-control {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 16px;

  &.horizontal {
    flex-direction: row;
    align-items: center;

    label {
      margin-bottom: 0;
      margin-right: 6px;
    }
  }

  &.gift-message {
    margin-top: 16px;
  }

  .input-prefix-info {
    margin: 0;
  }

  &.paypal-control {
    max-width: 300px;
    margin: 25px auto;
  }

  &.control-link {
    align-self: center;
    margin-bottom: -15px;
    width: auto !important;
  }

  &.margin-lg {
    margin-bottom: 36px;
  }

  &.control-button {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    justify-content: flex-start;
    padding-top: 10px;

    .button-link { 
      text-decoration: underline;
      padding: 16px 10px;
    }

    .button-link + .button-link { margin-left: 15px; }
  }


  & label {
    // padding: 5px 10px;
    line-height: 1;
    font-weight: bold;
    letter-spacing: .01;
    margin-bottom: 8px;
  }
  
  & input, & select, & textarea, & .StripeElement {
    // background: rgba($secondary-brand-color, .25);
    background: $white;
    border: 2px solid $quarternary;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1;
    outline: none;
    font-weight: 500;
    height: 40px;
    // border-radius: 100px;
    border-radius: 8px;
    padding: 8px 16px;

    &::placeholder, &::placeholder, &::placeholder {
      color: $quarternary;
      font-size: initial !important;
    }

    &:-internal-autofill {
      background-color: $white !important;
      -webkit-box-shadow: 0 0 0 1000px $white inset !important;
      box-shadow: 0 0 0 1000px $white inset !important;
    }

    &:-webkit-autofill {
      &, &:hover, &:active, &:focus {
        background-color: $white !important;
        -webkit-box-shadow: 0 0 0 1000px $white inset !important;
        box-shadow: 0 0 0 1000px $white inset !important;
      }
    }
  
    &:disabled, &:disabled, &:disabled {
      // background-color: rgba($disabled-color, .2);
      // border-color: rgba($disabled-color, .2);
      background-color: $alabaster-50;
      border-color: $alabaster-50;
    }
  }

  // & textarea { 
  //   border-radius: 10px;
  //   height: auto;
  // }
  & textarea { height: initial; }

  &.invalid { 
    border-color: $warning;
    
    & input, & textarea, .StripeElement { 
      border-color: $warning;
    };

    & label { color: $warning; }

    small, a, span { color: $warning }
  }

  & + button {
    margin-top: 30px;
    width: 100%;
  }

  .with-checkbox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .checkbox-wrapper {
      margin: 0;
      padding: 0px;
      padding-left: 30px;

      .checkmark { transform: translateY(-15%); }
    }
  }

  .error-msg { 
    padding: 0px 16px; 
    font-size: 14px;
    margin: 8px 0 0 0;
    color: $warning;

    &.error-absolute {
      position: absolute;
      display: flex;
      align-items: center;
      width: max-content;
      bottom: -25px;

      a { margin-left: 5px; }
    }
  }

  .success-msg {
    padding: 0px 10px; 
    font-size: 16px;
    font-weight: 500;
    color: $primary-brand-color;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 0;
  }

  .input-sm { height: 40px; }

  .select-wrapper {
    
    input { height: 30px; }
  }

  &_button {
    margin-top: 32px;
    justify-content: center;
    display: flex;
  }
}

.input-group {
  &_password {
    position: relative;

    input {
      width: 100%;
      padding-right: 46px;
    }

    .button-link {
      position: absolute;
      right: 0;
      height: 40px;
    }
  }

  &__icon-wrapper {
    position: absolute;
    right: 18px;
    top: 46px;
    transform: translateY(-50%);

    svg { cursor: help; }
  }
}

.img {

  &_rounded {
    border-radius: 10px;
  }

  &_circle { border-radius: 50%; }

  &_responsive {
    max-width: 100%;
    max-height: 100%;
  }
}

.wine-space-between {
  @include flexLayout(center, space-between, row);

  .wine-grape {
    margin-top: 16px;
    font-weight: 500;
    @include flexLayout(center, flex-start, row);
  }
}

.wine-additional-data {
  margin-top: 16px;

  .wine-tastes {
      list-style: disc;
      padding: 0 16px;
      margin-top: 16px;
  }
}

.section {
  position: relative;
  width: 100%;
  overflow: hidden;


  &_centered {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.wait-list-main-page-section {
    // min-height: 750px;
    min-height: calc(100vh - 242px);

    @media (max-width: $media-sm) {
      height: auto;
      min-height: 420px;

      .error-msg-block>.up {
        margin-top: 15px;
      }
    }
  }

  &_divided {
    display: flex;
    justify-content: space-between;

    @media (max-width: $media-md) { 
      flex-direction: column; 
      justify-content: initial;
      text-align: center;
    }
  }

  &_with-sidebar {
    display: flex;
    justify-content: space-between;
    margin-right: -160px;
    padding: 0px;

    @media (max-width: $media-md) { 
      margin: 0;
      flex-direction: column-reverse;
    }

    .section__part {
      flex-basis: 70%;

      @media (max-width: $media-md) { 
        flex-basis: 100%;
        max-width: 100%;
      }

      @media (max-width: $media-sm) { 
        padding: 0px 10px;
      }
    }

    .section__part + .section__part {
      flex-basis: 30%;
      padding: 0px;
    }

    h2 {
      margin-top: 32px;
      margin-bottom: 16px;
      font-size: $h3;
    }

    h3 {
      margin-top: 32px;
      margin-bottom: 16px;
    }
  }

  &.curator_section {
    
    .section__header {
      padding: 120px 0;
    }

    @media (max-width: $media-xs) {
      overflow: visible;

      .section__header {
        padding: 32px 0;

        .flex-column + .flex-column { 
          height: auto;
          
          img {
            width: 215px;
            right: - 16px;
            transform: translateY(-15%);
          }
        }

        .header-text-block {

          .co-founders__signature {
            width: 40%;
          }
        }
      }
    }
  }

  &.terms_section {

    ul {
      padding-left: 32px;
      margin: 16px 0;
      list-style: disc;
    }
  }

  &.section_recommendation {
    padding: 24px 0;

    .section__subtitle {
      font-size: 40px;
      line-height: 1.5;
      margin-bottom: 8px;
    }

    .section__subtitle + p {
      font-size: 24px;
      line-height: 1.5;
      color: $disabled-color;
    }

    .section__content > .alert.alert-info {
      .alert__text, .alert__title {
        color: $primary;

        a { color: inherit; }
      }
    }

    .repeat-button {
      background-color: $icon-color;
      border-color: $icon-color;
      border-radius: 4px;
  
      img { margin-right: 8px; }
    }
  
    .case__details {
      margin-top: 32px;
  
      .case__controls {
        @include flexLayout(center, space-between, row);
        margin-bottom: 32px;
  
        .purchased-label {
          line-height: 99%;
          padding: 12px 20px;
          background-color: $icon-color;
          color: $white;
          @include flexLayout(center, space-between, row, inline-flex);
          border-radius: 8px;
        
          img { margin-right: 4px; height: 13px; margin-bottom: -1px; }
        
          @media (max-width: $media-sm) {
            margin: 0;
            transform: none;
          }
  
          @media (max-width: $media-xs) {
            order: 2;
          }
        }
  
        @media (max-width: $media-xs) {
          flex-direction: column;
          margin-bottom: 16px;
        }
      }
  
      .price-section {
        @include flexLayout(flex-end, space-between);
        padding: 32px 0;
        border-bottom: 1px solid $disabled-color;
  
        @media (max-width: $media-xs) {
          flex-direction: column;
          align-items: center;
          margin: 0;
  
          .case-price {
            margin: 0;
            text-align: center;
          }
        }
      }
  
      &-header {
        @include flexLayout(center, space-between, row);
        margin-bottom: 16px;
  
        @media (max-width: $media-sm) {
          flex-direction: column-reverse !important;
        }
  
        @media (max-width: $media-xs) {
          margin-bottom: 8px;
          
          .recommendation-case-title {
            text-align: left;
            font-size: 24px;
            margin-bottom: 0;
            margin-top: 32px;
          }
        }
        
      }
  
      .case-description { 
        // font-size: 24px;
        font-size: $subtitle;
  
        p { margin: 0; }
        
        @media (max-width: $media-xs) {
          font-size: 14px;
          text-align: center;
        }
      }

      .price-section {
        font-size: $subtitle;
      }
  
      .details__wines {
        margin-top: 0;
        margin-bottom: 32px;
  
        .wines-list {
          display: flex;
          flex-wrap: wrap;
          margin: 25px 0 0;
          gap: 16px;
  
          .wine-card-wrapper {
            padding: 0;
            flex: 30%;
            max-width: calc(33.33333% - 12px);
            display: flex;
            flex-direction: column;
  
            .wine_card.wine_recommendation {
              height: 100%;
  
              .card-header {
  
                img { height: 100%; }
              }
            }
          }
  
          @media (max-width: $media-sm) {
            display: flex;
  
            .wine-card-wrapper {
              max-width: calc(50% - 16px);
              flex-basis: 40%;
              // padding: 8px;
    
              .wine_card.wine_recommendation {
                width: 100%;
  
                .card-header {
  
                  img { height: 100%; }
                }
              }
            }
          }
  
          @media (max-width: $media-xs) {
  
            .wine-card-wrapper {
              max-width: 100%;
              flex-basis: 100%;
            }
          }
        }
      }
  
      &-subheader {
        @include flexLayout(center, space-between, row);
        margin-top: 24px;
  
        .case-content-caption + .flex-space-between {
  
          .button_outline { width: auto; margin-right: 16px; }
  
          .button + button {
            max-width: 250px;
          }
        }
  
        & > .flex-space-between { width: 100%; }
  
        @media (max-width: $media-sm) {
          flex-direction: column-reverse;
          margin-top: 32px; 
        }
  
        @media (max-width: $media-xs) {
          margin-bottom: 24px;
        }
      }
  
      @media (max-width: $media-xs) {
        margin-top: 32px;
        padding: 0;
      }
    }
  
    & + .section_recommendation__footer {
      position: sticky;
      z-index: 35;
      // width: 100vw;
      bottom: 0;
      right: 0;
      left: 0;
      @include flexLayout(center, space-between, row);
      padding: 24px 0;
      background-color: $white;
      box-shadow: 0px -5px 36px rgba(0, 0, 0, 0.1);

      .flex-space-between { width: 100%; }
    
      .case-details__tools {
        width: 100%;
        display: flex;
        align-items: center;
    
        .price {
            font-size: $h5;
            line-height: 1;
            font-weight: 700;
            width: 90px;
            margin: 0;
            padding: 0 16px;
            border: none;
        }
    
        // .button { width: 200px; font-size: 16px; }
  
        @media (max-width: $media-xs) {
  
          .price { 
            font-size: 24px;
            display: none;
          }
  
          .button {
            width: 100%;
            margin-top: 8px;
          }
        }
      }
  
      @media (max-width: $media-sm) {
  
        .flex-space-between {
          flex-direction: column;
  
          .wines-summary { margin-bottom: 16px; }
        }
      }
  
      @media (max-width: $media-xs) {
        padding: 16px 0;
  
        .flex-space-between {
  
          .wines-summary {
            position: relative; 
            width: 100%;
            margin-bottom: 0;
            flex-wrap: wrap;
            justify-content: space-between;
  
            .wine-badge {
              width: calc(50% - 4px);
              margin-bottom: 8px;
            }
  
            .wine-badge + .wine-badge { margin-left: 0; }
          }
        }
      }
    }
  
    @media (max-width: $media-xs) {
  
      .flex-space-between {
        flex-direction: column;
      }
    }
  }

  &__subtitle {
    color: $text-base-color;
    font-weight: bold;
    margin-top: 32px;
    margin-bottom: 16px;

    @media (max-width: $media-xs) { font-size: 30px !important; }
  }

  &__description {
    font-size: 24px;

    @media (max-width: $media-xs) { font-size: 18px; }
  }

  &__part {
    box-sizing: border-box;
    padding: 0px 20px;
    width: 50%;
    max-width: 720px;

    &.main-forms_part {
      width: 45%;
      padding-top: 8px; 
      padding-bottom: 8px;
      display: flex;
      flex-direction: column;

      & + .section__part { flex-grow: 1; }

      p { padding: 0; }

      form {
        flex-grow: 1;
        margin: 32px 0 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        
        &.register-form, &.verification-form {
          margin-top: 0;

          input { height: 40px }

          .form-link {
            text-align: center;

            .button-link { padding: 0; }
          }
        }

        .form__header {
          margin-bottom: 24px;

          .form__title {
            font-weight: 800;
            text-align: center;
            color: $text-base-color;
            margin: 0;
            padding: 0;
            font-size: 24px;
          }

          .form__subtitle {
            font-size: 16px;
            color: $disabled-color;
            margin: 8px 0 0;
            text-align: center;
          }
        }

        &.verification-form {

          .form-control + button { margin: 0; }
        }
      }

      & + .section__part {

        .slick-slide + .slick-slide { margin: 0; }
      }


      @media (max-width: $media-md) { 
        width: 100%;
        order: -1;
      }

      @media (max-width: $media-sm) { 
        width: 100%;
      }

      @media (max-width: $media-xs) { 
        padding-bottom: 38px;
      }
    }

    &.flex-column {
      position: relative;
      display: flex;
      flex-direction: column;          
    }

    &.dinamic {
      width: fit-content;

      @media (max-width: $media-md) { width: 100%; }
    }

    &.region-info {
      flex-grow: 1;
      max-width: none;
    }

    @media (max-width: $media-md) { 
      width: 100%;
    }

    @media (max-width: $media-sm) { 
      padding: 0px;

      &.main-forms_part {
        width: 100%;
      }
    }
  }

  &-button {
    margin: 10px 0px;
  }

  &__header {
    // padding: 32px 0;
    padding: 80px 0;
    background-color: $alabaster;
    font-size: 18px;
    min-height: 190px;
    width: 100%;

    h2 {
      font-size: $h3;
      line-height: 1;
      font-weight: bold;
      padding: 0;
      margin-top: 0;
      margin-bottom: 16px;

      @media (max-width: $media-sm) { font-size: 20px; }
    }

    h3 {
      // font-family: $quincy;
      // color: $text-base-color;
      font-size: $h4;
      line-height: 1;
      font-weight: bold;
      padding: 0;
      margin-top: 0;
      margin-bottom: 16px;
    }

    p { 
      margin-top: 0; 
      margin-bottom: 32px;
      color: $secondary;

      @media (max-width: $media-sm) { font-size: 16px; }

      @media (max-width: $media-xs) { margin-bottom: 0; }
    }

    .co-founders__signature {
      margin: 0;
      color: $icon-color;
    }

    .header-media-block {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;

      img {
        max-width: 100%;
        position: absolute;
        clip-path: ellipse(48% 50% at 50% 50%);
      }
    }

    @media (max-width: $media-xs) {
      padding: 32px 0;
      min-height: 10px;
    }
  }

  .header-text-block {

    p { 
      margin-bottom: 32px;      
    }

    @media (max-width: $media-xs) {
      
      p {
        margin-bottom: 16px;
        font-size: 14px;
      }

      h3 {
        font-size: 16px;
      }
    }
  }

  &__title {
    margin-bottom: 50px;

    h1 { max-width: 650px; }

    h2 {
      font-family: $quincy;
      font-size: 45px;
      font-weight: bold;
      line-height: 1.15;
      margin: 0px;
      
      & + h3 {
        margin-top: 15px;
      }
    }

    &.login_title {
      margin: 32px 0;

      @media (max-width: $media-xs) {
        margin-bottom: 0;

        h1 { margin: 24px 0 }
      }
    }

    @media (max-width: $media-md) {
      text-align: center;
    }
  }

  .section__content {
    padding: 120px 0px;
    width: 100%;

    .container > h2 {
      font-size: 42px;
    }

    .infographics-title {
      font-family: $avenir;
      font-size: 20px;
      text-align: center;
      margin-top: 0;
    }

    &.content_compact {
      padding-top: 0;
      
      .container {
        margin-top: -32px; 

        .flex-row { margin: 0 -16px; }

        .info-container {
          padding-top: 64px;
        }

        @media (max-width: $media-md) {
          margin-top: 32px;

          .info-container {
            padding-top: 0;
          }
        }
      }      
    }

    &.content_streched { width: 100%; }

    &.quiz_content {
      padding-top: 32px;
    }

    .container > h2 { 
      font-size: 42px;
      
      &.h2-title { font-size: $h2; }
    }

    @media (max-width: $media-sm) {
      padding: 60px 0;
    }

    @media (max-width: $media-xs) {
      padding: 20px 0;

      .container > h2 {
        font-size: 36px;
      }
    }
  }

  @media (max-width: $media-md) { 
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: $media-sm) {
        
    &.curator_section {

      .flex-column { 
        width: 100%;

        img {
          max-width: 260px;
          right: 0;
        }


      }
    }

    &#curator {
      
      .media-container {
        order: -1;
        padding: 0 32px
      }
    }
  }

  @media (max-width: $media-sm) {
    &.curator_section {

      .flex-column + .flex-column {
        height: 75px;

        img {
          transform: translateY(15%)
        }
      }
    }
  }
}

.info-block {
  padding: 16px;
  border: 1px solid $disabled-color;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 32px 0;

  h3 {
    font-weight: 600;
    font-size: $subtitle;
    margin: 0 !important;
    padding: 0;
    color: $primary;
  }

  small { 
    font-size: 14px;
    font-weight: 500;
    color: $secondary;
    margin: 12px 0;
    display: inline-block;
  }

  p { 
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    color: $tertiary;
  }
}

.v-margin-xxs {
  margin: 8px 0px !important;
}

.v-margin-xl {
  margin: 64px 0px !important;
}

.small-form {
  width: 100%;
  max-width: 400px;
  margin: 16px auto;
}

form {
  position: relative;

  .error-msg-block {
    display: inline-block;
    position: absolute;
    font-size: 16px;
    line-height: 1.25;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    bottom: 0px;
    width: 100%;
    transform: translateY(100%);
    text-align: center;
    color: $error-color;
    z-index: 50;
    // font-size: 14px;
    // margin-top: 8px;
    // line-height: 1;
    // padding: 0 16px;
    // color: $warning;

    p { margin: 0px; }

    &.up {
        bottom: initial;
        top: 0px;
        transform: translateY(-120%);

        &.lower {
          transform: translateY(-75%);
        }
    }

    &.left {
      text-align: left;
    }

    a { 
      color: $error-color;
      text-decoration: underline;
    }
  }

  &.order_form {
    padding: 16px 0px;

    .button-container {
        justify-content: space-between;
        width: auto;
    }
  }

  &.small-form {
    width: 100%;
    max-width: 400px;
    margin: 16px auto;
  }

  &.form_centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
  }

  &.form_horizontal {
    display: flex;
    flex-wrap: wrap;

    .form-control + button { 
      margin: 0;
      width: auto;
    }

  }

  .form-section {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 16px 0px;

      &.wrap-section {
        flex-wrap: wrap;
        gap: 16px;

        .form-control {
          width: calc(50% - 8px);
        }

        .form-control + .form-control { margin-left: 0; }
      }


      &-title {
        width: 100%;
        padding: 0;
        color: $text-base-color;
        margin-top: 64px;
        margin-bottom: 32px;
        font-weight: bold;
        letter-spacing: 0.01em;
        font-size: $h4;
        font-family: $avenir;
        line-height: 1;

        &.refer-friend-title {
          font-size: 20px;
          line-height: 1;
          color: #D1AD82;
          font-weight: 800;
          display: flex;
          align-items: center;
          margin: 0;

          img { margin-right: 16px; }
        }

        &.label {
          padding: 5px 10px;
          line-height: 1.25;
          font-size: 16px;
          text-align: left;
          margin: 0;
          margin-bottom: -16px;
          justify-content: flex-start;
        }
      }

      .form-control {
        max-width: 375px;
        width: 50%;

        .row {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          margin-bottom: 16px;
        }

        button {
          margin-bottom: 0;
        }
      }

      .form-control + .form-control { margin-left: 15px; }
  }

  .form-column {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;

    &.flex-column {
      text-align: center;
      justify-content: center;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  &.refer-friend-form {
    width: auto;

    @media (max-width: $media-sm) {

      .refer-friend-title {
        width: 100%;
        padding: 0 12px;
      }
      
      .form-section {
        flex-wrap: wrap;

        .form-control {
          width: calc(50% - 8px);
          flex-grow: 1;

          .button { width: 100%; }
        }

      }
    }

    @media (max-width: $media-xs) {
      margin: 0 -16px;

      .form-section {

        .form-control {
          width: 100%;

          .button { margin-top: 16px; }
        }

        .form-control + .form-control { margin: 0; }
      }
    }
  }
}

.radio-button { 
  padding: 4px 8px;

  .radio-flex {
    display: flex;
    flex-direction: column;

    small { font-weight: 500; }
  }
}

.radio-button, .checkbox-wrapper {
  display: block;
  position: relative;
  padding-left: 35px !important;
  margin-top:24px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;


    &:checked ~ .checkcircle:after,
    &:checked ~ .checkmark:after {      
      opacity: 1;
    }
  }

  .checkcircle {
    position: absolute;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 3px solid $primary-brand-color;


    &:after {
      content: "";
      position: absolute;
      transition: opacity .3s ease-in-out;
      opacity: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background-color: $primary-brand-color;
    }
  }

  .checkmark {
    position: absolute;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0px;
    height: 24px;
    width: 24px;
    border-radius: 3px;
    border: 3px solid $primary-brand-color;


    &:after {
      content: "";
      opacity: 0;
      transition: opacity .3s ease-in-out;
      width: 5px;
      height: 10px;
      border: solid $primary-brand-color;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  &.no-padding {
    padding-left: 0 !important;
  }
}

.account-section {

  &__header {
    margin: 54px 0;
    text-align: center;  
  }

  &__title {
    font-size: 90px;
    margin: 10px 0 16px;
  }

  &__subtitle {
    font-size: 18px;
    color: $disabled-color;
    margin: 0;
  }

  @media (max-width: $media-sm) {

    &__header { 
      margin-bottom: 16px;
    }
  }

  @media (max-width: $media-xs) {

    &__header { 
      padding: 0 16px; 
      margin: 32px 0;
    }

    &__title {
      font-size: 64px;
    }
  }
}

.account-content {
  padding-top: 28px;
}

.close-modal-btn {
  position: absolute;
  font-size: 16px;
  line-height: 1;
  background-color: transparent;
  opacity: .3;
  top: 6px;
  right: 10px;
  padding: 5px;
  cursor: pointer;
  transition: opacity .4s ease-out;

  img { 
    filter: invert(0.6);
    height: 24px;
  }

  &.promt-btn {
    opacity: 1;

    img { 
      filter: invert(0);
      height: 40px;

      @media (max-width: $media-xs) { height: 32px; }
    }
  }

  &:hover { opacity: .8; }
}

.flex-row {
  display: flex;
  flex-wrap: wrap;

  &.row_center {
    justify-content: center;
  }

  .flex-column {
    width: 50%;
    padding: 0 16px;

    img { max-width: 100%; }

    @media (max-width: $media-sm) {
      width: 100%;
    }
  }

  .flex-column-fluid {
    width: 100%
  }
}

.header-margin {
  margin-top: 64px;
  margin-bottom: 32px;
  padding: 0 16px;
}

.subregions-cards {
  margin: 0 -16px;

  .subregions-header {

    p { margin-top: 8px; margin-bottom: 0; }
  }
}

.content__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  h3 {
    font-size: 28px;
    line-height: 38px;
    color: $text-base-color;
  }
}

.loading {
  filter: brightness(0) invert(1);
}

.button-group {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  .button {
    width: 100%;
    border-width: 2px;
    background-color: $white;
    color: $primary-brand-color;

    &.active {
      background-color: $primary-brand-color;
      color: $white;
    }

    &:first-child {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    &:last-child {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    .button + .button { margin: 0; }
  }
}

.formatted-spaces {
  white-space: pre-line;
}

.main-slide {
  position: relative;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;

  h3 {
    background-image: linear-gradient(#f5f6fc00, #00000059 85%, #000000b3 100%);
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 20px;
    font-family: $quincy;
    color: $white;
    position: absolute;
    padding: 24px;
    bottom: 0
  }
}


.account-wines-header {
  width: 100%;
  // font-size: 18px;
  line-height: 1;
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 32px;
  margin-bottom: 16px;  
  

  .wine-like {
      border: none !important;
      background-color: transparent;
      outline: none !important;
      // width: 90px;
      width: 145px;
      text-align: left;

      &:disabled {
        color: $disabled-color;
        opacity: .5;
      }

      @media (max-width: $media-md) {
        width: 100px;
      }
  }

  .wine-info {
      flex-grow: 1;
      display: flex;
      gap: 32px;
      align-items: center;

      .wine-vintage {
        padding: 0;
        width: 60px;

        &:disabled {
          color: $disabled-color;
          opacity: .5;
        }
      }
  
      .wine-name {
        flex-grow: 1;
        width: 150px;
        padding: 0;

        &:disabled {
          color: $disabled-color;
          opacity: .5;
        }
      }
  
      .wine-producer {
          width: 125px;
          padding: 0;

          &:disabled {
            color: $disabled-color;
            opacity: .5;
          }

          @media (max-width: $media-md) {
            width: 110px;
          }
      }
  
      .wine-grape {
        width: 125px;
        padding: 0;

          &:disabled {
            color: $disabled-color;
            opacity: .5;
          }
      }      
  }

  .wine-inventory {
      // width: 170px;
      width: 225px;
      padding: 0 16px;
      justify-content: center;

      &:disabled {
        color: $disabled-color;
        opacity: .5;
      }
  }

  button {
    background: transparent;
    border: none;
    outline: none;
    text-align: left;
    display: flex;
    align-items: center;

    img { margin-left: 6px; }
  }
}

.no-wines-notification {
  max-width: 475px;
  margin-top: 64px;
}

.popover-wrapper {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  background-color: $white;
  padding: 16px;
  border-radius: 10px;
}

.popover-arrow {
  // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  // background-color: $white;
  filter:drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15))
}

.popover-container {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  background-color: $white;
  padding: 16px;
  border-radius: 10px;

  .image-container {
    width: 210px;
    height: 245px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 100%;
    }
  }

  .btn-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    h4 { width: 100%; text-align: center; }

    button {
      display: inline-block;
      width: 45%;
    }
  }
}

.sort-list {
  width: 100%;
  max-width: 190px;
  font-size: 14px;
}

.settings-form {

  .form-section {
    width: 100%;
    justify-content: flex-start;

    a { margin-left: 16px; }

    @media (max-width: $media-xs) {
      flex-wrap: wrap;

      .form-control {
        width: 100%;
      }

      .form-control + .form-control {
        margin-left: 0;
      }
    }
  }

  @media (max-width: $media-xs) {

    .form-section-title { display: none; }
  }
}

.verification-modal-dialog {
  max-width: 460px;
}

.referer-modal {
  width: auto;
  min-height: initial !important;
  padding: 0 64px;

  .register-form {
    
    .form-logo {
      position: relative;
      @include flexLayout(center, center);
      height: 40px;

      img { max-height: 100%; }
    }
    .form-title {
      padding-bottom: 0;
    }
    .form-subtitle { 
      text-align: center;
      padding-bottom: 16px; 
    }
    .button-container { padding: 16px; }
  }

  @media (max-width: $media-sm) {
    padding: 0 16px;
  }

  @media (max-width: $media-xs) {
    padding: 0;
  }
}

.grape_rose {
  color: $grape-rose-color;

  &::before {
    content: url('./assets/icons/grape-rose.svg');
    padding-top: 6px;
    margin-right: 8px;
  }
}

.grape_red { 
  color: $grape-red-color;

  &::before {
    content: url('./assets/icons/grape-red.svg');
    padding-top: 6px;
    margin-right: 8px;
  }
}

.grape_white { 
  color: $icon-color;

  &::before {
    content: url('./assets/icons/grape-white.svg');
    padding-top: 6px;
    margin-right: 8px;
  }
}

.grape_sparkling { 
  color: $disabled-color;

  &::before {
    content: url('./assets/icons/grape-sparkling.svg');
    padding-top: 6px;
    margin-right: 8px;
    margin-top: -7px;
  }
}

.vineify-player-wrapper {
  position: fixed;
  background-color: rgba($text-base-color, .7);
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  .vineify-player {
    position: relative;
    max-height: 100%;
    max-width: 100%;
    z-index: 20;

    .vineify-player-content {
      width: auto;
      height: auto;
      
      iframe {
        max-width: 1000px !important;
        max-height: auto !important;
        z-index: 50;
      }

      @media (max-width: $media-xs) {
        width: 100% !important;
      }
    }


    button {
      background: rgba(30,30,30,.9);
      border-radius: .5em;
      width: 40px;
      height: 40px;
      position: absolute;
      top: 16px;
      left: 16px;
      z-index: 100;
  
      &::after {
        content: "X";
        position: absolute;
        bottom: 50%;
        font-weight: bold;
        color: $white;
        right: 50%;
        transform: translate(50%, 50%);
      }
  
      @media (max-width: $media-xs) { 
        display: block;
      }
    }

    @media (max-width: $media-xs) {
      width: 100%;
    }
  }
}

.play-button {
  position: absolute;
  height: 52px;
  width: 52px;
  border-radius: 50%;
  display: flex;
  padding: 0;
  border: none;
  background: white url('./assets/icons/play-video.svg') 105%;
  background-position: center;
}

.loading-content-wrapper {
  min-height: calc(100vh - 400px);
}


.slick-dots {
  bottom: -35px;

  li button::before {
    font-size: 15px;
  }
}


.modal-dialog {
  width: auto;
  min-height: auto !important;
  height: auto;

  &.large {
    width: 800px;
    max-width: 90%;
  }

  &.legal-age {
    max-width: 90%;

    .rsm-flex-1.rsm-p-8 { padding: 0; }
  }
}

.modal-dialog--relative { position: relative; }

.float-img {
  float: right;
  height: 242px;
  padding: 26px 12px;
  z-index: 5;
  border-radius: 50%;
  shape-outside: border-box;
  clip-path: ellipse(50% 50% at 50% 50%);
  margin-top: -180px;

  img { height: 100%; }

  &.region {
    width: 500px;
    height: 450px;
    position: relative;
    clip-path: none;
    margin-top: -150px;

    img {
      height: auto;
      width: 100%;
    }
  }
}

.label {
  border-radius: 4px;
  padding: 4px 16px;
  font-size: 80%;
  font-weight: 600;

  img { margin-right: 4px; }

  &.gold {
    background-color: $icon-color;
    color: $white;

    img { filter: brightness(12); }
  }

  &.disabled {
    background-color: rgba($disabled-color, .25);
  }
}

.custom-case-action-btn {
  min-width: 135px;
  width: auto;
  font-size: 16px;
}

  .change-case-button {
    background-color: transparent;
    border: none;

    &:disabled {
      opacity: .3;
    }
  }

  .recommendations-tools {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 24px;

    .info {
      font-size: 20px;
      // width: 250px;
      padding: 0 12px;
    }
  }

  .case-content-caption {
    font-size: 24px;
    font-weight: 800;
    padding: 0;
  }

  .case-price {
    font-size: 32px;
    font-weight: 600;
    margin-right: 24px;
    padding: 0;
  }

  .recommendation-case-title {
    // font-size: 64px;
    margin: 0;
  }

  .regions-links-section {
    margin: 48px auto;

    .links {

      a + a { margin-left: 24px; }

      @media (max-width: $media-xs) {
        flex-direction: column;

        a + a { margin-left: 0; margin-top: 16px; }
      }
    }
  }


.label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;

  &.gold {
    background-color: $icon-color;
    color: $white;
  }

  &.success {
    background-color: $bg-card-color;
    color: $white;
  }

  &.info {
    color: $white;
    background-color: $alert-color;
  }

  &.secondary {
    background-color: $disabled-color;
  }
}

.region-info-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;

  &.offset {

    .card:nth-child(5n + 2) {
      margin-right: 32px;
    }
  }

  .card {
    margin: 0 16px 32px 16px;
    width: calc(33.33% - 32px);

    @media (max-width: $media-sm) {
      width: 100%; 
      margin-right: 0; 
      margin-left: 0; 
    }

    &__header {
      background-color: $warm-gray-9;

      h4 {
        color: $white;
        font-size: $h5;
        font-weight: 700;
      }
    }

    &__body { 
      padding: 24px;
      
      @media (max-width: $media-xs) {
        padding: 12px 24px;
      }
    }
  }

  @media (max-width: $media-sm) { flex-direction: column; }

  @media (max-width: $media-xs) { 
    padding: 0 16px; 
  }
}

.tastings-header {
  width: 100%;
  height: 327px;
  margin-bottom: 64px;
  background-image: url('./assets/images/tastes-bg-min.png');
  background-size: cover;
  background-color: $primary-brand-color;

  .container {
    position: relative;
    height: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    max-width: 460px;
    color: $white;
    font-size: $subtitle;

    h1 {
      line-height: 1;
      color: $white;
      text-align: left;
      margin: 16px 0;
    }

    @media (max-width: $media-sm) {
      font-size: 16px;

      h1 { font-size: 64px; }
    }

    @media (max-width: $media-xs) {
      padding: 0 24px;

      h1 { 
        font-size: 48px;
        margin-top: 0; 
      }
    }
  }

  &__guide {
    width: 440px;
    height: 110px;
    position: absolute;
    padding: 16px;
    // right: 0;
    right: 190px;
    bottom: -36px;
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

    .guide-info {
      display: flex;
      flex-direction: column;
      font-size: $caption;
      width: 335px;

      .up-title {
        text-transform: uppercase;
        color: $white-wine-text;
        font-size: $caption;
        font-weight: 800;
        margin: 0;
      }

      .guide-name {
        font-size: $subtitle;
        line-height: 1;
        margin: 8px 0 4px
      }

      .subtitle {
        color: $disabled-color;
        font-size: 14px;
        margin: 0;
      }
    }

    img {
      position: absolute;
      height: 250px;
      width: 250px;
      border-radius: 50%;
      right: 0;
      bottom: 0;
      transform: translate(60%, 10%);

      @media (max-width: $media-md) {
        height: 180px;
        width: 180px;
        transform: translate(50%, 10%);
      }

      @media (max-width: $media-sm) {
        height: 122px;
        width: 122px;
        top: 0;
        transform: translate(-10%, -50%);
      }
    }

    @media (max-width: $media-sm) {
      width: 360px;
      right: 0;
      bottom: -80px;
    }

    @media (max-width: $media-xs) {
      bottom: -90px;
    }
  }

  @media (max-width: $media-md) {
    padding: 0 100px 0 24px;
  }

  @media (max-width: $media-sm) {
    padding: 0 24px;
  }

  @media (max-width: $media-xs) {
    padding: 0;
  }
}

.page-warning {
  display: flex;
  align-items: center;
  flex-direction: column;

  .alert {
    &__title { 
      font-weight: 800;
      color: $text-base-color;
      font-size: 32px; 
    }
    &__text { 
      color: $disabled-color;
      font-size: 18px;
    }
  }
}

.alert-layout {
  padding: 32px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 395px);
}

.react-tiny-popover-container {
  z-index: 100;
}

.disable-layout {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(white, .8);
}

.reload-notification {

  .no-order__img { height: 120px; }
}

.rsm-z-40 {
  z-index: 100;
}

.modal-dialog-lg {
  height: auto;
  background-color: $white;
  z-index: 100;
  min-height: 500px !important;
}

.modal-dialog-width-md {
  min-width: 940px;
}

.promt-dialog {
  padding: 32px;
  max-width: 1024px;
  width: 100%;
  // height: 346px;
  min-height: 1px !important;
  border-radius: 10px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .promt-title {
    font-size: $h3;
    line-height: 1;
    font-family: $quincy;
    font-weight: 500;
    padding: 0;
    margin-bottom: 16px;
    // color: $text-base-color;
    text-align: center;
  }

  .promt-text {
    font-size: $subtitle;
    line-height: 1.5;
    color: $tertiary;
    text-align: center;
    margin-top: 0;
    margin-bottom: 24px;

    @media (max-width: $media-xs) {
      font-size: 20px;
    }
  }

  button + button {
    margin-left: 24px;
  }

  @media (max-width: $media-xs) {
    padding: 32px 16px;
    height: auto;

    .flex-center > .flex-space-between {
      flex-direction: column;

      button + button {
        margin-left: 0;
        margin-top: 12px;
      }
    }
  }
}

.card_case-content {

  .card-bottom-section {

    @media (max-width: $media-xs) {

      .flex-space-between {
        flex-direction: column;

        button {
          margin-bottom: 8px !important;
        }

        button + button {
          margin-left: 0 !important;
        }
      }
    }
  }
}

.modal-buttons-container {
  @include flexLayout(center, space-between);

  @media (max-width: $media-xs) {
    flex-direction: column;

    .button {
      margin-top: 16px;
    }
  }
}

.Dropdown-menu {

  .Dropdown-option {
    padding: 12px 16px;
    color: $secondary;

    &:hover {
      background-color: $alabaster-50;
    }
  }

  .Dropdown-option + .Dropdown-option {
    border-top: 1px solid $warm-gray-3;
  }
}

.shipping-country-block {
  @include flexLayout(center, flex-start);
  padding: 0 16px;
  gap: 32px;

  &.account-preference {
    padding: 16px;
    margin-bottom: 32px;
  }

  .country-dropdown-menu {
      margin: -2px 8px 0px;
      cursor: pointer;
      border: 2px solid rgba($text-base-color, .4);
      border-radius: 4px;
      // padding: 2px 16px;
      width: 180px;
      background-color: transparent;

      &::after {
        content: "";
        position: absolute;
        right: 6px;
        transform: translateY(50%);
        bottom: 50%;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-radius: 4px / 2px;
        border-right: 6px solid transparent;
        border-top: 8px solid black;
      }

      .language {
          font-weight: 500;
      }

      .Dropdown-control { color: $text-base-color; margin-right: -16px; z-index: 10; }

      .Dropdown-menu { max-height: 350px; }
  }

  @media (max-width: $media-xs) {
    flex-direction: column;
    gap: 6px;
    align-items: center;
  }
}

.warehouses-content {
  padding: 32px 0;
}

.submit-button-wrapper {
  width: 100%;
  padding: 64px 0;
  background-color: $white;
  z-index: 100;

  .contact-form-submit-button {
    margin: auto;
  }

  @media (max-width: $media-sm) {
    padding: 24px 0;
  }
}

.popover-image-container {
  min-width: 280px;
  position: relative;
  padding: 16px;
  background-color: $white;

  img {
    width: 100%;
  }
}

.margin-9 { margin: 0 9px }

.admin-panel-filters {
  @include flexLayout(center, flex-start);
  flex-wrap: wrap;
  gap: 32px;

  .search-bar-wrapper { 
    width: 100%;
  }

  @media (max-width: $media-xs) {
    flex-direction: column;
    align-items: stretch;
    gap: 6px 32px;

    .form-control {
      max-width: 100% !important;
    }

    .search-bar-wrapper {
      flex-direction: column;
      gap: 0;
      margin-bottom: 32px;

      .search-bar {
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        
        input {
          padding-right: 48px;
        }

        .search-button {
          top: 32px;

          img {
            height: 16px;
          }
        }
      }
    }
  }
}

.header-csv-link {
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  border: 2px solid $vineify-green;
  padding: 4px 16px;
  cursor: pointer;
  text-decoration: none !important;
}

.admin-list-date-filter {
  display: flex;
  align-items: center;
  gap: 12px;

  h5 {
    flex-shrink: 0;
  }

  .DatePicker__input  {
    width: 145px;

    @media (max-width: $media-xs) {
      width: 125px;
    }
  }

  @media (max-width: $media-xs) {
    flex-wrap: wrap;
  }
}

.icon-option {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0;
  padding: 0;
}

.main-register-form {
  padding: 24px 48px;
  width: 100%;
  max-width: 500px;
  margin: 32px auto 0;
  background-color: rgba($white, .85);
  border-radius: 8px;
  margin-bottom: 32px;

  h4 {
    text-align: center;
    margin: 0;
  }

  .compact-form-title {
    margin-bottom: 32px;
  }

  .compact-form-subtitle {
    text-align: center;
    margin: 0;
    font-weight: 500;
    color: $tertiary;

    .button-link { 
      padding: 0;
      color: $vineify-green;
      font-weight: 600;
    }
  }

  .form-subtitle {
    text-align: center;
    margin: 0 0 24px;
    color: $tertiary;
    text-transform: uppercase;
  }

  .button-container {
    padding: 25px;

    button {
      
      max-width: 250px;
    }
  }

  @media (max-width: $media-xs) {
    padding: 16px;
  }
}


.age-layout {
  background-color: $vineify-green;
  width: 100vw;
  height: 100vh;
  gap: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 64px;

  h1 { 
    font-size: $h2;

    @media (max-width: $media-xs) { 
      font-size: 38px;
      text-align: center;
    }
  }

  h1, h3 {
    color: white;
    margin: 0;
  }

  .subtitle {
    font-size: 24px;
    text-align: center;
    color: $quarternary;
    white-space: pre-line;
    margin: 0;

    @media (max-width: $media-xs) {
      font-size: 20px;
      white-space: initial;
    }
  }

  .question-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &.modal {
    width: 100%;
    height: auto;


    h1 {
      font-size: $h3;
    }
    .subtitle {
      font-size: 20px;
    }
  }

  @media (max-width: $media-xs) { 
    justify-content: flex-start;
  }
}

.bg-white {
  background-color: white !important;
}


.line-breaks {
  white-space: pre-line;
}

.upload {
  &__image-wrapper {
    // width: 320px;
    flex-shrink: 0;

    &.center {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .img-container {
      width: 100%;
      position: relative;
      height: auto;
      flex-direction: column;
      gap: 16px;

      &.rounded  {
        width: 250px;
        height: 250px;
        margin: auto;
        display: block;

        img {
          max-width: 100% !important;
          max-height: 100% !important;
          height: 100%;
          width: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .img-title {
        font-size: 20px;
        margin: 0;
        padding: 0;
        font-family: $avenir;
        text-align: center;
      }

      .case-image-wrapper, img {
        max-width: 366px;
      }

      &.with-auto-gen-preview {
        display: flex;
        gap: 32px;
        height: auto;

        .img-wrapper {
          height: 275px;
          display: flex;
          gap: 16px;
          flex-direction: column;
        }
      }
    }
  }

  &__control {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &.horizontal { 
      flex-direction: row;
      justify-content: center;
      max-width: 600px;
      width: 100%;
      padding: 0 16px;
      gap: 16px;

      .button.button_xs {
        width: 150px;
      }
    }
  }
}

.visible { visibility: visible; }
.not-visible { visibility: hidden; }

.fw {
  &-400 { font-weight: 400; }
  &-500 { font-weight: 500; }
  &-600 { font-weight: 600; }
  &-800 { font-weight: 800; }
}

.zi {
  z-index: 0;

  &-5 { z-index: 5; }
  &-10 { z-index: 10; }
  &-15 { z-index: 15; }
}

.hide-sm {
  display: initial;

  @media (max-width: $media-sm) {
    display: none !important;
  }
}

.case-details {

  &__header-bottom {
    position: relative;
    display: flex;
    justify-content: space-between;

    @media (max-width: $media-xs) {
      flex-direction: column;
      gap: 24px;
    }
  }

  &__curator-preview {
    position: absolute;
    right: 48px;
    top: 0;
    transform: translateY(-48px);

    .image-link {
      position: relative;
      height: 98px;
      width: 98px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 50%;
    }

    @media (max-width: $media-xs) {
      position: relative;
      right: auto;
      transform: none;
    }
  }

  &__curator-placeholder {
    align-items: center;
    background-color: $vineify-green;
    border-radius: 50%;
    display: flex;
    height: 85px;
    justify-content: center;
    margin: auto;
    width: 85px;
  }

  &__title {
    font-size: $h4;
    font-family: $avenir;
    margin: 48px 0 0;
    font-weight: 600;
  }
}

.modal-dialog__dark-layout {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(black, .3);
}

.p-16 {
  padding: 16px;
}

.admin-header-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 32px;

  .left-content {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    // gap: 16px;
    min-width: 400px;

    &.wide {
      width: 550px;

      @media (max-width: $media-xs) {
        max-width: 100%;
      }
    }

    &.gap {
      gap: 32px;
    }

    &.horizontal {
      flex-direction: row;
    }

    .case-image {

      &.wine {
        width: auto !important;
        flex-shrink: 0;
        justify-content: flex-start;
      }
      
      img {
        max-height: 185px;
        width: auto;
      }

      @media (max-width: $media-xs) {
        width: 100% !important;
      }
    }

    @media (max-width: $media-xs) {
      padding-top: 0;
      min-width: 100% !important;
      width: 100%;
    }
  }

  @media (max-width: $media-xs) {
    flex-direction: column;
  }
}

.m-auto {
  margin: auto;
}

.not-enought-bottles-text {
  z-index: 1000;
  font-size: 16px;
  font-weight: 800;
  color: $warning;
  margin: 0;
}

.striped-list {
  margin: 0;
  padding: 0;

  li {
    padding: 6px 0;

    &:nth-child(even) {
      background-color: $alabaster-50;
    }
  }
}

.select-checkbox-option {
  margin: 0;
  display: flex;
  gap: 10px;
  color: $tertiary;

  svg {
    cursor: pointer;
  }
}
