@import '../../theme/variables';


.main-menu {
  background-color: transparent;
  width: 45px;
  position: relative;
  
  &__control {
    color: $white;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0 20px 0 0;
    font-size: 16px;
    font-weight: 600;
  }

  &__menu {
    min-width: 200px;
    max-width: 300px;
    height: auto;
    max-height: 90vh;
    right: 0;
    // padding: 4px 16px;
    border-radius: 4px;
    margin-top: 8px;

    .Dropdown-group {
      margin: 0 -16px;
      border-bottom: 2px solid;

      .Dropdown-title {
        background-color: rgba($disabled-color, .3);
        
        padding: 16px;
      }

      .Dropdown-option {
        margin: 0 16px;
  
        &:hover {
          background-color: transparent;
          text-decoration: underline;
        }

        &:first-child {
          border: none
        }
      }
    }


    // .Dropdown-option {
    //   padding: 12px 0;
    //   color: $primary-brand-color;

    //   &:hover {
    //     background-color: transparent;
    //     text-decoration: underline;
    //   } 
    // }

    // .Dropdown-option + .Dropdown-option {
    //   border-top: 1px solid $icon-color;
    // }
  }

  &__arrow {
    display: none;
  }

  @media (max-width: $media-xs) {
    padding: 8px 0;
  }
}

.mobile-menu {

  &__overlay {
    display: none;
  }

  &__body {
    position: fixed;
    transition: transform .4s ease-out;
    transform: translateX(100%);
    padding-bottom: 32px;
    z-index: 120;
    top: 0; right: 0;
    height: 100vh;
    width: 100%;
    max-width: 450px;
    overflow-y: scroll;
    background-color: $white;

    .header {
      @include flexLayout(center, center, column);
      padding: 32px;
      height: 220px;

      .button-link {
        padding: 8px;
      }
      

      .logo { 
        height: 48px;
        filter: none;
      }

      &-icon {
        padding: 8px;
        border-radius: 4px;
        @include flexLayout(center, center);
        border: 1px solid $bg-card-color;
        margin-bottom: 16px;

        img { height: 32px }
      }

      &-user {
        @include flexLayout(center, center, column);
        font-size: 20px;
      }

      .flex-space-between {

        a { padding: 10px 16px; }

        * { font-size: 18px; }
      }
    }

    .divider {
      display: inline-block;
      width: 100%;
      line-height: 1;
      border-bottom: 1px solid $disabled-color;
    }

    .header-title {
      color: $white;
      width: 100%;
      padding-left: 64px;
      margin: 0;
      transform: translateY(-12px);
      font-size: 18px;
    }

    .nav-menu {
      margin: 0;
      padding: 32px 0;
      flex-direction: column;

      .header-links {
        flex-direction: column;
        padding: 0;

        .link {
          display: flex;
          width: 100%;
          flex-wrap: wrap;

          a {
            display: inline-block;
            color: black;
            padding: 16px 64px;
            font-size: 24px;
            width: 100%;
            text-align: center;

            &.active {
              background-color: $bg-card-color;
              color: $white;
            }
          }

          .inner-menu-title {
            font-size: 24px;
            font-family: $avenir;
            display: block;
            text-align: center;
            width: 100%;
            font-weight: 600;
            color: black;
            padding: 16px 0 8px;
          }

          .inner-link {
            font-size: 20px;
            color: $disabled-color;
            padding: 8px;
            font-weight: 400;

            &.active {
              background-color: $bg-card-color;
              color: $white;
            }
          }
        }
      }
    }

    .close-button {
      position: absolute;
      right: 16px;
      top: 16px;
      background-color: transparent;

      img {
        filter: brightness(0);
        height: 20px; 
      }
    }
  }

  &__button {
    z-index: 30;
    background-color: transparent;
    // position: absolute;
    // top: 50%;
    // right: 16px;
    // transform: translateY(-100%);

    img { width: 24px; }
  }

  &.opened {

    .mobile-menu__overlay {
      overflow: hidden;
      display: block;
      position: fixed;
      left: 0; top: 0;
      width: 100%;
      height: 100vh;
      z-index: 20;
      background-color: rgba(black, .1);
    }

    .mobile-menu__body {
      transform: translateX(0%);
    }
  }
}


.language-menu-view {
  @include flexLayout(center, flex-start);
  font-size: 14px;

  img { 
    height: 16px; 
    margin-right: 6px;
    filter: brightness(12);
  }

  .language { text-transform: capitalize; }
}

.account-links {
  padding: 0 8px;
  flex-grow: 1;

  a.button { width: auto !important; }
}
