@import '../../theme/variables';


.custom-case-wines-header {
  display: flex;
  align-items: center;
  padding: 8px 16px;

  button { 
    padding: 0 8px;
    font-weight: 600;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
  }

  .select-wrapper {
      margin-left: auto;
  }

  .wine-like {
    width: 170px;
    text-align: left;
    justify-content: flex-start;
  }

  .wine-vintage {
      width: 90px;
  }

  .wine-name {
    width: 215px;
    justify-content: flex-start;
  }

  .wine-producer {
    text-align: center;
    width: 120px;
    justify-content: flex-start;
  }

  .wine-grape {
    width: 100px;
    text-align: center;
  }
}

.sort-list {
  margin-left: auto;
  padding: 16px 0px;
}

.search-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  .custom-case-wines__search-bar + .button {
    width: auto;
    padding: 10px 36px;
  }
}

.restock-history {
  padding: 0;

  &__title {
    @include flexLayout(flex-end, center);
    margin-top: 36px;
    padding: 0 32px;

    h2 {
      margin: 0;
      text-align: left;
      width: 100%;

      @media (max-width: $media-xs) {
        font-size: $h3-mobile;
      } 
    }
  }

  &__list { 
    padding: 16px;
    
    @media (max-width: $media-xs) {
      padding: 16px 0;
    }
  }

  &__row {
    display: flex;
    align-items: center;

    @media (max-width: $media-xs) {
      padding-top: 16px;
      padding-bottom: 16px;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 0 12px;

      &:nth-child(2n + 1) {
        background-color: whitesmoke;
      }
    }
  }

  &__item {
    width: 150px;
    padding: 6px 12px;

    &.main {
      width: 220px;
      flex-grow: 1;
    }

    &.negative { color: $warning }
    &.positive { color: $vineify-green }

    @media (max-width: $media-xs) {
      width: 100%;
      text-align: left !important;

      &.stock-change {
        max-width: 50%;
        width: auto;
        flex-grow: 0;
        flex-shrink: .45;
      }
    }
  }

  @media (max-width: $media-xs) {

  }
}

.wine-price-history-report {

  &.admin-report-header {
    width: 100%;
    justify-content: flex-start;
    padding: 16px 0;
  }

  .report-row {
    width: 100%;

    .report-item {
      padding: 6px 12px;
  
      &.date, &.action {
        // style={{ width: '150px', padding: '6px 12px', textAlign: 'center', }}
        width: 150px;
        text-align: center;

        @media (max-width: $media-xs) {
          text-align: left;
        }
      }
  
      &.user {
        width: 220px;
        flex-grow: 1;
      }
  
      &.price {
        width: 200px;
        text-align: center;
        margin: 0;
        border: none;
        font-size: 16px;
      }
  
      &.no-history {
        font-size: 18px;
        text-align: left;
        font-weight: 600;
      }

      @media (max-width: $media-xs) {
        width: 100% !important;
        text-align: left !important;

        &.price {
          max-width: 50%;
          width: auto !important;
        }
      }
    }

    @media (max-width: $media-xs) {
      padding: 16px 0;
      flex-wrap: wrap;
      gap: 0 12px;
      justify-content: flex-start;

      &:nth-child(2n + 1) {
        background-color: whitesmoke;
      }
    }
  }
}


.case-data__regions-list {
  @include flexLayout(center, flex-start);
  width: 100%;
  gap: 8px;
  padding: 0 16px;
  margin: 0;

  .case-data__region {
    font-weight: 400;
    font-size: 14px;
    padding: 4px 12px;
    border-radius: 8px;
    background-color: $alabaster;
  }
}
