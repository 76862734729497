@import '../../theme/variables';

.steps {
    margin-top: 64px;
    margin-bottom: 32px;

    &__list {
        display: flex;
    }

    &__item {
        color: $primary-brand-color;
        position: relative;

        .button-link { 
          padding: 0;

          &:not(:hover) {
            text-decoration: none;
          }
        }

        .link-disabled {
          color: $disabled-color;
        }

        &.active {
            font-weight: bold;

            .button-link { font-weight: bold; }
        }
    }

    &__item + &__item {
        padding-left: 32px;

        &::before {
            content: ">";
            position: absolute;
            left: 16px;
            transform: translateX(-50%);
        }
    }

    @media (max-width: $media-sm) {
      margin-top: 32px;
    }
}

.button_cancel-payment {
    margin-top: 24px;

    &::before {
        content: url('../../assets/icons/chevron-left.svg');
        margin-right: 12px;
        padding-top: 2px;
    }
}

.order-data {
    font-size: 18px;
    display: flex;
    flex-wrap: wrap;

    &__block {
        max-width: 50%;
        min-width: 35%;
        flex-grow: 1;
        padding: 32px 0;

        .contact-title {
            padding: 0;
            font-weight: 800;
            font-size: 20px;
            margin-bottom: 16px;
            margin-top: 0;
            color: $text-base-color;
        }
    
        p {
            margin-bottom: 8px;
            margin-top: 0;
        }

        .button-link {
            padding: 0;
            margin-top: 8px;
        }

        @media (max-width: $media-sm) {
            padding: 8px 0;
            width: 100%;
            max-width: 100%;

            img { margin-bottom: 32px; }
        }
    }

    @media (max-width: $media-sm) {
        flex-direction: column;
    }
}

.alert.order-shipping-alert {
  margin-top: 8px;
    padding: 16px 16px 16px 64px;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    .alert__title {
        width: 100%;
        padding: 0 !important;
        margin: 0 !important;
        color: $primary;
        font-size: 20px;
        line-height: 1;
        font-weight: 600;
        font-family: $avenir;
    }

    button {
      padding: 0;
      color: $error-color;
      font-weight: 800;
      font-size: 16px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      color: $primary;
      margin: 0;
    }

    .alert__icon {
      position: absolute;
      top: 50%;
      left: 20px;
      height: 40px;
      transform: translateY(-50%);
      filter: none !important;
    }
}

.edit-contacts-modal-dialog {
    max-width: 600px;
    width: 90%;
    height: auto;
    max-height: 90%;
}

.button_aside {
    padding: 50px 0;
}

.scroll-to-pay {
    position: fixed;
    display: flex;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 24px 32px;
    background-color: $white;
    opacity: 1;
    justify-content: center;
}

.shipping-cost {
    @include flexLayout(center, space-between);
    position: relative;

    .case-price-block {
        font-weight: 800;
        margin-right: 54px;
        font-size: 18px;
    
        @media (max-width: $media-sm) {
            margin: 0;
            padding: 24px 24px 0;
            width: 100%;
            text-align: center;
        }
    }

    @media (max-width: $media-sm) {
        flex-direction: column;
        align-items: flex-end;
    }

    @media (max-width: $media-xs) {
        align-items: center;
    }
}

.editable-text-wrapper.gift-message {
  font-size: 18px;
  margin-bottom: 32px;
  

  h4 {
    font-weight: 800;
    font-size: 20px;
    margin: 0;
  }

  .button-link {
    padding: 0;
    font-size: 18px;
    font-weight: 600;

    @media (max-width: $media-xs) {
      font-size: 14px;
    }
  }

  & textarea {
    background: $white;
    border: 2px solid $quarternary;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1;
    outline: none;
    font-weight: 500;
    min-height: 40px;
    // border-radius: 100px;
    border-radius: 8px;
    padding: 8px 16px;
  }
}
