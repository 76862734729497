@import '../../theme/variables';


.facebook-auth {
  display: flex;
  justify-content: center;
  gap: 6px;
  width: 100%;

  img {
    height: 14px;
    width: auto !important;
  }
}

.auth-wrapper {
  width: 100%;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    transition: all 400ms ease-out;
    color: $white;
    background-color: #3C8CC8;
    border: 1px solid #3C8CC8;
    outline: none;
    text-align: center;
    padding: 10px 20px;
    width: 100%;
    height: 40px;
    box-shadow: 0px 0px 25px rgb(0 0 0 / 10%);
    border-radius: 50px;

    img { 
      height: 14px;
      width: auto !important; 
    }
  }
}
