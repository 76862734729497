@import '../../theme/variables';

@mixin forrmCaption() {
    font-weight: 600;
    color: $text-base-color;
    font-size: 20px;
    padding: 8px 4px;
} 


.processing-wrapper {
    display: flex;
    align-items: center;
}

.img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    padding: 16px;
    margin-bottom: 16px;

    img {
        max-width: 100%;
        max-height: 175px;
    }

    & + button {
        text-align: center;
    }
}

.form_tracking {
    margin: auto;

    h3 {
        font-size: 45px;
        font-family: $quincy;
        color: black;
        font-weight: bold;
    }
}

.manage-case-form {

    .case-form-img {
      position: relative;
      display: flex;
      justify-content: center;
      width: 385px;
      height: 295px;
      flex-shrink: 0;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      .case-no-photo-message {
        position: absolute;
        padding: 8px 16px;
        left: 0;
        bottom: 0;
        margin: 0;
        width: 100%;
        text-align: center;
        font-weight: 500;
        color: white;
        transform: translateY(-90px);
      }

      @media (max-width: $media-xs) {
        width: 100%;
        max-width: 320px;

        .upload__image-wrapper {
          .img-container {
            padding: 0;

            img {
              height: auto;
              width: 100%;
            }

            #image-content {
              width: 100%;

              .case-image-wrapper {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .manage-case__section {
      padding: 0 16px;
      gap: 16px;

      .form-control {
        padding: 0;

        &.col--status {
          gap: 0;
          max-width: 225px !important;
        }
      }

      .form-control + .form-control {
        margin-left: 0;
      }
    }

    .form-section-title {
      padding: 0 16px;
    }

    .case-main-data-wrapper {
      display: flex;
      padding: 0 8px;
      justify-content: space-between;
      align-items: stretch;
      min-height: 290px;

      @media (max-width: $media-xs) {
        flex-direction: column;
      }
    }

    .case-control__title {
      font-family: $avenir;
    }

    .case-main-data {
      // width: 600px;
      flex-grow: 1;
      // margin-top: 32px;
      margin-top: 0;
      padding: 0 32px;

      @media (max-width: $media-xs) {
        width: 100%;
        padding: 0;
        margin-top: 32px;
      }
    }

    .form-control {
      width: 100%;
      padding: 16px;
      margin: 0;
    }

    .case-pricing {
      margin-top: 32px;
      padding: 24px 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // height: 200px;

      &__details {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: 16px;

        .pricing-fee {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          gap: 16px;

          @media (max-width: $media-xs) {
            flex-direction: column;
            align-items: stretch;
            width: 100%;
            gap: 0;
          }
        }

        &-controls {
          display: flex;
          flex-shrink: 0;
          align-items: center;
        }

        .form-control.pricing-control {
          width: 150px;
          padding: 0;
          margin-top: 16px;
        }
      }

      &.compact {
        height: auto;
      }

      h3 {
        padding: 0;
      }

      .calculated-pricing {
        padding: 16px 0;

        @media (max-width: $media-xs) {
          justify-content: center;
        }
      }

      @media (max-width: $media-xs) {
        
        h3 { text-align: center; }
      }
    }

    .case-form-wines {
        padding: 24px 16px;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;

        .add-wine {
            font-size: 18px;
            font-weight: 600;
        }

        .case-form-wine-row {
          width: 100%;
          flex-grow: 1;
          position: relative;
          display: flex;
          align-items: center;
          padding: 16px;
          margin: 0px 0;
          font-size: 14px;
          border: 1px solid $disabled-color;
          border-radius: 4px;
          @include boxShadow(rgba(0, 0, 0, .1));

          .out-of-stock-layout {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 5;
            display: flex;
            align-items: center;
            // background-color: rgba(white, .8);
            background-color: rgba(#d8b6b6, .8);

            &::after {
              content: 'Out of Stock';
              font-weight: 800;
              color: $error-color;
              padding: 0 8px;
            }
          }

          &.active {
            // background-color: $alabaster-50;
            background-color: #e3e0d3;
            border-color: $warm-gray-3;
          }

          .wine-region {
            margin-right: 24px;
            margin-bottom: 6px;
            flex-shrink: 0; 
            padding: 0 16px;
          }

          .wine-labels {
            display: flex;
            align-items: center;
            gap: 6px;
            flex-grow: 1;

            .wine-label {
              padding: 4px 12px;
              border-radius: 4px;
              font-size: 12px;

              &.public {
                background-color: rgba($info-light, .8);;
              }

              &.private {
                background-color: rgba($warning-light, .8);
              }
            }
          }

          .wine-name {
            font-weight: 600;
            padding: 0 16px;
            font-size: 16px;
            line-height: 1.2;
            // width: 100%;
            width: 395px !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-bottom: 6px;

            &.red {
              color: $grape-red-color;
            }
        
            &.white {
              color: $icon-color;
            }
        
            &.rose {
              color: $grape-rose-color;
            }
        
            &.sparkling {
              color: $bg-card-color;
            }
          }

          .wine-cost {
            font-size: 16px;
            padding-right: 16px;
            padding: 0 16px;
          }

          .wine-row__img-container {
            height: 56px;
            width: 56px;
            padding: 0px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            margin-right: 16px;

            .wine-img {
              height: 100%;
              cursor: help;
            }

            .flex-space-between { justify-content: flex-start; }
          }

          .wine-row__img-container + .flex-column {
            width: calc(100% - 72px);
          }

          .form-control {
            width: auto;
          }

          .button-link {
            padding-left: 24px;
            width: auto;
            margin: 0;
          }

          .count-input {
            z-index: 10;

            .input-group {
              padding-left: 0;
              
              input {
                width: 40px;
                padding: 0 8px;
                height: 30px;
                font-size: 16px;
              }
          }
          }

          

          &__icon {
              height: 16px;
          }

        }

        .add-more-wines {
            display: inline-flex;
            align-items: center;

            button {
                border-radius: 50%;
                width: 24px;
                height: 24px;
                padding: 6px;
                position: relative;
                margin-right: 6px;
    
                img {
                  max-width: 100%;
                  max-height: 100%;
                  filter: brightness(12);
                }
            }
        }

        @media (max-width: $media-xs) {

          .alert {
            padding: 16px !important;
            text-align: center;
          }
        }
    }

    @media (max-width: $media-xs) {

      .flex-space-between {
        flex-wrap: wrap;
        width: 100%;

         .submit-button {
          margin: auto;
         }
      }

      .form-section {
        flex-wrap: wrap;

        .form-control + .form-control {
          margin: 0 !important;
        }
      }

      .form-control, .form-control.horizontal {
        width: 100% !important;
        padding: 8px 0 !important;
      }

      .form-section__col {
        padding: 8px 0 !important;
        margin: 0 !important;
      }
    }
}

.restock-form, .wine-price-form {
    width: 370px;
    padding: 0;

    .section-title {
      color: $text-base-color;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    button {
        margin-top: 12px;
    }

    p { margin-bottom: 0; }

    .restock-warning {
      white-space: pre-line;
      text-align: center;
      color: $warning;
    }

    .form-control > .input-group { margin: auto; }
}

.wine-price-form {
  width: 90%;
  max-width: 450px;

  .section-title {
    font-weight: normal;
  }

  .form-control { align-items: center; }
}

.count-input {

    .input-group {
        display: flex;
        align-items: center;
        padding: 0 16px;

        .control-btn {
            position: relative;
            margin: 0 8px;
            border-radius: 50%;
            background-color: $disabled-color;
            width: 20px;
            height: 20px;
            padding: 0;

            img { 
                filter: brightness(12);
                width: 18px; 
            }
        }

        input {
            // height: 50px;
            height: 40px;
            padding: 8px;
            border-radius: 8px;
            text-align: center;
            font-size: $body;
        }
    }
}


.friend-refer-form {
    padding: 20px 0;

    .section-header {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;

        .pre-title {
            margin: 0;
            text-transform: uppercase;

            &:after {
                content: url('../../assets/icons/wine-love-glasses.svg');
                transform: translate(-30%, -35%) scale(.3);
                position: absolute;
            }
        }

        h2 {
            margin: 8px 0 16px;
            padding: 0;
            font-size: 42px;
            line-height: 1;

            @media (max-width: $media-xs) { font-size: 42px; }
        }

        .subtitle {
            color: $disabled-color;
        }

        .success-msg {
            color: $bg-card-color;
            font-size: 18px;
            position: absolute;
            bottom: 0;
            font-weight: 600;
            padding: 12px 0;
            transform: translateY(100%);
        }

        @media (max-width: $media-sm) {

            .subtitle {
                padding: 0 100px;
            }
        }

        @media (max-width: $media-xs) {
            margin: 16px 0;

            .subtitle {
                padding: 0;
            }
        }
    }

    .form-section {

        .form-control {

            label { font-weight: 500; }
    
            input {
                border-color: #81A398;
            }
        }

        @media (max-width: $media-sm) {
            justify-content: space-between;
            flex-wrap: wrap;

            .form-control { 
                width: 45%;
                margin-left: 0 !important; 
            }
        }

        @media (max-width: $media-xs) {
            flex-direction: column;
            align-items: center;

            .form-control { 
                width: 100%;
                max-width: 300px;
                margin-bottom: 24px;

                button { margin: 16px auto; width: 100%; }
            }
        }
    }
}

.wine-data-form {
    padding: 32px;

    @media (max-width: $media-xs) {
      padding: 0;

      .form-section {
        flex-direction: column;

        .form-control {
          max-width: 100%;
          width: 100%;
        }
      }
    }
}

.admin-tags-list {
    margin: 0 0 8px !important;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .tag {
        padding: 0;
        float: left;
        border-radius: 8px;

        .tag-button {
            padding: 6px 12px;
            border: 1px solid;
            background-color: $white;
            color: $disabled-color;
            font-size: 14px;
            border-radius: 8px;

            &.selected {
                background-color: $bg-card-color;
                border-color: $bg-card-color;
                color: $white;
            }
        }
    }
}

.recommendation-form {

    label {
        font-weight: 600;
    }

    .recommendation-info {
        padding: 16px;
        width: 100%;
        background-color: rgba($bg-card-color, .2);
        border: 1px solid rgba($bg-card-color, .6);
        border-radius: 10px;
        color: $primary-brand-color;
    
        .info-item {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            gap: 6px;
        }
    }
}

.tox .tox-statusbar {
    display: none;
}

.edit-contact-data-form {

    .form-control {
        flex-grow: 1;

        &.country { flex-grow: 3; } 
    }

    button { margin: 32px auto; }
}

.alert.out-of-stock-alert, .alert.connection-failed-alert {
  justify-content: center;
  @include boxShadow(rgba(135, 135, 135, 0.1), 12px);

  .alert__title {
    margin: 0 !important;
    padding: 0;
    font-weight: 600;
  }
}

.alert.connection-failed-alert {
  border: 1px solid $error-color;
  flex-direction: column;

  * { color: $error-color; }

  .alert__text {
    margin-bottom: 16px;
  }
}


.case-data {

  &__wines-filters {
    // background-color: rgba($icon-color, .2);
    border: 1px solid rgba($disabled-color, .5);
    border-radius: 4px;
    margin: 16px;
    padding: 32px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    .form-control {
      width: calc(25% - 16px);
    }

    @media (max-width: $media-xs) {
      margin: 8px 0;
      padding: 16px;

      .wines-categories-buttons {
        flex-wrap: wrap;
        gap: 12px;

        .category-button {
          width: calc(50% - 8px);
          flex-grow: 1;
        }
      }
    }
  }
}

.wine__category-badge {
  position: absolute;
  top: 8px;
  right: 0;
}


.notes-section {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-bottom: 32px;

  .form-control {
    width: 100%;
    // max-width: calc(50% - 32px);
    flex-grow: 1;
    gap: 16px;

    textarea {
      flex-grow: 1;
    }
  }
}

.crop-image-dialog {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

.submit-divider {
  position: relative;
  margin: 26px 0;
  height: 1px;
  width: 100%;
  border-bottom: 1px solid $bg-card-color;

  &::before {
    position: absolute;
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
    padding: 0 16px;
    background-color: $white;
    font-style: italic;
    content: "OR";
    display: inline-block;
  }
}

.register-form {
  // input {
  //   background: rgba(244, 242, 234, 0.25) !important;
  //   border: 2px solid rgba(209, 173, 130, 0.4) !important;

  //   &::placeholder {
  //     color: rgba($disabled-color, .65) !important;
  //   }
  // }

  .button-container--column {
    padding: 0px;
    margin-bottom: 16px;

    @media (max-width: $media-sm) {
      flex-direction: column !important;
    }
  }

  .button-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .error-msg-block {
    padding: 0 10px;
  }

  .form-section {
    position: relative;

    &-title.invalid {
      color: $error-color !important;

      .text-gray { color: $error-color !important; }
    }

    .error-msg-block {
      transform: none;
    }
  }

  .form-control {
    label { font-weight: 600; }
  }
}

.col--status {
  width: 210px;
  gap: 10px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  padding: 16px;

  @media (max-width: $media-xs) {
    width: 100%;
  }
}

.contact-form {
  width: 100%;
  position: relative;

  .form-caption {
    @include forrmCaption();
  }

  .form-section {
    padding: 8px 0;
    gap: 32px;

    .form-control { 
      margin-bottom: 0;
      max-width: 100%;
      width: 90px;
      flex-grow: 1;

      &.large { flex-grow: 4; }

      label { font-weight: 500; }

      input::placeholder { color: rgba($disabled-color, .6) }

    }

    .form-control + .form-control {
      margin: 0;
    }

    @media (max-width: $media-xs) {
      flex-wrap: wrap;
      padding: 0;
      margin-bottom: 16px;

      &:not(:last-child) {
        gap: 16px;

        .form-control { width: 100%; }
      }

      .form-control.large {
        width: 100%;
      }
    }
  }
}

.payment-content {
  .button-container {
    gap: 16px;
    padding: 24px 0;

    @media(max-width: 520px) {
      .button { 
        margin: 0!important;
        max-width: 100% !important;
        width: 100% !important;
      }
    }
  }

  .payment-section-header {
    @include flexLayout(flex-start, space-between, column);
    gap: 8px;

    .checkbox-wrapper { margin-top: 0; }
  }

  .form-caption {
    @include forrmCaption();
  }

  .checkbox-wrapper {
    width: auto;
    display: inline-block;
    margin-top: 8px;
    margin-bottom: 24px;
  }
}

.card-placeholder{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140px;
}

.payment-button {
  margin: auto;
  width: 80% !important;
  max-width: 300px;
}

.payment-header {
  @include flexLayout(center, space-between);

  .button-link {
    @include flexLayout(center, space-between);
    gap: 6px;
    text-decoration: none;
    font-weight: 600;

    img { width: 16px; }
  }

  .payment-title {
    font-weight: 600;
    font-size: 24px;
    color: $alert-info-text;
  }
}

.retailer-data {
  padding: 32px 64px;

  &__tabs {
    display: flex;
    gap: 6px;
    z-index: 5;

    .tab-button {
      transform: translateY(1px);
      padding: 12px;
      width: 100%;
      z-index: 10;
      font-size: 18px;
      border: 1px solid $disabled-color;
      border-bottom: none;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: rgba($disabled-color, .1);
      
      &.active {
        z-index: 10;
        border-bottom: 2px solid white;
        background-color: transparent;
        font-weight: 600;
      }
    }
  }

  &__content { 
    padding: 64px;
    border: 1px solid $disabled-color;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.payment-form {
  z-index: 115;
  background-color: $white;
}

.regions-with-inventory-list-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dropdown-wines-inventory {
  font-size: 14px;
  margin: 0;
  padding: 0;
  display: inline-flex;
  gap: 6px;
  list-style: none;
}

.editable-text-wrapper {
  width: 100%;
  @include flexLayout(flex-start, flex-start, column);
  gap: 8px;

  p { margin: 0; }

  textarea {
    width: 100%;
  }
}

.main-wine-data-fields {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: flex-start !important;

  .form-section {
    gap: 16px;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: flex-start !important;
  }
  .form-control {
    margin-left: 0 !important;
    flex-grow: 1;
    max-width: calc(50% - 16px) !important;
  }
}

.add-label-form {
  width: 480px;
  max-width: 90%;
  padding: 24px 0;
  margin: auto;

  .form-section-title {
    margin-top: 0;
  }

  .form-section {
    gap: 16px;
  }

  .button { width: 100%; }
}

.curator-form {

  &__photo-placeholder {
    display: flex;
    margin: auto;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: $vineify-green;
    width: 225px;
    height: 225px;
  }
}

.curator-note {

  &__label {
    height: 0;
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 0 !important;
    padding: 0 8px;
    transform: translateY(24px);
  }
}

.select-tree {
  [class*="-control"] {
    padding: 0 16px;
    flex-wrap: nowrap;
  }

  .value-container {
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    display: flex;
    flex: 1 1 0%;
    // flex-wrap: wrap;
    padding: 0;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    text-overflow: ellipsis;
    flex-grow: 1;
  }

  [class*="-ValueContainer"] {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    // gap: 2px;
    padding: 0;
    text-overflow: ellipsis;
    flex-grow: 1;
  }

  [class*="-multiValue"] {
    background-color: transparent;
    align-items: baseline;
    flex-shrink: 0;

    div {
      padding: 0;

      &:nth-child(2) { display: none; }
    }

    &:not(:last-child)::after {
      content: ",";
      display: inline;
    }
  }

  .multiselect-value {
    background-color: transparent;
    align-items: baseline;
    flex-shrink: 0;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;

    &:not(:first-child)::before {
      content: ", ";
      display: inline;
    }
  }
}
