.load-screen {
  min-height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;

  &.content-loading {
    min-height: auto;
    padding: 32px;
  }
}

.spinner {
  // width: 40px;
  // height: 40px;

  @media (prefers-reduced-motion: no-preference) {
      animation: spin infinite 2s linear;
    }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  
  to {
    transform: rotate(360deg);
  }
}
