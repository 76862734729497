@import '../../theme/variables';


.alert {
  position: relative;
  padding: 16px 32px 16px 64px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  &.success {
      z-index: 5;
      color: $white;
      background-color: #3C8CC8;

      .alert__icon { filter: brightness(0) invert(1); }
  }

  &.warning {
    z-index: 5;
    background-color: rgba($icon-color, .4);

    .alert__text { color: #906c41; }
  }

  &.error {
      z-index: 5;
      color: $error-color;
      background-color: rgba($error-color, .2);

      .alert__icon { filter: brightness(0) invert(1); }
  }

  &.fixed {
      position: fixed;
      right: 16px;
      bottom: 16px;
  }

  &.alert-sm {
      max-width: 375px;
      font-size: 14px;
      line-height: 1.25;
  }

  &__icon {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    max-height: 64px;
  }

    &__title {
      margin: 0 !important;
      padding: 0 !important;
    }

    &__text { 
        margin: 0px;
  }

  &.text-white .alert__text { color: white }

  &.session-alert {
    background-color: $warning-light;
    margin: -32px 0 0;
    padding: 16px 32px;
  }
}

.alert_tracking {
    padding: 16px 32px;
    flex-direction: column;
    margin-bottom: 16px;
    color: $white;
    background-color: $info-light;

    &-title {
        margin: 0 0 12px;
        font-size: $h5;
        color: $primary;
    }

    &-msg {
        margin: 0;
        font-size: 14px;
        line-height: 1.25;
        color: $secondary
    }
}

.order-confirmed {
    display: flex;
    border-radius: 10px;
    padding: 16px;
    background-color: rgba(#81A398, .2);
    margin-top: 24px;
    margin-bottom: 64px;

    .confirmed-info {
        margin-left: 16px;
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .subtitle {
            margin: 0px;
            color: $disabled-color;
        }

        .confirm-notification {
            font-size: 20px;
            line-height: 1;
            margin-top: 0px;
            margin-bottom: 0px;
        }

        .confirmed-info_date {
            text-align: right;

            p {
                margin: 0;
            }
        }
    }
}

.no-order {
    width: 100%;
    max-width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__title {
        font-family: $quincy;
        font-weight: bold;
        font-size: 45px;
        line-height: 1;
        margin-top: 32px;
        margin-bottom: 16px;
    }

    &__text {
        color: $disabled-color;
        margin: 0px;
    }

    .button {
        margin-top: 32px;
    }
}

.confirmation-dialog {
    width: 300px;
    max-width: 100%;
    padding: 32px 16px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: $white;

    &.dialog-lg {
      width: 800px;
    }

    .confirmation-dialog__text {
        font-size: $subtitle;
        font-weight: 500;
        width: 100%; 
        text-align: center;
        margin-top: 0;
    }

    .confirmation-dialog__title {
      font-weight: 700;
      text-align: center;
      margin-top: 0;
  }

    .confirmation-dialog__buttons {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .button {
            display: inline-block;
            width: 45%;
        }
    }

    &__control {
        margin-bottom: 24px;
    }
}

.calculated-pricing {
    width: 200px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 16px;

    button { margin-bottom: 6px; }
}

.case-pricing-details {
    position: relative;
    padding: 32px;

    .caption {
        padding: 0 16px;
        font-size: 24px;
        margin: 0 0 16px;
        text-decoration: underline;
    }

    .calculated-pricing__wines {
        width: 100%;

        li {
            width: 100%;
            line-height: 1.2;
            padding: 4px 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span + span { font-weight: 600; }

            &.wine-pricing {
                line-height: 1.4;

                // span:first-child { width: 75%; }
            }

            .dots {
              flex-grow: 1;
              border-bottom: 1px dotted $tertiary;
              padding: 0 4px;
            }

            &.section-line { 
                border-top: 1px solid $disabled-color;
                margin: 8px 0;
            }

            &.sub-total {
                font-weight: 600;
                margin-bottom: 8px;
                padding-top: 16px;
                padding-bottom: 16px;
                background-color: rgba($disabled-color, .3);
            }
            
            &.total {
                font-weight: 600;
                font-size: 120%;
                margin-top: 8px;
            }
        }
    }
}

.empty-pricing-list {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    right: 0;
}

.pricing-details-loading {
  min-height: 100px !important;
  max-height: 240px !important;
}

.six-pack-banner {
  padding: 12px 24px;
  border: 2px solid $white-wine-text;
  color: $white-wine-text;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 18px;

  p { margin: 0; }

  @media (max-width: $media-xs) {
    padding: 12px;
    font-size: 16px;
  }
}
