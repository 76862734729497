@import '../../theme/variables';


.welcome-header {
  @include flexLayout(center, center);
  background-color: $vineify-green;
  background-image: url('./../../assets/images/wait-list-bg.jpg');
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  gap: 90px;
  padding: 90px;
  position: relative;
  overflow: hidden;
  width: 100%;
  // height: 350px;
  height: 100%;
  text-align: left;
  // clip-path: ellipse(80% 80% at 50% 15%);
  
  h1 {
    color: $white;
    // font-size: 64px;
    font-size: 76px;
    margin-top: 36px;
    line-height: 1.1;

    @media (max-width: $media-md) {
      margin: 24px 0;
    }

    @media (max-width: $media-xs) {
      margin: 16px 0;
      font-size: 24px;
    }
  }

  h3 { 
    color: $white;
    // font-size: 18px;
    font-size: 22px;
    font-weight: 800;
    width: auto;
    padding: 0;

    @media (max-width: $media-sm) {
      font-size: 20px;
    }

    @media (max-width: $media-xs) {
      padding: 0;
      font-size: 12px;
    }
  }

  img {
    width: 100%;
    // filter: brightness(.7);
  }

  .welcome-title {
    max-width: 730px;
    // position: absolute;
    // width: 460px;
    width: 40%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: 40px 0;
    // top: 0;
    // left: 50%;
    // transform: translateX(-50%);
    // text-align: center;
    
    h3 { 
      // max-width: 450px;
      white-space: pre-wrap;
    
      @media (max-width: $media-xs) { 
        // max-width: 270px;
        font-size: 14px; 
      }
    }

    @media (max-width: $media-md) {
      padding: 24px;
      width: 100%;

      h1 { font-size: 68px; }
    }

    @media (max-width: $media-sm) {
      // width: 100%;
      margin: 0;
    }

    @media (max-width: $media-xs) {
      padding: 0;

      h1 { font-size: 40px; }
    }
  }

  .welcome-header-form {
    background-color: $white;
    border-radius: 10px;
    max-width: 650px;
    width: 40%;
    max-width: 460px;
    flex-grow: 1;
    padding: 40px 60px;

    .no-order {

      &__img {
        width: 64px;
      }
    }

    form {

      .form-logo {
        width: 100%;
        text-align: center;

        img { height: 56px; }
      }

      .form-header {
        text-align: center;
        padding: 20px 0;

        .form-title {
          font-family: $quincy;
          font-size: 32px;
          color: $text-base-color;

          @media (max-width: $media-sm) {
            font-size: 20px;
          }
        }

        .form-subtitle {
          font-size: 16px;

          .button-link {
            padding: 0 6px;
          }

          @media (max-width: $media-sm) {
            font-size: 14px;
          }
        }
      }

      .form-control + button {
        margin-top: 65px;
      }

      &.register-form {

        .button-container {
          padding: 25px;
        }

        .form-link {
          margin: 0;
        }
      }
    }

    @media (max-width: $media-md) {
      width: 100%;
    }

    @media (max-width: $media-sm) {
      max-width: 520px;
      padding: 30px 24px;
    }

    @media (max-width: $media-sm) {
      padding: 16px;
      margin-bottom: 32px;
    }
  }

  @media (max-width: $media-md) {
    padding: 32px;
    gap: 45px;
  }

  @media (max-width: $media-sm) {
    // height: initial;
    flex-direction: column;
    gap: 32px;
    background-position: bottom;
  }

  @media (max-width: $media-xs) {
    background-image: url('../../assets/images/wait-list-bg-xs.jpg');
    background-position: 0 -25px;
    padding: 0;
  }
}


.new-home-header {

  @media (max-width: $media-md) {
    margin-bottom: 275px;

    .welcome-title {
      top: 0;
      transform: translateY(50%);
      padding: 0 32px;

      h1 { font-size: 64px; }
    }
  }

  @media (max-width: $media-xs) {

    .welcome-title {
      top: 0;
      padding: 0 8px;

      h1 { font-size: 32px; }
    }
  }
  
}


.infographics {
  display: flex;
  text-align: center;
  justify-content: space-around;
  margin: auto;
  max-width: 726px;
  margin-bottom: 66px;
  z-index: 50;


  &__container {
    padding: 0;
    display: flex;
    width: 20%;
    align-items: center;
    flex-direction: column;
    transform: translateY(-25%);

    &.top { margin-top: -16px; }
    &.middle { margin-top: -8px; }

    @media (max-width: $media-md) {
      padding: 16px;
    }

    @media (max-width: $media-sm) {
      padding: 8px;

      .icon-wrapper_circle {
        width: 64px;
        height: 64px;
      }
    }
  }

  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 26px;

    & + &::before {
      content: "";
      display: block;
      height: 20px;
      width: 80px;
      z-index: 10;
      position: absolute;
      transform: translate(-150%, 400%);
      background-image: url('../../assets//icons/horizont-arrow.svg');
      background-size: contain;
      background-repeat: no-repeat;


      @media (max-width: $media-xs) { display: none }
    }

    @media (max-width: $media-xs) {
      flex-direction: row;

      .icon-wrapper_circle {
        height: 56px;
        width: 56px;
        margin-right: 16px;

        img {
          max-width: 24px;
          max-height: 24px;
        }
      }
    }
  }

  &__text {
    // font-family: $quincy;
    white-space: pre-wrap;
    font-size: 16px;
    text-transform: capitalize;
    color: $disabled-color;

    @media (max-width: $media-xs) {
      font-size: 14px;
      text-align: left;
    }
  }

  @media (max-width: $media-sm) {
    width: 100%;
  }

  @media (max-width: $media-xs) {
    width: 275px;
    flex-direction: column;
  }

}

.container.container_home-page {
  @include flexLayout(center, center, column);

  h1 {
    white-space: pre-line;
    text-align: center;
    color: $white;
    // font-size: 56px;
    margin-bottom: 16px;
  }

  .welcome-subtitle {
    // font-size: 32px;
    font-size: $subtitle;
    font-weight: 600;
    margin: 0;
    text-align: center;
  }

  @media (max-width: $media-md) {
    padding-top: 24px;
  }

  @media (max-width: $media-sm) {
    margin-top: 0;
    padding-top: 0;

    h1 { font-size: 46px; }
  }

  @media (max-width: $media-xs) {
    // margin-top: 16px;
    margin-top: 0;
    padding-top: 16px;

    h1 { 
      font-size: 24px;
      letter-spacing: -0.4px;
    }

    .welcome-subtitle { font-size: 14px; }
  }
}

.welcome-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 48px;

  h3 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 36px;
    padding: 0;
    margin-bottom: 0;
  }

  .welcome-text {
    margin: 0;
    color: $disabled-color;
    font-size: 22px;

    @media (max-width: $media-xs) {
      font-size: 18px;
    }
  }

  @media (max-width: $media-xs) {
    margin-bottom: 30px;
    margin-top: -20px;
  }
}

.icon-wrapper_circle {
  width: 128px;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  border-radius: 50%;
  background: $bg-base-color;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: $media-sm) {
    width: 80px;
    height: 80px;
    padding: 20px;
  }
}

.brands_list {
  padding: 15px 0px;
}

.region-images {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.region-features {
  margin-top: 12px;
  padding-right: 36px;

  li {
    display: flex;
    align-items: center;
    padding: 8px 0px;

    img, svg { margin-right: 16px; flex-shrink: 0; }

    @media (max-width: $media-sm) {
      flex-direction: column;
      align-items: flex-start;

      img { margin: 32px 0 16px }
    }
  }

  @media (max-width: $media-sm) {
    padding-right: 0;
    margin: 0 0 32px;
  }
}

.region-description {
  display: flex;
  flex-wrap: nowrap;

  @media (max-width: $media-md) {
    flex-direction: column;
  }

  &_images {
    display: flex;
    flex-direction: column;
    padding-right: 36px;

    img {
      height: 50%;
      max-width: none;
      width: auto;
    }

    img:not(:last-child){
      margin-bottom: 20px;
    }

    @media (max-width: $media-md) {
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 0;

      img { flex-grow: .3; }

      .region-features {
        height: fit-content;
        margin: 0;
      }
    }

    @media (max-width: $media-sm) {
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding: 0;

      img:not(:last-child){
        margin-bottom: 35px;
      }

      .region-features {
        margin-bottom: 35px;
      }
    }
  }
}

.region-description-card {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  flex: 50% 1;
  padding: 48px;
}

.region-subdesc {
  font-weight: 600;
  display: list-item;
  margin-left: 20px;
  margin-top: -5px;
  color: #787878;
  font-size: 18px;
  font-style: italic;
}

.curator-card, .region-info {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &>h2 {
    font-size: $h4;
    font-weight: 700;
  }

  &__subtitle {
    font-weight: $subtitle;
    color: $secondary
  }
}



.media-container {
  width: 100%;
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  & img, & video {
    max-width: 100%;
  }

  @media (max-width: $media-sm) {
    margin-bottom: 30px;
  }

  @media (max-width: $media-xs) { 
    margin-top: 50px;
  }
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.region-info-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;

  &.offset {

    .card:nth-child(5n + 2) {
      margin-right: 32px;
    }
  } 

  @media (max-width: $media-sm) { flex-direction: column; padding: 0 16px; }

  .card {
    margin: 0 16px 32px 16px;
    width: calc(33.33% - 32px);

    @media (max-width: $media-sm) {
      width: 100%; 
      margin-right: 0; 
      margin-left: 0; 
    }
  }
}

.wine-props {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 64px;

  @media (max-width: $media-sm) { flex-direction: column; }

  .alert {
    flex: 40% .4 0;
    margin-bottom: 15px;

    @media (max-width: $media-sm) { flex: 100% 1 0; }
  }
}

.wine-cards {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;

  
  .wine-card-bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  .wine-tastes, .wine-food {
    color: $icon-color;
    
    img {
      max-width: 35px;
      max-height: 30px;
    }
  }
  
  .wine-tastes { list-style: disc; padding-left: 20px; }
  
  .card {
    width: calc(33.33% - 12px);
    
    @media (max-width: $media-md) {
      width: calc(50% - 16px);
      margin-bottom: 32px;
    }

    @media (max-width: $media-sm) {
      width: 100%; 
    }
  }

  @media (max-width: $media-md) { 
    flex-wrap: wrap;
  }

  @media (max-width: $media-sm) { flex-direction: column; }
}

.cases-cards {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: $media-sm) { flex-direction: column; }

  .card {
    height: 365px;

    @media (max-width: $media-md) { 
      width: 300px;
    }
  }

  .slick-list { 
    margin: 0 -6px;

    @media (max-width: $media-md) {
      margin-left: -8px;
      margin-right: 0;
    }
  }

  .slick-slide + .slick-slide { margin: 0; }
  .slick-slide {
    padding: 0 6px;
  }

  .slider-control {
    justify-content: center;
    margin-top: 36px;

    [class^="slider-control__btn"] {
      border-color: transparent !important;
      padding: 0;

      &:focus, &:hover { border-color: transparent !important; }

      & + & { margin-left: 50px; }

      img { height: 24px; }
    }
  }

  

  @media (max-width: $media-xs) { 
    display: block;
    position: relative;
    width: 100%;

    .swiper-wrapper .swiper-slide.card.card_case {
      height: auto;
    }
  }
}

.case__details {
  margin-top: 71px;

  .details  {

    &__title {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      // padding: 24px 0px;
      margin-top: 32px;

      * { margin: 0px }      

      .subtitle {
        flex-grow: 1;
        padding: 0px 16px;
        color: $disabled-color;

        @media (max-width: $media-sm) { padding: 16px 0px; }
      }

      .case-details_info {
        display: flex;
        flex-direction: column;

        h2 {
          font-size: 45px;
          line-height: 1;
          margin-bottom: 16px;
        }

        .case-details__subtitle {
          display: flex;
          align-items: center;

          span + span { margin-left: 16px; }

          @media (max-width: $media-xs) {
            flex-direction: column;
            align-items: flex-start;
            
            .wines-types-list { width: auto; }

            .details__price { 
              padding: 16px 0;
              margin: 0;
            }
          }
        }

        @media (max-width: $media-sm) { margin-right: 32px; }
        @media (max-width: $media-xs) { 
          margin-right: 0px;
          width: 100%;
          align-items: flex-start;

          h2 { margin: 0; }

          & + .purchase-tools {
            width: 100%;
            
            button { width: 100%; }
          }
        }
      }

      .purchase-tools {
        display: flex;
        align-items: center;
        flex-direction: column;

        p { margin-top: 12px; }

        .button-link { padding: 0; }
      }

      @media (max-width: $media-sm) { align-items: center; }

      @media (max-width: $media-xs) { flex-direction: column; }
    }

    &__price {
      font-size: 24px;
      line-height: 1;
      white-space: nowrap;
      margin-left: 16px;

      @media (max-width: $media-sm) { margin-bottom: 16px; }

      @media (max-width: $media-xs) { margin: 0; }
    }

    &__description {
      padding: 8px 0px;
    }

    &__wines {

      &-subtitle {
        @include flexLayout(center, space-between);
        margin: 0;
        color: $secondary;
        font-weight: 700;
        font-size: $h5;

        .customization-request {
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          padding: 0;

          .button-link {
            padding: 0;
            margin-left: 12px;
            text-decoration: underline;
          }

          @media (max-width: $media-xs) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 6px;

            .button-link {
              margin-left: 0;
            }
          }
        }

        @media (max-width: $media-xs) {
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 32px;
        }
      }
    }
  }

  .case-details__section-divider {
    margin: 32px 0;
    height: 1px;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid $warm-gray-9;
  }

  .price-section {
    @include flexLayout(flex-end, space-between);
    gap: 32px;
    margin-top: 32px;

    &__subtitle {
      font-size: $subtitle;
      color: $tertiary;
      margin-top: 0;
      margin-bottom: 8px;
    }

    &__price {
      font-size: $h5;
      font-weight: 700;
    }

    @media (max-width: $media-xs) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.curator_case-subtitle {
  font-size: $subtitle;
  font-weight: 600;
  line-height: 27px;
  margin-top: 32px;
  margin-bottom: 8px;
}

.curator_case-description {
color: $tertiary;
margin: 0;
}

.questions-list {
  width: 100%;
  max-width: 930px;
  margin: 32px auto;
}

.feedback_form {
  width: 100%;
  max-width: 492px;
  margin: auto;
  padding: 0px 16px;

  button { 
    width: 100%;
    margin: auto;
  }

    .form-control {
      width: 100%;
      margin-bottom: 32px;

      .row:last-child { margin-bottom: 0; }

      @media (max-width: $media-sm) {

        .row:first-child {
          transform: translateX(calc(-100% - 15px));
        }
      }

      @media (max-width: $media-xs) {
        width: 100%;
        max-width: auto;

        .row:first-child {
          transform: none;
        }
      }
    }

    @media (max-width: $media-xs) {
      margin-bottom: 32px;
    }
}

.tastings-content {
  width: 100%;
  padding: 0 16px;


  &__title {
    text-align: center;
    padding: 32px 32px 64px;

    h3 { 
      font-weight: 600;
      font-size: $h5;
      padding: 0;
      font-weight: 700;
      font-family: $avenir;
      margin-bottom: 6px;
    }

    p { 
      margin: 0;
      font-size: $subtitle;
    }

    @media (max-width: $media-sm) {
      padding: 64px 0;
    }
  }
}

.tastings__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $media-sm) {
    flex-direction: column;
  }

  .links {
    display: flex;

    @media (max-width: $media-sm) {
      flex-direction: column;
    }

    .button + .button {
      margin-left: 16px;

      @media (max-width: $media-sm) {
        margin-left: 0;
        margin-top: 8px;
      }
    }
  }
}

.tasting-info {
  padding: 32px 0px;
  
  &__title {
    margin: 16px 0px;
    padding: 0;
    font-size: 32px;
    line-height: 1;
    color: $text-base-color;
  }

  &__subtitle {
    font-size: 18px;
    color: $disabled-color;
  }

  &__description {
    margin-top: 32px;
    color: $disabled-color;
  }
}

.img-shortened-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 14;

  &.expanded {
    overflow: visible;
    -webkit-line-clamp: initial;
  }

  @media (max-width: $media-md) {
    overflow: visible;
    display: block;
    -webkit-line-clamp: initial;
    text-align: left;
    margin-bottom: 35px;

    button { display: none; pointer-events: none; }
  }
}

.short-desc-wrapper {
  scroll-behavior: unset;
}

.short-description {
  white-space: pre-wrap;
  display: inline-block;
  margin: 0;
  
  p {
    margin: 0;
  }
  
  .more-info-link {
    color: $vineify-green;
    display: block;
    width: 100%; 
    font-weight: 700; 
    font-size: $caption; 
    cursor: pointer;
    background-color: transparent;
    box-shadow: 0px -20px 15px 0px white;
  }

  &.expanded {

    .more-info-link {
      box-shadow: none;
    }
  }
}


.more-info-button {
  font-weight: 600;
  display: inline;
  color: $primary-brand-color;


  &.block-info-button {
    width: 50%;
    position: absolute;
    padding-right: 50px;
    bottom: 60px;
  }
}

.expanded + .more-info-button {
  box-shadow: none;
}

.welcome-title {
  margin-bottom: 90px;
  font-size: 72px;
  text-align: center;
}

.welcome-info-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;

  @media (max-width: $media-lg) {
    padding: 0 16px;
    align-items: center;
  }

  @media (max-width: $media-md) {
    flex-direction: column;
  }

  .welcome-description {
    max-width: 630px;
    padding-right: 35px;

    @media (max-width: $media-lg) {
      max-width: none;
      width: 100%;
      padding-right: 0;
    }

    @media (max-width: $media-md) {
      text-align: center;
      max-width: none;
      padding-left: 0px;
      margin-bottom: 45px;
    }

    h3 {
      font-size: 45px;
      line-height: 1;
      margin-bottom: 20px;
      color: $text-base-color;
      font-weight: 500;
      font-family: $quincy;
      margin-top: 0;
    }

    p {
      margin: 0;
      font-size: 26px;
      color: $disabled-color;
      line-height: 1.5;
    }
  }


}

.co-founders {
  background-color: $secondary-brand-color;
  position: relative;
  padding-left: 30px;
  box-shadow: 0px 0px 15px #00000026;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  img {
    max-width: 100%;
    align-self: flex-end;
  }

  @media(max-width: $media-lg) {
    width: 40%;

    img { max-width: 100%; }
  }

  @media(max-width: $media-md) {
    width: auto;
  }

  @media(max-width: $media-xs) {
    img { max-width: 100%; }
  }


  &__info {
    margin: 12px 0 0;
    color: $icon-color;
  }
}

.infografics-block {
  max-width: 726px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 48px;
    margin-top: 105px;
    margin-bottom: 66px;
    display: block;
    text-align: center;
    color: $disabled-color;
  }

  .welcome-text {
    margin-top: 0;
    margin-bottom: 55px;
    padding: 0 60px;
    text-align: center;
    color: $disabled-color;
  }
}

.region-info-container {
  
  .pre-title {
    font-size: 32px;
    line-height: 1;
  }

  h2 {
    font-family: $quincy;
    font-size: 64px;
    font-weight: 500;
    line-height: 1;
    margin-top: 16px;
  }

  button {
    margin-bottom: 32px;
  }

  @media (max-width: $media-sm) {

    .pre-title {
      text-align: center;
    }

    .flex-space-between {
      flex-direction: column;
    }

    h2 { text-align: center; }
  }

  @media (max-width: $media-sm) {
    padding-top: 32px;

    .pre-title { display: none; }

    h2 { display: none; }
  }

  @media (max-width: $media-sm) {
   button { align-self: stretch; }
  }
}


.new-home-header {
  height: 590px;
  margin-bottom: 160px;
 
  img {
    clip-path: none;
    filter: none;
  }

  h1 {
    text-align: left;
    line-height: 1.2;
    width: 100%;
  }

  @media (max-width: $media-xs) { height: 400px; margin-bottom: 320px; }
}


.regions-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  padding: 32px 0;

  .slick-slide + .slick-slide {
    margin-left: 0;
  }

  .region-wrapper {
    position: relative;
    padding: 16px;
    width: 50%;

    @media (max-width: $media-sm) {
      width: 100%;
      max-width: 544px;
      padding: 16px 8px;
    }

    @media (max-width: $media-xs) {
      min-width: 350px;
      padding: 16px;
    }
  }

  @media (max-width: $media-sm) {
    justify-content: center;
  }

  @media (max-width: $media-xs) {
    padding: 0;
  }
}

.inline-region-title {
  @include flexLayout(baseline, center);
  gap: 6px;
}

.grapes-cards-list {
  width: 100% !important;
}

.grape-card-wrapper {
  width: 300px;
}

.alert-circle-icon {
  .alert__icon {
    border: 2px solid white;
    border-radius: 50%;
    padding: 4px;
  }
}

.public-home {
  &__header-alert {
    position: relative;
    background-color: white;
    color: $primary;
    height: 40px;
    @include flexLayout(center, center);
    
    p {
      margin: 0;
      position: relative;
    }

    .button-link { color: $primary !important; }

    @media (max-width:$media-sm) {
      p {
        flex-direction: row;
        gap: 4px;

        .dismiss-button {
          transform: translate(0,-25%);
        }
      }
    }

    @media (max-width:$media-xs) {
      height: 48px;

      .button-link, p { font-size: 12px; }

      p { 
        display: inline-block;
        padding-left: 20px;
        padding-right: 36px;

        .dismiss-button {
          transform: translate(-25%,-55%);
        }
      }
    }
  }

  &__description {
    font-size: 20px;
    color: $tertiary;
    margin: 16px 0 0;
  }

  &__header-buttons {
    margin-top: 64px;
    display: flex;
    gap: 24px;

    .button { border-radius: 4px; }

    @media (max-width: $media-sm) {
      justify-content: center;
    }

    @media (max-width: $media-sm) {
      gap: 16px;

      .button {
        padding: 8px 16px;
        font-size: 14px;
      }
    }
  }

  &__section {
    padding: 64px 0;
    background-color: $alabaster-50;

    &.lite { background-color: $white; }

    &.curators-section { 
      padding-top: 24px;
      padding-bottom: 24px;

      @media (max-width: $media-xs) {
        padding-top: 0;
        padding-bottom: 0;

        .swiper-pagination { bottom: 16px !important; }
        .with-pagination {
          padding-bottom: 0 !important;
        }
      }
    }

    .motivations-list {
      margin-top: 32px;
      display: flex;
      flex-wrap: wrap;
      gap: 32px;

      .motivation-card {
        max-width: calc(50% - 16px);
        // width: calc(50% - 16px - 32px);

        @media (max-width: $media-xs) {
          max-width: 100%;
        }
      }
    }

    @media (max-width: $media-xs) {
      padding: 36px 0;
    }
  }

  .public-title-sm {
    font-size: 40px;
    font-family: $avenir;

    &.text-xs {
      font-size: 32px;
    }

    b { font-weight: 700; }

    & + .button {
      border-radius: 4px;
    }

    @media (max-width: $media-sm) {
      line-height: 1.3;

      &.line-breaks {
        white-space: initial;
      }
    }

    @media (max-width: $media-xs) {
      font-size: 24px;
    }
  }

  &__title {
    margin: 0;

    &.main {
      color: $white;
      text-shadow: 0px 0px 30px black;
      font-size: 72px;

      @media (max-width: $media-sm) {
        text-align: center !important;
        font-size: 48px !important;
      }

      & + .public-home__subtitle {
        font-size: 28px;
        max-width: 600px;
        margin-top: 16px;
      }
    }

    @media (max-width: $media-xs) {
      // font-size: 30px;
      // font-size: 24px;
      font-size: 22px;

      &.main { 
        font-size: 40px !important;
        // padding: 0 32px; 
      }
    }
  }

  &__subtitle {
    margin: 0;
    font-size: $subtitle;
    font-weight: 600;
    // margin-top: 16px;

    @media (max-width: $media-sm) {
      font-size: 18px;
    }

    @media (max-width: $media-xs) {
      font-size: 14px;
    }
  }

  &__section-description {
    font-size: $subtitle;
  }

  &__carousel-wrapper {
    margin-top: 48px;

    &.disabled {
      opacity: .5;
      pointer-events: none;
    }

    @media (max-width: $media-xs) {
      margin-right: -16px;
      margin-top: 24px;

      .case-slider-card-wrapper {
        width: calc(100% - 30px);
      }
    }
  }

  .grapes-filter {
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .button.button_outline {
      padding: 16px 24px;

      &.active {
        color: white;
        background-color: $warm-gray-12 !important;
      }

      @media (max-width: $media-sm) {
        font-size: 14px;
      }

      @media (max-width: $media-xs) {
        width: calc(50% - 8px) !important;
        padding: 12px;
      }
    }

    &__label {
      font-size: 20px;
      font-weight: 600;
      margin: 0;

      @media (max-width: $media-sm) {
        width: 100%;
        text-align: center;
      }
    }

    @media (max-width: $media-sm) {
      flex-wrap: wrap;
    }
  }

  &__header {
    height: 700px;
    position: relative;
    background-image: url('../../assets/images/new-homepage-bg.jpg');
    background-position: left bottom;
    // background-size: 100%;
    background-size: cover;
    @include flexLayout(center, flex-start, column);
    gap: 12px;

    // .container { margin: 0 auto; }

    .dark-bg-layout {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      background-image: linear-gradient(rgba(0, 0, 0, 0.3) 0%
      , rgba(0, 0, 0, 0.2) 53.13%
      , rgba(0, 0, 0, 0) 100%);
    }
    
    .public-home__title {
      margin-top: 45px;
      width: 100%;
      max-width: 985px;
      text-align: center;
      text-shadow: 0px 0px 50px #694F24, 0px 1px 2px rgba(97, 95, 39, 0.5);

      @media (max-width: $media-sm) {
        font-size: 56px;
      }

      @media (max-width: $media-xs) {
        font-size: 30px;
      }
    }

    .public-home__subtitle {
      color: white;
      font-size: $h5;
      text-align: center;
      width: 100%;
      // text-shadow: 0px 0px 50px #694F24, 0px 1px 2px rgba(97, 95, 39, 0.5);
      text-shadow: 0px 0px 30px black;

      @media (max-width: $media-sm) {
        // padding: 0 32px;
        font-size: 18px !important;
        text-align: center !important;
      }
    }

    // @media (max-width: $media-sm) {
    //   background-size: auto 100%;
    // }

    @media (max-width: $media-xs) {
      // height: 600px;
      height: 440px;
      background-image: url('../../assets/images/new-home-bg-xs.jpg');

      .public-home__logo {
        display: none;
      }
    }
  }

  &__info-cards {
    @include flexLayout(flex-start, flex-start);
    // gap: 120px;
    gap: 64px 40px;
    margin-top: 64px;
    flex-wrap: wrap;

    @media (max-width: $media-sm) {
      gap: 32px;
    }

    @media (max-width: $media-xs) {
      gap: 24px;
    }
  }

  &__info-card {
    @include flexLayout(flex-start, flex-start, column);
    // padding-right: 48px;
    gap: 16px;
    width: calc(100% / 3 - 40px * 2 / 3);

    .info-card__icon-placeholder {
      @include flexLayout(center, center, column);
      background-color: $alabaster;
      border-radius: 50%;
      // width: 64px;
      // height: 64px;
      width: 40px;
      height: 40px;
    }

    .info-card__title {
      // font-size: 16px;
      font-size: 20px;
      color: $primary;
      font-family: $avenir;
      // font-weight: 500;
      font-weight: 700;
      padding: 0;
      margin: 0;
      text-align: left;
      width: 100%;

      @media (max-width: $media-xs) {
        font-size: 16px;
       }
    }

    .info-card__text {
      margin: 0;
      color: $tertiary;

      @media (max-width: $media-xs) {
       font-size: 14px;
      }
    }

    @media (max-width: $media-sm) {
      width: calc(100% / 2 - 32px / 2);
    }
  }

  &__curators-list {
    height: 650px;
    @include flexLayout(stretch, space-between);
    gap: 24px;
    // margin-top: 64px;

    .curators-list__info {
      width: 100%;
      @include flexLayout(center, center, column);
      // gap: 68px;
      gap: 8px;
      flex-grow: 2;
      background-color: #A2905A;

      .info-title, .info-description {
        width: 575px;
        line-height: 1.5;
        color: white;
        text-align: center;
        margin: 0;
        padding: 0;

        @media (max-width: $media-xs) {
          width: 100%;
        }
      }

      .info-title {
        font-size: $h3;
        font-family: $avenir;
        font-weight: 500;

        b { font-weight: 800; }

        @media (max-width: $media-xs) {
          font-size: $h3-mobile;
        }
      }

      .info-description {
        font-size: $h5;

        @media (max-width: $media-xs) {
          font-size: 16px;
        }
      }

      @media (max-width: $media-sm) {
        padding: 64px 24px;
        gap: 16px;
        order: -1;
        width: 100%;
      }
    }

    @media (max-width: $media-md) {
      height: auto;
    }

    @media (max-width: $media-sm) {
      flex-wrap: wrap;
      gap: 8px;
      height: auto;
    }

    @media (max-width: $media-xs) {
      // flex-direction: column;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }

  &__curator-card {
    position: relative;
    width: 50%;
    height: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    img { width: 100%; }

    .public-home__curator-info {
      position: absolute;
      display: flex;
      flex-direction: column;
      color: white;
      gap: 4px;
      bottom: 24px;
      left: 0;
      padding: 0 24px;

      .public-home__curator-name {
        font-size: 20px;
        font-weight: 700;
        margin: 0;

        @media (max-width: $media-xs) {
          font-size: 16px;
        }
      }

      .public-home__curator-spec {
        margin: 0;
        text-transform: uppercase;
        font-size: 14px;

        @media (max-width: $media-xs) {
          font-size: 12px;
        }
      }

      .public-home__curator-desc {
        font-weight: 500;
        margin: 0;

        @media (max-width: $media-xs) {
          font-size: 12px;
        }
      }

      .public-home__curator-country {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
      }

      @media (max-width: $media-sm) {
        bottom: 16px;
        padding: 0 16px;
      }
    }

    @media (max-width: $media-sm) {
      width: calc(50% - 8px);
    }
  }

  &__welcome-section {
    height: 470px;
    position: relative;
    background-color: $alabaster-50;
    @include flexLayout(center, center);

    .welcome-section__image {
      max-height: 100%;
    }

    .welcome-section__info {
      align-self: stretch;
      @include flexLayout(flex-start, center, column);
      // gap: 16px;
      gap: 32px;
      padding: 0 64px;

      .welcome-section__title {
        font-size: 32px;
        // font-size: $h3;
        margin: 0;

        @media (max-width: $media-xs) {
          font-size: 30px;
        }
      }

      .welcome-section__description {
        font-size: $subtitle;
        color: $tertiary;
        margin: 0;

        @media (max-width: $media-xs) {
          font-size: 16px;
        }
      }

      .welcome-section__author {
        font-weight: 700;
        font-size: $subtitle;
        // margin: 16px 0 0 0;
        margin: 0;

        @media (max-width: $media-xs) {
          font-size: 18px;
        }
      }

      small { transform: translateY(-32px); }

      @media (max-width: $media-sm) {
        order: -1;
        padding: 64px 32px;
      }
    }

    @media (max-width: $media-sm) {
      flex-direction: column;

      .welcome-section__image {
        width: 100%;
      }
    }

    @media (max-width: $media-sm) {
      height: auto;
    }
  }

  &__subscription {
    @include flexLayout(center, center, column);
    gap: 16px;
    background-color: #A2905A;
    padding-top: 80px;
    padding-bottom: 80px;

    .public-home__title { 
      color: white;
      font-size: $h3;

      @media (max-width: $media-xs) {
        font-size: $h3-mobile;
      }
    }

    .subscription__pretitle,
    .subscription__subtitle {
      color: white;
      font-size: $subtitle;
      margin: 0;

      @media (max-width: $media-xs) {
        text-align: left;
      }
    }

    .subscription__pretitle { 
      text-transform: uppercase;

      @media (max-width: $media-xs) {
        font-size: 12px;
      }
    }

    input, .button {
      height: 43px !important;
      border-radius: 4px !important;
    }

    @media (max-width: $media-sm) {
      padding: 44px 55px;
      align-items: flex-start;

      .container {
        padding: 0;
      }

      .subscription__subtitle {
        font-size: 16px;
      }
    }
  }

  &__subscription-form {
    margin-top: 24px;
    padding: 0 24px;
    gap: 16px;
    @include flexLayout(center, center);

    .form-control {
      flex-grow: 1;
      margin: 0;

      &.email { max-width: 460px; }
    }

    .button { 
      flex-shrink: 0;
      width: auto;
      margin: 0;

      @media (max-width: $media-xs) {
        width: 100%;
        margin-top: 24px;
      }
    }

    .subscribed-alert {
      position: absolute;
      bottom: 0;
      transform: translateY(60px);
      color: $white;
      text-align: center;
    }

    .error-msg-block {
      color: white;
      transform: translateY(calc(100% + 16px));
    }

    @media (max-width: $media-sm) {
      flex-wrap: wrap;
    }

    @media (max-width: $media-xs) {
      flex-direction: column;
      padding: 0;

      .form-control {
        width: 100%;
      }
    }
  }

  @media (max-width: $media-sm) {
    .sm-p-32 {
      padding-left: 32px;
      padding-right: 32px;
    }

    &__regions {
      .region-card {
        &__content {
          padding-left: 16px;
          padding-right: 16px;

          .float-img {
            height: 200px;
          }
        }

        &__footer {
          padding-left: 16px;
          padding-right: 16px;
          flex-direction: column;
          gap: 12px;

          .button {
            width: 100% !important;
            margin: 0;
          }
        }

        &__cases-stat {
          @include flexLayout(center, center);

          @media (max-width: $media-xs) {
            width: 100%;
            justify-content: flex-start;
          }
        }
      }
    }
  }
}

.newsletter-dialog {
  position: relative;
  height: 580px;
  max-width: 470px;
  width: 100%;
  border-radius: 16px;
  min-height: auto !important;
  background-color: transparent;
  overflow: hidden;

  &.page-dialog {
    margin: 80px auto;
    height: auto;

    .newsletter-card {
      height: auto;

      .newsletter-card__subtitle {
        width: 380px;
        font-size: 18px;
  
        @media (max-width: $media-xs) {
          width: auto;
          font-size: 14px;
          padding: 0 16px;
          margin: 0;
        }
      }

      .newsletter-card__image {
        height: auto;
      }

      .error-msg {
        position: absolute;
      }

      @media (max-width: $media-xs) {
        gap: 12px;

        .newsletter-card__title {
          margin: 0;
        }
      }
    }

    @media (max-width: $media-xs) {
      margin: 16px auto;
    }
  }

  .close-modal-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.3);
    right: 16px;
    top: 16px;
    z-index: 15;
    border-radius: 50%;
    opacity: 1;
  }

  .rsm-flex-1.rsm-p-8 { 
    padding: 0;
    border-radius: 16px;
  }

  .newsletter-card {
    height: 100%;
    background-color: white;
    width: 100%;
    padding: 8px;
    display: flex;
    border-radius: 16px;
    flex-direction: column;
    gap: 16px;

    &__image {
      position: relative;
      height: 296px;
      overflow: hidden;
      border-radius: 15px;
    }

    &__title {
      margin: 16px 0 0;
      line-height: 1;
      text-align: center;
      width: 400px;
      align-self: center;

      @media (max-width: $media-xs) {
        width: 280px;
      }
    }

    &__subtitle {
      margin: 0 0 16px;
      text-align: center;
      width: 340px;
      align-self: center;

      @media (max-width: $media-xs) {
        width: 280px;
      }
    }

    .form-control {
      gap: 16px;
      padding: 0 24px;
      margin-bottom: 24px;

      input {
        flex-grow: 1;
        border-radius: 4px;
        border: none;
        background-color: $alabaster;
        height: 50px;
        color: $secondary;
      }

      .button { 
        flex-shrink: 0;
        border-radius: 4px;
        width: auto;
        margin: 0;
        height: 50px;
        font-weight: 400;

        &-link {
          color: $accent;
          padding: 0;
          font-weight: 600;
          text-decoration: none;
        }
      }

      &.invalid {
        input { border: 1px solid $warning; }

        .error-msg {
          position: absolute;
          bottom: 0;
          transform: translateY(24px);

          @media (max-width: $media-xs) {
            bottom: 50%;
            transform: translateY(50%);
          }
        }
      }

      @media (max-width: $media-xs) {
        flex-direction: column;
        align-items: stretch;
      }
    }
  }

  @media (max-width: $media-sm) {
    max-width: calc(100vw - 32px);
    height: auto;
  }
}

.alert.sub-success-alert {
  background-color: #7AB80033;
  box-shadow: none;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 14px;
}

.trending-wine-signup-page {
  background-image: url('../../assets/images/trending-wine-signup.png');
  background-size: cover;
  position: relative;
}

.personal-rec {
  width: 100%;
  position: relative;
  display: flex;
  gap: 32px;

  &__photo {
    position: relative;
    height: 85px;
    background-image: url('../../assets/images/curator_images_stack.jpg');
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: auto 100%;

    @media (max-width: $media-xs) {
      background-image: url('../../assets/images/curator_images_stack_xs.jpg');
      height: 75px;
    }
  }

  &__form {
    background-color: $alabaster-50;
    border-radius: 8px;
    padding: 24px;
    flex-grow: 1;

    &-success {
      color: $success;
      position: absolute;
      top: 0;
      font-style: italic;
      font-weight: 500;
      line-height: 1.2;
      transform: translateY(-40px);

      @media (max-width: $media-xs) {
        transform: translateY(-50px);
        font-size: 14px;
      }
    }

    &-prompt {
      font-size: 14px;
      font-style: italic;
      font-weight: 500;
      margin: 0 0 16px;
      color: $tertiary;
    }

    &-filters {
      display: flex;
      gap: 32px;

      .form-control {
        width: calc(100% / 3 - 32px * 2 / 3);

        label { font-size: 14px; }
        .select-wrapper {
          width: 100%;
        }

        @media (max-width: $media-xs) {
          width: 100%;
        }
      }

      .radio-button-group__buttons {
        width: 100%;

        .button.button_outline {
          padding: 8px 12px;
          font-size: 14px;
          flex-grow: 1;
          height: 40px;
          width: 50%;

          @media (min-width: 521px) {
            border-radius: 0;

            &:last-child {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }
      
            &:first-child {
              margin-left: 0px;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }
          }
        }

        @media (max-width: $media-xs) {
          gap: 16px;
        }
      }

      @media (max-width: $media-xs) {
        gap: 0;
        flex-direction: column;
      }
    }

    .form-control {
      gap: 0 16px;

      &.horizontal {
        margin-bottom: 0;
        margin-top: 24px;

        @media (max-width: $media-xs) {
          gap: 16px;
          margin-top: 42px;
        }
      }

      input { 
        flex-grow: 1;
        height: 48px;
        border-radius: 4px;
      }

      textarea::placeholder {
        color: rgba($primary, .5);
        font-size: 14px;
        padding: 16px 0;
        font-style: italic;
      }

      .button {
        height: 48px;
        border-radius: 4px;
      }

      @media (max-width: $media-xs) {
        flex-wrap: wrap;

        .button { width: 100%; }
      }
    }

    @media (max-width: $media-sm) {
      border-radius: 0;
    }
  }

  &__filters {
    width: 470px;
    flex-shrink: 0;
    // background-color: $alabaster-50;
    border-radius: 8px;
    padding: 0 24px;

    &-placeholder {
      margin: 0;
      padding: 24px 0;
      opacity: .5;

      @media (max-width: $media-xs) {
        display: none;
      }
    }

    &-title {
      font-size: $h5;
      font-weight: 700;
      margin: 16px 0 0;
      padding: 0;
      line-height: 1.4;

      @media (max-width: $media-xs) {
        font-size: 18px;
        margin-bottom: 16px;
      }
    }

    &-subtitle {
      margin: 0;

      @media (max-width: $media-xs) {
        margin-bottom: 16px;
      }
    }

    @media (max-width: $media-sm) {
      border-radius: 0;
      width: 100%;
      padding-bottom: 0;
    }
  }

  &__preferences {
    margin-top: 16px;

    &-caption {
      @media (max-width: $media-sm) {
        width: 100%;
      }
    }

    &-control {
      justify-content: space-between;

      label { font-size: 14px; }

      .select-wrapper {
        width: 178px;
      }

      @media (max-width: $media-xs) {
        width: 100%;
      }
    }

    @media (max-width: $media-sm) {
      display: flex;
      flex-wrap: wrap;
      gap: 0 64px;
      justify-content: space-between;
    }
  }

  @media (max-width: $media-sm) {
    flex-direction: column;
    gap: 0;
    align-items: stretch;
  }
}

.occasions-slider {
  margin-top: 24px;
  width: calc(50% - 42px) !important;

  @media (max-width: $media-xs) {
    width: calc(100% - 32px) !important;
  }
}
